@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: $title-content-margin; }

.content {
  li {
    font-weight: 400;
    color: $n6;
    @include dark {
      color: $n3; }
    b {
      color: $n4; } } }

.list {
  // display: grid
  // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
  gap: 36px 24px;
  margin-top: 20px;
  list-style: none;

  display: flex;
  flex-wrap: wrap; }

.list_item {
  b {
    color: $n4; } }
