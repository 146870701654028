@import "styles/helpers";

.button {
  display: flex;
  align-items: center;
  gap: 20px;
  transition: .2s ease-in-out;
  &.disabled {
    opacity: 0.8;
    cursor: not-allowed; }
  .loader {
    display: none;
    cursor: wait;
    &.visible {
      display: block; } } }
