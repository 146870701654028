@import "styles/helpers";

.breadcrumps_button {
  margin-right: 8px;
  cursor: pointer;
  @include dark {
    color: $n; }
  color: $n7;
  @include body1-m;
  &:hover {
    text-decoration: none;
    color: #009bee; } }

.breadcrumps_icon {
  margin-right: 8px; }

.Breadcrumb {
    color: $n8;
    height: 100%;
    @include dark {
      color: $n; }

    a {
      cursor: pointer;
      color: $n4;
      font-size: 20px;
      font-weight: 600;
      &:hover {
        text-decoration: none; } }

    .JZWIT {
        color: $n8;
        transition: 150ms;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 2px;
        align-items: center;
        @include title1-s;
        font-family: 'Inter', sans-serif;
        @include dark {
          color: $n; } } }
