@import "styles/helpers";

.item {
  position: relative;
  width: 100%;
  aspect-ratio: 5 / 4;
  overflow: hidden;
  padding: 8px;
  border-radius: $default-radius;

  h2 {
    position: relative;
    z-index: 2;
    color: $n7;
    @include dark {
      color: $n1; }
    transition: all 0.3s ease; }

  .thumbnail {
    z-index: 1;
    position: absolute;
    inset: 0;
    background-color: $n1;
    @include dark {
      background-color: $n7; }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0.4;
      transition: all 0.3s ease; } }

  &:hover {
    h2 {
      transform: scale(0.95); }

    .thumbnail {
      img {
        transform: scale(1.1); } } } }
