@import "../../../styles/helpers";

.categories {
  display: flex; }

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 12px;
  .col {
    display: flex;
    align-items: center;
    gap: 12px; } }

.dropdown {
  width: 200px; }


.list {
  display: flex;
  margin-left: 10px;
  list-style-type: none; }

.item {
  position: relative;
  margin-right: 20px;
  width: fit-content;
  min-width: 100px;
  height: auto;
  @include s {
    height: 120px;
    width: 100%; }
  input {
    display: none;
    &:checked + label {
      border: 1px solid $p1; }

    &:checked + label:before {
      content: "✓";
      transform: scale(1);
      background: $p1; }

    &:checked + label:hover {
      transition: filter 0.2s;
      opacity: 1;
      filter: brightness(110%); } }
  label {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    cursor: pointer;
    border: 1px solid $n3;
    padding: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 16px;
    overflow: hidden;
    &:before {
      color: white;
      content: " ";
      position: absolute;
      top: 5px;
      left: 5px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      text-align: center;
      line-height: 28px;
      //transition-duration: 0.4s
      transform: scale(0);
      z-index: 2; }
    span {
      // padding: 50px
      position: absolute;
      font-size: 120%;
      min-width: 110px; }
    img {
      position: relative;
      width: auto;
      height: 100%;
      opacity: 0.7; }

    &:hover {
      transition: opacity 0.2s;
      opacity: 0.5; } } }

.card {
  padding: 24px;
  margin-bottom: 24px; }

.categories_button {
  height: 48px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
  color: black;
  @include dark {
    background: none;
    color: #6F767E; } }
