@import "styles/helpers";

.container {
    width: 100%;
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    list-style-type: none;
    height: auto;
    max-height: 200px;
    overflow-y: hidden;
    span {
        @include dark {
            --base-color: #313131;
            --highlight-color: #525252; } } }
