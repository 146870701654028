@import "styles/helpers";

.container {
  @include element-flex(column, flex-start, stretch);
  gap: $cards-margin;
  width: 100%; }

.resultHeader {
  @include element-flex(row, space-between, center);
  gap: 16px; }

.orderedList {
  list-style: decimal inside; }

.text {
  @include body1-m;
  color: $n7;
  @include dark {
    color: $n3; }

  strong {
    color: $n4; } }

.section {
  @include element-flex(column);
  gap: 24px; }

.goToTestButton {
  align-self: center; }
