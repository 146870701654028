@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: $cards-margin;
  span {
    @include dark {
      --base-color: #313131;
      --highlight-color: #525252; } } }

.card {
  width: 100%; }
