@import "../../../../styles/helpers";

.earnings_bar_container {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-left: 5%;
    margin-bottom: 4px; }

.earnings_bar {
    top: -14px;
    width: 90%;
    height: 0;
    border-radius: 12px;
    margin-top: 8px;
    border: 6px solid #DCDCDC;
    position: absolute; }

.earnings_bar_vertical_line {
    position: absolute;
    left: 50%;
    top: -12px;
    border: 1px solid #ffd88d;
    border-radius: 12px;
    height: 24px;
    width: 0; }

.earnings_bar_horizontal_line {
    height: 0;
    border-radius: 12px;
    margin-top: 8px;
    border: 6px solid #3174fa;
    position: absolute;
    top: -14px; }

.earnings_bar_info_left {
    margin-left: 5%;
    float: left;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: #6f767e; }

.earnings_bar_info_right {
    margin-right: 5%;
    float: right;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    color: #6f767e; }
