@import "../../styles/helpers";

$color-error: #cf0000;

.title {
  font-size: 20px;
 }  //color: $color-error

.content {
  white-space: pre-line; }
