@import "styles/helpers";

$color-delete: #cf0000;

.container {}

.title {
  margin-bottom: 24px; }

.form_section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 24px; }

.field {
  //grid-column-start: 1
 }  //grid-column-end: 3

.button {
  display: flex;
  width: 100%;
  max-width: 200px;
  margin: 12px auto 0;
  &.confirm {
    color: #ff0000;
    svg {
      path {
        fill: #ff0000; } } } }

.section_title {
  color: $p1;
  //font-weight: 400
  font-size: 15px; }

.form_section {
  padding: 24px 0;
  border-top: 1px solid $p1; }

.list, .textarea, .file_input, .attachments, .acceptance {
  grid-column-start: 1;
  grid-column-end: 3; }

.attachment {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  padding: 12px 16px;
  border-radius: $default-radius;
  background-color: $n3;
  @include dark {
    background-color: $n6; } }

.attachment_size {
  font-size: 12px;
  font-weight: 400;
  color: $n4; }

.attachment_remove {
  path {
    fill: $n4;
    transition: .2s ease-in-out; }
  &:hover {
    path {
      fill: $color-delete; } } }

.error {
  position: relative;
  padding: 12px;
  margin-bottom: 24px;
  color: $color-delete;
  border: 1px solid $color-delete;
  border-radius: 12px;
  width: 100%;
  display: flex;
  gap: 12px;
  svg {
    flex-shrink: 0;
    transform: rotate(180deg);
    fill: $color-delete; } }

 :global {
  .mobile {
     :local {

      .form_section {
        grid-template-columns: 1fr; } } } }
