@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: 48px; }

.section_header {
  margin-bottom: 24px; }

.fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 12px;
  @include m {
    grid-template-columns: 1fr; } }

.editor {
  margin-bottom: 24px; }

.background_image {
  margin-top: 24px;
  max-width: 500px; }
