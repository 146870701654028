@import "styles/helpers";

.container {
  width: 100%;
  height: 10px;
  align-items: center;
  margin-bottom: 12px; }

.bar {
  width: 100%;
  height: 10px;
  background: $n2;
  @include dark {
    background: $n6; }
  border-radius: $default-radius / 2; }

.bar_fill {
  width: 0%;
  height: 100%;
  background-color: $p1;
  border-radius: $default-radius / 2;
  transition: all .5s ease-in-out; }

.progress, .rank_name {
  color: $n4;
  font-size: 12px;
  width: 100%; }
