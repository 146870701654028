@import "styles/helpers";

.container {
  margin-bottom: 40px; }

.title {
  text-transform: uppercase;
  text-align: center;
  @include title2; }

.content {
  display: flex;
  flex-direction: column;
  gap: 24px; }

article {
  li {
    color: $n4; }
  a {
    text-decoration: underline;
    color: $n4;
    @include dark {
      color: #9a9fa5; } }
  h3 {
    font-size: 15px;
    font-weight: 600; }
  ol {
    padding: 10px 30px;
    list-style-position: inside;
    list-style: number;
    ol {
      padding: 0 30px;
      list-style-type: lower-alpha; }
    li {
      margin: 10px 0;
      line-height: 1.3;
      font-size: 14px;
      font-weight: 300;
      display: list-item;
      color: $n6;
      @include dark {
        color: $n3; } }
    ul {
      padding: 0 30px;
      list-style-type: square;
      li {
        margin: 10px 0;
        line-height: 1.3;
        font-size: 14px;
        font-weight: 300;
        display: list-item;
        color: $n6;
        @include dark {
          color: $n3; } } } } }

.content {
  li {
    color: $n4;
    @include dark {
      color: #9a9fa5; } } }
