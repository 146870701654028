@import "styles/helpers";

.settings {
    height: fit-content;
    //overflow-y: scroll

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none; } }

.item {
    &:not(:last-child) {
        margin-bottom: 24px; } }

.label {
    margin-bottom: 12px;
    @include base2;
    color: $n4; }

.list {
    margin-bottom: 36px; }

.dropdownHead {
    @include dark {
        color: $n1; } }

.checkbox {
    &:not(:last-child) {
        margin-bottom: 12px; } }

.box {
    position: relative;
    .dropdownHead {
        padding-left: 48px; }
    svg {
        position: absolute;
        left: 12px;
        bottom: 12px;
        z-index: 10;
        pointer-events: none;
        fill: $s5; } }

.btns {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    .button {
        &:not(:last-child) {
            margin-right: 12px; } } }
