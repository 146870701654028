@import "../../../styles/helpers";

.panel {
    position: sticky;
    //width: calc( 100vw - 300px )
    z-index: 10;
    bottom: 10px;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    border: 2px solid $n2;
    background-color: $n;
    @include dark {
        background-color: $n7;
        border: 2px solid $n6; }
    @include d {
        padding: 10px;
        // width: calc( 100vw - 96px )
        // right: 25px
 }        // bottom: 30px
    @include t {
        //width: 100%
        // right: 15px
 }        // bottom: 25px
    @include m {
        bottom: 5px;
        padding: 5px; } }

.lastSave {
    @include m {
        display: none; } }

.info {
    margin-right: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
    @include caption1;
    color: $n4;
    @include m {
        // margin: 0 0 24px
 }        // gap: 0.5rem
    svg {
        // margin-right: 12px
        fill: $n4; }
    span {
        color: $n7;
        @include dark {
            color: $n1; } } }

.btns {
    display: flex;
    align-items: center;
    gap: 0.5rem; }

.button {
    // margin-right: 8px
    @include m {
        flex-grow: 1;
        transform: scale(0.9); } }

.button {
    display: flex;
    align-items: center;
    gap: 4px;
    transition: opacity .2s ease-in-out;
    &.disabled {
        opacity: 0.8; }
    .loader {
        display: none;
        &.visible {
            display: block; } } }

.actionsHead {
    width: 48px;
    height: 48px;
    border: 2px solid $n3;
    border-radius: 12px;
    transition: all .2s;
    svg {
        transform: rotate(90deg);
        fill: $n4; }
    &:hover {
        background: $n3;
        svg {
            fill: $n7; } }
    @include dark {
        border-color: $n6;
        background: transparent; }
    @include m {
        transform: scale(0.9); } }

.actionsOption {
    &:first-child {
        display: none;
        @include t {
            display: flex; } } }

.actionsBody {
    right: 0;
    &:before {
        right: 14px; } }



// @import ../../../styles/helpers

// .panel
//     +m
//         display: block

// .info
//     margin-right: auto
//     +caption1
//     color: $n4
//     +m
//         margin: 0 0 24px
//     svg
//         margin-right: 12px
//         fill: $n4
//     span
//         color: $n7
//         +dark
//             color: $n1

// .btns
//     display: flex
//     align-items: center

// .button
//     margin-right: 8px
//     +m
//         flex-grow: 1

// .actionsHead
//     width: 48px
//     height: 48px
//     border: 2px solid $n3
//     border-radius: 12px
//     transition: all .2s
//     svg
//         transform: rotate(90deg)
//         fill: $n4
//     &:hover
//         background: $n3
//         svg
//             fill: $n7
//     +dark
//         border-color: $n6
//         background: transparent

// .actionsOption
//     &:first-child
//         display: none
//         +t
//             display: flex

// .actionsBody
//     right: 0
//     &:before
//         right: 14px
