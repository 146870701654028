@import "../../../styles/helpers";

.events {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 10px 0;
  > li {
    width: 100%;
    background: $n2;
    padding: 8px;
    padding-left: 20px;
    margin-bottom: 8px;
    color: lighten($n4, 20);
    font-weight: normal;
    font-style: italic;
    border-left: 3px solid lighten($p1, 20);
    @include dark {
      background: $n5; }
    span {
      margin-left: 10px;
      font-style: normal;
      color: $p1; } } }

.btn {
  .loader {
    margin-left: 5px; } }
