@import "styles/helpers";

.card {
  margin-bottom: 30px; }

.head {
  display: flex;
  white-space: nowrap;
  gap: 20px;
  @include s {
    align-items: unset;
    flex-direction: column; }

  & > .title {
    margin-right: unset; } }

.form {
  @include s {
    width: 100%; } }

.options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 12px;
  .box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px; } }

.dropdownHead, {
  width: max-content; }

.dropdown {
  min-width: 200px; }

.info {
  width: 100%;
  text-align: center; }

/* -------------------------------------------------------------------------- */
/*                                 PAGINATION                                 */
/* -------------------------------------------------------------------------- */

.foot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-top: 40px;
  text-align: center;
  @include x {
    margin-top: 32px;
    margin-bottom: 32px; }
  @include m {
    margin-top: 24px;
    margin-bottom: 24px; } }

.pagination_numbers {
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 10px;
  color: $n7;
  &:hover {
    background-color: $n4; }
  @include dark {
    color: $n2;
    &:hover {
      background-color: $n5; } } }
