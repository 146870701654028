@import "../../styles/helpers";

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 320px);
        width: calc(100% - 300px);
        padding-right: 18px;
        @include x {
            flex: 0 0 calc(100% - 320px);
            width: calc(100% - 320px); }
        @include d {
            // flex: 0 0 calc(100% - 200px)
            // width: calc(100% - 312px)
            width: 100%;
            flex: 100%; }

        @include t {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; }
        @include m {
            width: 100%; } }
    &:nth-child(2) {
        // flex-shrink: 0
        width: 340px;
        @include x {
            width: 324px; }
        @include d {
            width: 0; }
        // +t
 } }        //     width: 100%

.card {
    &:not(:last-child) {
        margin-bottom: 8px; } }

.notadd {
    color: $n4;
    margin: 2rem auto;
    text-align: center; }

.header {
    background-color: $n;
    width: 100%;
    padding: 1.5rem;
    border-radius: 12px;
    font-size: 20px;
    position: relative;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 1.5rem;
        width: 16px;
        height: 32px;
        transform: translateY(-50%);
        border-radius: 4px;
        background-color: #5969e1; }
    @include dark {
        background-color: $n7; }
    h1 {
        color: $n8;
        position: relative;
        display: inline-block;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.6;
        letter-spacing: -.02em;
        margin-left: 2rem;
        @include dark {
            color: $n; } }
    .options {
        width: 50px;
        border-radius: 10px;
        background-color: $n3;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before, &::after {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 6px;
            background-color: $n4; }
        &::before {
            margin-right: 20px; }
        &::after {
            margin-left: 20px; }
        @include dark {
            background-color: $n5; }
        span {
            width: 6px;
            height: 6px;
            border-radius: 6px;
            background-color: $n4; } }

    .content {
        position: absolute;
        z-index: 99;
        top: calc(100% + 5px);
        right: -27px;
        padding: 0.3rem;
        border: 1px solid transparent;
        border-radius: 10px;
        box-shadow: 0 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
        background: $n;
        visibility: hidden;
        opacity: 0;
        transform: translateY(3px);
        transition: all .2s;
        display: flex;
        justify-content: center;
        @include x {
            top: calc(100% + 15px); }
        @include m {
            top: 55px;
            right: 0; }
        @include dark {
            background: $n8;
            border-color: $n6; }

        .groupDelete {
            width: 100px;
            height: 30px;
            border-radius: 10px;
            background-color: $n3;
            transition: 250ms;
            color: $n5;
            &:hover {
                background-color: #ff4d4d;
                color: $n1; }
            @include dark {
                background-color: $n5;
                color: $n4;
                &:hover {
                    background-color: #ff4d4d;
                    color: $n1; } }
            @include m; } } }

.margin_bottom {
    margin-bottom: $cards-margin; }

.notification {
    position: relative;
    @include m {
        position: static; }
    &.active {
        .content {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.pinElement {
    list-style: none;
    text-decoration: none; }

.alert {
    margin: 30px 0;
    font-style: italic;
    text-align: center;
    color: $n4; }

.isEmpty {
    font-size: 1rem;
    text-align: center;
    padding: 1rem;
    border-radius: 12px;
    background-color: $n2;
    @include dark {
        background-color: $n7; }
    @include m {
        font-size: 12px; } }

.foot {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    margin-top: 40px;
    text-align: center;
    @include x {
        margin-top: 32px;
        margin-bottom: 32px; }
    @include m {
        margin-top: 24px;
        margin-bottom: 24px; } }
