@import "styles/helpers";

.form {
    margin-bottom: 12px;
    input {
        height: 60px;
        padding-left: 64px; } }

.formIcon {
    padding-left: 20px; }

.info {
    display: flex;
    align-items: center;
    @include caption1;
    color: $n4;
    gap: 4px; }

.categories {
    display: flex;
    flex-wrap: wrap; }

.breadcrumbs {
    margin-bottom: 8px;
    color: black;
    @include dark {
        color: white; } }
