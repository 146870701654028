@import "styles/helpers";

.container {
    @include element-flex(column,space-between);
    gap: 24px; }

.header {
    @include title1-s;
    font-weight: 500; }

.languages_list {
    @include element-flex(row);
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px; }

.language_container {
    @include element-flex(row);
    gap: 12px;
    padding: 12px;
    border-radius: 12px;
    background: $n3;
    @include dark {
        background: $n6; }
    @media (max-width: 600px) {
        width: 100%; } }

.image {
    height: 48px;
    width: 72px;
    img {
        height: 100%;
        width: 100%;
        border-radius: 8px; } }

.details {
    .details {
        word-break: break-all; } }

.language_name {
    @include body1-m; }

.language_level {
    @include body1-m;
    color: $n4; }

.no_information {
    @include body1-m;
    color: $n4; }
