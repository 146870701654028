@import "../../styles/helpers";


.skeleton, .skeleton_user, .skeleton_group,.skeleton_related {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    padding: 1.5rem;
    border-radius: 10px;
    border: 1px solid #ebebeb;
    margin-bottom: 1rem;
    @include dark {
        border: 1px solid #313131; }
    span {
        @include dark {
            --base-color: #313131;
            --highlight-color: #525252; } } }
.img {
    margin-right: 1rem; }


.content {
    flex: 1;
    margin: auto; }

// --------- SKELETON-USER -------
.skeleton_user, .skeleton_related {
    flex-direction: column;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    margin: auto;
    .img {
        margin-right: 0;
        margin-bottom: 5px; }
    .content {
        height: 24px;
        width: 70px; } }

// --- SKELETON-GROUP ---
.skeleton_group {
    padding: 0px;
    border: none !important; }

// --- SKELETON-RELATED ---
.skeleton_related {
    min-width: 250px;
    .content {
        width: 90%; } }

