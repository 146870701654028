@import "styles/helpers";

.container {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    align-items: stretch; }

.nav {
    padding: 0 24px;
    border-radius: $default-radius;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    // display: flex
 }    // gap: 16px

.button {
    @include body1-m;
    padding: 12px;
    color: $n4;
    position: relative;
    transition: all .3s ease;

    &::after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background: $p1;
        transition: all .3s ease;
        top: 80%;
        left: 0;
        opacity: 0; }

    &:hover {
        color: $n6;
        @include dark {
            color: $n1; }

        &::after {
            opacity: 1;
            top: 90%; } }

    &.active {
        color: $n6;
        @include dark {
            color: $n1; }
        &::after {
            opacity: 1;
            top: 90%; } } }

@media(max-width: 900px) {
    .button {
        font-size: 15px;
        padding: 15px; } }

@media(max-width: 600px) {
    .button {
        font-size: 10px;
        padding: 10px; } }
