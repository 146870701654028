@import "styles/helpers";

.facet {
  @include element-flex(column, flex-start, stretch);
  gap: 4px;
  width: 100%; }

.header {
  @include element-flex(row, flex-start, center);
  gap: 8px; }

.title {
  @include title2;
  color: $n7;
  @include dark {
    color: $n3; } }

.description {
  @include body1-m;
  color: $n4; }
