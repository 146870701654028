@import "styles/helpers";

.header {
    position: fixed;
    top: 0;
    left: 96px;
    right: 0;
    z-index: 12;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 40px;
    background: $n1;
    box-shadow: inset 1px 0px 0px $n2, inset 0 -1px 0px $n3;
    transition: padding .2s ease-out;
    &.expanded {
        left: 300px; }
    @include x {
        //left: 300px
        padding: 16px 24px; }
    @include d {
 }        //left: 96px
    @include m {
        padding: 12px;
        left: 0; }
    &.sticky {
        padding-block: 12px; }
    @include dark {
        background: $n7;
        box-shadow: inset 1px 0px 0px $n8, inset 0 -1px 0px $n8; } }

.leftWrap {
    display: flex;
    gap: 6px; }

.burger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    // margin-right: auto
    @include m {
        display: flex; }
    &:before,
    &:after {
        content: "";
        width: 16px;
        height: 2px;
        border-radius: 2px;
        background: $n4; }
    &:before {
        margin-bottom: 6px; } }

.control {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-left: auto;
    @include m {
        margin-left: 0; }
    @include s {
        gap: 10px; } }

.control .button,
.control > div,
.buttonSearch {
    &:not(:last-child) {
 } }        // margin-right: 24px

.buttonSearch {
    display: none;
    width: 48px;
    height: 48px;
    @include m {
        display: inline-block; }
    svg {
        fill: $n4; }
    &.active {
        svg {
            fill: $n7;
            @include dark {
                fill: $n1; } } } }

.control .button {
    @include t {
        display: none; } }

.search {
    @include m {
        visibility: hidden;
        opacity: 0;
        &.visible {
            visibility: visible;
            opacity: 1; } } }

.btns {
    display: flex;
    align-items: center;
    margin-left: auto;
    @include m {
        margin-left: 0; } }

.pic {
    height: 48px;
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @include m {
        display: block; } }

.link {
    margin-right: 32px;
    @include button1;
    color: $n7;
    @include m {
        margin-right: 24px; }
    @include dark {
        color: $n1; }
    &:hover {
        color: $p1; } }

.header {
    @include m {
        .buttonSearch {
            margin-right: 0px; } } }

 :global {

    .mobile {
         :local {
            .header {
                padding: 12px;
                left: 0; }
            .burger {
 }                //display: flex
            .buttonSearch {
                display: inline-block; }
            .search {
                visibility: hidden;
                opacity: 0;
                &.visible {
                    visibility: visible;
                    opacity: 1; } }
            .btns {
                margin-left: 0; }
            .link {
                margin-right: 24px; } } } }
