@import "styles/helpers";

.container {
  display: flex;
  align-items: center;
  gap: 24px;
  @include m {
    gap: 12px;
    flex-direction: column; } }

.thumbnail {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  max-width: 200px;
  aspect-ratio: 5 / 4;
  //margin-right: 32px
  @include m {
 }    //margin-right: 16px
  img {
    width: 100%;
    height: 100%;
    //object-fit: cover
    border-radius: $default-radius; } }

.file {
  position: relative;
  display: inline-block;
  //margin-right: 12px
  overflow: hidden;
  @include m {
    margin-right: 0; }
  input {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 160px;
    opacity: 0; }
  .button {
    cursor: pointer;
    @include m {
      padding: 0 10px; } }
  input {
    height: 100%;
    cursor: pointer;
    &:hover + .button {
      background-color: darken($p1, 10);
      cursor: pointer; } } }

.remove {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $n1;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  font-size: 0;
  @include m {
    display: inline-block; } }

.button_remove {
  @include m {
    display: none; } }

 :global {
  .mobile {
     :local {

      .container {
        gap: 12px;
        flex-direction: column; }

      .file {
        margin-right: 0; }

      input {
        .button {
          padding: 0 10px; } }

      .remove {
        display: inline-block; }

      .button_remove {
          display: none; } } } }
