@import "../../styles/helpers";

.notLoggedIn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 16px;
    width: 100%;
    padding: 36px;
    p {
        font-size: 1.6em; }
    .panel {
        button {
            margin-right: 2em; } } }
