@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column; }

.content {
  padding: 0;
  background: transparent;
  @include dark {
    background: transparent; } }

.content_title {
  margin-bottom: 12px; }

.title {
  font-size: 24px;
  font-weight: 700; }

.subtitle {
  @include base1-s; }
