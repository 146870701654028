@import "styles/helpers";

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px; }

.col {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap; }

.author {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid $n3;
  transition: none;
  @include dark {
    border: 2px solid $n6; } }

.pin_button {
  padding: 12px;
  border-radius: 10px;
  transition: background-color .2s ease-in-out;
  border: 1px solid $n3;
  svg {
    path {
      fill: $p3; } }
  &:hover {
    background-color: $n3;
    border: 1px solid $n3; }
  @include dark {
    color: $n1;
    border: 2px solid $n6;
    &:hover {
      background-color: $n6; } } }

.rating {
  //margin-top: 8px
  width: 215px;
  display: flex; }

 :global {
  .mobile {
     :local {
      .container {
        //flex-direction: column
        align-items: flex-start;
        //justify-content: flex-start
        gap: 12px; } } } }
