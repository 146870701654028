@import "styles/helpers";

.row {
  display: flex;
  @include d {
    display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 340px);
        width: 100%;
        width: calc(100% - 340px);
        padding-right: 12px;
        flex-shrink: 0;
        @include x {
            flex: 0 0 calc(100% - 324px);
            width: calc(100% - 324px); }
        @include d {
            //flex: 0 0 calc(100% - 312px)
 }            //width: calc(100% - 312px)
        @include d {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 340px;
        @include x {
            width: 324px; }
        @include d {
            width: 100%; } } }

.items_list {
  display: flex;
  flex-direction: column;
  gap: $cards-margin; }

.card {
  &:not(:last-child) {
    margin-bottom: 12px; } }

.options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 12px;
  .box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px; }
  .open_map_btn {} }


.foot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-top: 40px;
  text-align: center;
  @include x {
    margin-top: 32px;
    margin-bottom: 32px; }
  @include m {
    margin-top: 24px;
    margin-bottom: 24px; } }

.pagination_numbers {
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 10px;
  color: $n7;
  &:hover {
    background-color: $n4; }
  @include dark {
    color: $n2;
    &:hover {
      background-color: $n5; } } }

.info {
  margin: 30px 0;
  font-style: italic;
  text-align: center;
  color: $n4; }
