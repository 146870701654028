@import "styles/helpers";

.container {
    display: flex;
    flex-direction: column;
    gap: 24px; }

.links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
    border-radius: 2px;
    width: 100%;

    .icon {
        margin-top: -1px;
        height: 16px;
        margin-right: 4px; }

    a:hover .icon {
        filter: invert(43%) sepia(21%) saturate(5850%) hue-rotate(199deg) brightness(100%) contrast(101%); } }

.link {
    flex-shrink: 0;
    word-break: break-word;
    width: fit-content;
    height: fit-content;
    padding: 4px 12px 4px 8px;
    border-radius: $default-radius;
    font-size: 13px;
    transition: .2s ease-in-out;
    color: $n7;
    background-color: $n3;
    @include dark {
        color: $n3;
        background-color: $n5; } }
