@import "../../../styles/helpers";


.sidesubs {
    width: 100%;
    height: 100%;
    max-height: 275px;
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    overflow-y: scroll;
    overflow-x: visible;
    margin-bottom: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-right: 12px;
    &::-webkit-scrollbar {
        width: 6px; }
    &::-webkit-scrollbar-track {
        border-radius: 100px; }
    &::-webkit-scrollbar-thumb {
        background-color: $n3;
        border-radius: 100px;
        @include dark {
            background-color: $n6; } }
    gap: 0.5rem;

    p {
      color: $n4;
      padding-left: 12px;
      font-size: 0.8rem;
      font-weight: normal; }

    li {
      display: flex;
      height: 46px;
      a {
        color: $n4;
        width: 100%;
        padding: 0 12px;

        border-radius: 10px;
        &:hover {
              color: $n5;
              background-color: $n2; }

        @include dark {
             &:hover {
                  color: $n;
                  background-color: $n6; } } }
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 1rem; } } }


.add {
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 48px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $n3;
    transform: translateY(-50%);
    font-size: 0;
    @include d {
        display: none; }
    @include m {
        display: flex; }
    @include dark {
        border-color: $n5; }
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        transition: border-color .2s;
        border-color: $n7;
        svg {
            fill: $n7; }
        @include dark {
            border-color: $shades1;
            svg {
                fill: $shades1; } } }
    &.active {
        border-color: $shades1;
        svg {
            fill: $shades1; } } }

.link {
    position: relative;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 12px;
    border-radius: 12px;
    color: $n4;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:before {
        content: "";
        position: absolute;
        top: 12px;
        left: -13px;
        width: 12px;
        height: 12px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath d='M1 1v4a8 8 0 0 0 8 8h4' stroke='%23efefef' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto;
        @include dark {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath d='M1 1v4a8 8 0 0 0 8 8h4' stroke='%23272B30' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E"); } }
    svg {
        margin-left: auto;
        opacity: 0;
        fill: $n7;
        transition: opacity .2s;
        @include dark {
            fill: $n1; } }
    &:hover {
        transition: background .2s, box-shadow .2s, color .2s;
        color: $n7;
        @include dark {
            color: $n1; } }
    &.active {
        background: $n3;
        box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF;
        color: $n7;
        @include dark {
            background: $n6;
            box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11);
            color: $n1; }
        svg {
            opacity: 1; } } }
