@import "styles/helpers";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px; }

.details {
  display: flex;
  align-items: center;
  gap: 16px;
  @include s {
    flex-direction: column; }

  .image {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 50%; } } }

.background_image {
  width: 100%;
  max-width: 500px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: $default-radius; } }

.user_info {
  display: flex;
  flex-direction: column;
  //gap: 4px

  .username {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    font-size: 18px;
    color: $n8;
    @include dark {
      color: $n1; } }

  .time {
    font-weight: 400;
    font-size: 14px;
    color: $n4; } }

.subheader {
  margin-top: 24px; }

.informations_title,
.title {
  margin-bottom: 12px; }

.list_item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  b {
    color: $n4; }
  svg {
    path {
      fill: $n4; } } }

.user_content {
  display: flex;
  flex-direction: column;
  .informations_title {
    margin-bottom: 24px; } }

.title_head {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  svg {
    transition: transform .2s ease-in-out; }
  &.active {
    svg {
      transform: rotate(180deg); } }
  svg {
    path {
      fill: $n4; } } }

.publications {
  margin-top: 24px; }

.user_actions {
  display: flex;
  flex-direction: column;
  gap: 24px; }

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  button {
    flex-shrink: 0; } }

.bio_title {
  width: 100%; }

.content {
  margin-top: 24px;
  overflow: hidden;
  word-break: break-word;
  text-align: justify;
  &.hide {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; } }

.info {
  width: 100%;
  padding: 30px 0;
  text-align: center;
  color: $n4;
  font-weight: lighter;
  font-style: italic; }

.chartContainer {
  display: flex;
  flex-direction: column;
  gap: 40px; }

.chartTitle {
  @include title1-s;
  margin-left: 8.2%;
  margin-top: 30px; }

.userChart {
  border-radius: $default-radius;
  @include dark {
      background-color: rgba(255, 255, 255, .02); } }

.dropdown {
  margin-bottom: 20px; }

.toggle_edit {
  display: block;
  @include m {
    display: none; } }

.toggle_edit_mobile {
  display: none;
  margin-bottom: 24px;
  width: 100%;
  @include m {
    display: block; } }

.go_to_profile_btn {
  display: flex;
  @include m {
    display: none; } }

.go_to_profile_btn_mobile {
  display: none;
  @include m {
    display: flex; } }
