@import "../../styles/helpers";

.related {
    padding: 24px;
    border-radius: 12px;
    background-color: $n;
    &:not(:first-child) {
        margin-top: 12px; }
    @include dark {
        background-color: $n7; } }

.relatedList {
    display: flex;
    gap: 1rem;
    overflow-x: scroll;
    padding-bottom: 12px;
    &::-webkit-scrollbar {
        height: 6px; }
    &::-webkit-scrollbar-track {
        border-radius: 100px; }
    &::-webkit-scrollbar-thumb {
        background-color: $n3;
        border-radius: 100px;
        @include dark {
            background-color: $n6; } } }

.sc_title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 16px;
        height: 32px;
        transform: translateY(-50%);
        border-radius: 4px;
        background-color: #9659e1; }
    h4 {
        margin-left: 2rem;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.6;
        letter-spacing: -.02em;
        @include m {
            font-size: 16px; } } }

.isEmpty {
    font-size: 1rem;
    text-align: center;
    padding: 1rem;
    border-radius: 12px;
    background-color: $n2;
    @include dark {
        background-color: $n6; }
    @include m {
        font-size: 12px; } }

.loader {
    display: flex;
    gap: 1rem;
    overflow-x: scroll; }

.btn_more {
    background-color: #9659e1;
    color: $n;
    transition: 250ms;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    font-size: 0.7rem;
    &:hover {
        background-color: #7345ab; } }

.related_job {
  min-width: 250px;
  width: 250px;
  max-width: 250px;
  height: 100%;
  padding: 0 !important;
  & > * {
    height: 100% !important; } }

.item, .related_job {}

.item {
  height: 100% !important;
  padding: 10px 15px !important;
  @include dark {
    background-color: $n6 !important; } }
