@import "../../../styles/helpers";

.actions {
    margin-left: 16px; }

.actionsHead {
    @include dark {
        background: transparent; } }

.dropdown {
    min-width: 105px; }

.foot {
    margin-top: 32px;
    text-align: center;
    .button {
        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }

.card {
    min-width: 350px; }

.setreadbtn {
    position: absolute;
    top: 25px;
    right: 20px;
    @include d {
        top: 15px;
        right: 12px;
        p {
            display: none; } } }
