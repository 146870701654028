@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: $title-content-margin; }

.title {
 }  //margin-bottom: $title-content-margin

.section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 36px; }

.info {
  font-style: italic;
  text-align: center;
  color: $n4; }

.subsection_title {
  color: $p1;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid $p1;
  margin-bottom: 8px; }

.subsection {
  font-weight: 400;
  color: $n7;
  @include dark {
    color: $n3; } }

.attachment {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  padding: 8px 14px;
  border-radius: $default-radius;
  background-color: $n3;
  svg {
    fill: $p1; }
  @include dark {
    background-color: $n6; }
  &::after {
    content: 'Pobierz';
    pointer-events: none;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $default-radius;
    transition: .2s;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: darken($n3, 10);
    opacity: 0;
    @include dark {
      background-color: lighten($n6, 10); } }
  &:hover {
    &::after {
      opacity: 1; } } }

.job {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0; }
