@import "styles/helpers";

.skeleton {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 10px;
  &:not(:last-of-type) {
    margin-bottom: 10px; }
  span {
    @include dark {
      --base-color: #313131;
      --highlight-color: #525252; } } }

.left {
  margin-right: 10px; }
