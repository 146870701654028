@import "styles/helpers";

.switchWrapper {
  @include element-flex(row, flex-start, center);
  gap: 12px; }

.label {
  @include body1-m;
  color: $n7;
  @include dark {
    color: $n3; } }

.info {
  @include caption2-m;
  color: $n4; }
