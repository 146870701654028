@import "../../styles/helpers";

.avatar {
  position: relative;
  flex-shrink: 0;
  width: 80px;
  aspect-ratio: 1;
  //height: 80px
  margin-right: 16px;
  img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      border: 4px solid $p1; }
  .level {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid $p1;
      font-size: 12px;
      background-color: $n2;
      color: $n7;
      @include dark {
        background-color: $n5;
        color: $n1; } } }

.avatar_small {
  position: relative;
  flex-shrink: 0;
  width: 48px;
  //height: 48px
  aspect-ratio: 1;
  margin-right: 16px;
  cursor: pointer;
  img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
 }      //border: 3px solid $p1
  .level {
      position: absolute;
      bottom: -3px;
      right: -3px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      //border: 2px solid $p1
      font-size: 12px;
      background-color: $p1;
      color: $n1;
      transition: .5s ease;
      &.animate {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 20px;
        width: 100%;
        height: 100%;
        background-color: $p4; } } }
