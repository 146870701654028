@import "styles/helpers";

.container {
  padding: 24px; }

.article {
  padding: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 0;
  margin-bottom: 0px;
  border-radius: 12px;
  background: white; }

.pinElement {
  list-style: none;
  text-decoration: none; }

.isEmpty {
  width: 100%;
  padding: 64px 0;
  @include info;
  text-align: center; }

.margin_bottom {
  margin-bottom: $cards-margin; }
