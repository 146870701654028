@import "styles/helpers";

.container {
    width: 100%;
    @include element-flex(column,space-between);
    gap: 24px; }

.header {
    @include title1-s;
    font-weight: 500; }

.education_section {
    width: 100%;
    @include element-flex(column,space-between);
    gap: 12px; }

.education_container {
    @include element-flex(column,space-between);
    gap: 12px;
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    background: $n3;
    @include dark {
        background: $n6; } }

.education_list {
    width: 100%;
    display: grid;
    grid-template-columns: 72px 1fr 24px;
    gap: 12px; }

.image {
    height: 72px;
    width: 72px;
    border-radius: 12px;
    img {
        border-radius: 12px;
        height: 100%;
        width: 100%; } }

.information {
    word-break: break-all; }

.school {
    @include body1-m;
    word-break: break-word; }

.field {
    @include body1-m;
    color: $n4;
    word-break: break-word; }

.date {
    @include body1-m;
    color: $n4;
    word-break: break-word; }

.description_display {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center; }

.description_button {
    height: 24px;
    width: 24px;
    padding: 0;
    background: none;
    &:hover {
        background: none; }
    svg {
        fill: $n4;
        margin-right: 0px;
        margin-left: 0px !important; } }

.description_button_open {
    svg {
        transform: rotate(180deg); } }

.description {
    @include body1-m;
    color: $n4;
    padding-bottom: 12px; }

.no_information {
    @include body1-m;
    color: $n4; }
