@import "../../../styles/helpers";

.autor {
    margin-right: auto;
    width: 100%;
    margin-bottom: 6px;
    @include m {
        line-height: 40px; } }

.list {}


.item {
    padding: 24px;
    border-radius: 0;
    margin-bottom: 0px;
    border-radius: 12px;
    background: white; }

.post {
    flex-shrink: 0;
    width: 25%;
    height: auto;
    margin-right: 12px;
    border: 2px solid $n3;
    overflow: hidden;
    border-radius: 20px;
    max-width:  300px;
    max-height: 300px;
    @include dark {
        border: 2px solid $n; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include m {
            max-height: 190px; } }
    @include m {
        width: 100%;
        margin-bottom: 10px; } }

.details {
    flex-grow: 1; }

.line {
    display: flex;
    align-items: center;
    margin-top: 20px; }

.inline {
    display: flex; }

.user {
    margin-right: auto; }

.title {
    font-weight: 700;
    @include m {
        font-size: 20px; } }

.login {
    font-weight: 500;
    color: $shades1; }

.time {
    margin-left: 20px;
    @include caption1;
    color: $shades1; }

.content {
    margin-top: 10px;
    margin-bottom: 8px;
    font-weight: 500;
    color: $shades1;
    overflow: hidden;
    word-break: break-word;
    // -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 22px; /* fallback */;
    max-height: 66px; /* fallback */;
    strong {
        font-weight: 700;
        color: $n7;
        @include dark {
            color: $n1; } }
    @include m {
        margin: 10px; } }
.equalizer {
    margin-top: -25px; }
.autor {
    margin-bottom: 16px;
    font-weight: 500;
    color: $n7;
    margin-bottom: 40px;
    display: flex;
    @include dark {
        color: $n1; } }

.autorname {
    margin-top: 20px; }
.stars {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 80px; }
.price {
    display: flex;
    align-items: center;
    margin-left: 100px;
    margin-bottom: -14px;
    span {
        margin-left: 10px;
        margin-right: 10px; }
    div {
        font-size: 50px; } }
.inshops {
    display: flex;
    align-items: center;
    margin-left: 120px;
    margin-top: -20px;
    margin-bottom: 20px;
    span {
        margin-left: 10px;
        margin-right: 10px; } }
.control {
    margin-top: auto;
    margin-bottom: auto; }
.button {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 50px;
    padding-left: 50px;
    transition: color .2s;
    color: $n5;
    border-radius: 10px;
    span {
        min-width: 150px;
        text-align: center; }
    &:hover {
        background-color: $n2;
        transition: .2s;
        @include dark {
            background-color: $n7; } } }
.buttonP {
    @extend .button;
    background-color: $p3; }
.buttonK {
    @extend .button;
    background-color: $p2; }
.buttonO {
    @extend .button;
    background-color: $p1; }
.reactions {
    margin-top: 70px;
    display: flex; }
.emoji {
    display: flex;
    align-items: center;
    margin-left: 15px;
    img {
        width: 38px;
        height: 38px; } }
.info {
  width: 100%;
  padding: 64px 0;
  @include info;
  text-align: center; }
