@import "styles/helpers";

.card {
  padding: 0; }

.title {
  margin-bottom: 24px; }

.form {
  display: flex;
  flex-direction: column;
  gap: 24px; }

.row {
  display: flex;
  gap: 24px; }

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  label {
    @include base2;
    font-size: 15px;
    color: $n5;
    @include dark {
      color: $n3; }
    .info {
      color: $n4;
      font-size: 14px; } }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .required {
      color: $p1;
      @include dark {
        color: $p2; } } } }

.counter {
  font-weight: 400;
  font-size: 14px;
  color: $n4;
  &.error {
    color: #ff0000; } }

.textarea {
  width: 100%;
  height: 400px;
  padding: 10px;
  border-radius: 12px;
  border: 2px solid transparent;
  background: $n2;
  @include inter;
  @include base1-s;
  color: $n7;
  transition: all .2s;
  @include dark {
    border-color: $n6;
    background: $n6;
    color: $n1; }
  @include placeholder {
    color: $n4;
    @include dark {
      color: $shades1; } }
  &:focus {
    border-color: $shades1;
    background: $n;
    @include dark {
      border-color: $n5;
      background: $n8; } } }

.container {
  display: flex;
  flex-direction: column;
  gap: 24px; }

.message {
  color: $n6;
  @include dark {
    color: $n3; } }

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px; }

 :global {
  .mobile {
     :local {

      .row {
        flex-direction: column; } } } }

