@import "styles/helpers";

.label {
  margin-top: 10px; }

.selecteddate {
  position: relative;
  .dateinput {
    cursor: pointer;
    display: flex;
    padding: 10px 0;
    background: $n2;
    border-radius: 20px;
    font-size: 1.2em;
    margin: 5px 0 10px 0;
    svg {
      fill: $n4;
      margin: 0 10px; }
    p {
      border-left: 2px solid $n4;
      padding-left: 10px; } }
  .date {
    position: absolute;
    top: 55px;
    background: $n2;
    box-shadow: 0px 2px 7px $shades1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 20px; }
  @include dark {
    .dateinput {
      background: $n7;
      border: 2px solid $n5; }
    .date {
      border: 2px solid $n5;
      background: $n7;
      box-shadow: 0px 2px 7px $shades4;
      &>.react-datepicker__day--selected {
        background: $n4 !important; } } } }
.reason {
  padding: 10px;
  background: $n2;
  border-radius: 20px;
  margin: 5px 0 10px 0;
  resize: none;
  width: 100%;
  height: 100px;
  font-size: 1.1em;
  @include dark {
    color: $n;
    background: $n7;
    border: 2px solid $n5; } }

.buttons {
  display: flex;
  justify-content: space-between;
  .submit {
    background: $p3; } }
