@import "styles/helpers";

.heading {
  margin-bottom: 12px; }

.basic, .locations, .locations_list, .contact {
  margin-bottom: 32px; }

.profile {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  @include t {
      margin-bottom: 24px; }
  & > .button {
      @include m {
          display: none; } } }

.avatar {
  position: relative;
  flex-shrink: 0;
  width: 96px;
  height: 96px;
  margin-right: 32px;
  @include m {
    width: 80px;
    height: 80px;
    margin-right: 16px; }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%; } }

.file {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  overflow: hidden;
  @include m {
    margin-right: 0; }
  input {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 160px;
    opacity: 0; }
  .button {
    cursor: pointer;
    @include m {
      padding: 0 10px; } }
  input {
    height: 100%;
    cursor: pointer;
    &:hover + .button {
      background-color: darken($p1, 10);
      cursor: pointer; } } }

.remove {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $n1;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  font-size: 0;
  @include m {
    display: inline-block; } }

.row {
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  @include a {
    grid-template-columns: 1fr; } }


.errormessage {
  color: $p3;
  font-style: italic; }


.submit_button {
  display: flex;
  align-items: center;
  gap: 20px;
  transition: opacity .2s ease-in-out;
  margin-bottom: 32px;
  @include m {
    width: 100%; }
  &.disabled {
    opacity: 0.8; }
  .loader {
    display: none;
    &.visible {
      display: block; } } }

.add_location {
  button {
    margin-top: 8px;
    @include m {
      width: 100%; } } }

.locations_list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 24px;
  color: $n7;
  @include dark {
    color: $n2; }
  .locaton_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding: 8px 12px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: $n3;
    @include dark {
      background-color: $n6; }
    .location_item_data {
      @include body1-m; }
    .location_item_remove {
      padding: 5px;
      border-radius: 10px;
      transition: .2s ease-in-out;
      svg {
        transition: .2s ease-in-out; }
      &:hover {
        svg {
          transform: rotate(90deg); }
        background-color: $n1;
        @include dark {
          background-color: $n7; } }
      path {
        fill: $n7;
        @include dark {
          fill: $n1; } } } } }

.locations_input {
  padding: 0; }

 :global {
  .mobile {
     :local {

      .profile {
        & > .button {
          display: none; } }

      .avatar {
        width: 80px;
        height: 80px;
        margin-right: 16px; }

      .file {
        margin-right: 0;
        .button {
          cursor: pointer;
          @include m {
            padding: 0 10px; } } }

      .row {
        grid-template-columns: 1fr; }

      .remove {
        display: inline-block; }

      .add_location, .submit_button {
        width: 100%; } } } }
