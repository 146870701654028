@import "styles/helpers";

.row {
  display: flex;
  //min-width: 900px
  @include t {
    display: block; } }

.col {
    width: 100%; }
.nav {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 0;
  overflow-y: auto;
  margin-bottom: $cards-margin;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px; }
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $n3;
    @include dark {
      background: $n8; } }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $n1;
    @include dark {
      background: $n7; } }
  .item {
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    flex-shrink: 0;
    padding: 6px 12px;
    border-radius: 10px;
    background-color: $n1;
    color: $n7;
    svg path {
        fill: $n7; }
    @include dark {
      background-color: $n6;
      color: $n1;
      svg path {
        fill: $n1; } } } }
