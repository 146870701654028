@import "styles/helpers";

.container {
    display: grid;
    grid-template-columns: 1fr 250px;
    gap: 12px;
    width: 100%;
    @include m {
        grid-template-columns: 1fr; } }

.profile {
    position: relative;
    display: flex;
    background: white;
    padding: 24px;
    border-radius: 12px;
    gap: 12px;
    @include dark {
        background: $n7; }
    @include m {
        flex-direction: column; } }

.header {
    width: 100%;
    @include element-flex(row, space-between);
    gap: 12px;
    flex-wrap: wrap; }

.information {
    width: 100%;
    @include element-flex(column, space-between);
    gap: 12px; }

.avatar {
    position: relative;
    flex-shrink: 0;
    width: 85px;
    height: 85px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid white;
        @include dark {
            border-color: $n7; } }
    @include m {
        width: 52px;
        height: 52px; } }

.row {
    display: flex;
    width: 100%; }

.display_name {
    @include title1-s;
    line-height: 1; }

.followers {
    @include body2-s;
    color: $n4; }

.user_description_section {
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    gap: 8px; }

.user_description {
    @include body1-m;
    max-height: 48px;
    color: $n4;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis; }

.user_description_more {
    width: 30px;
    height: 30px;
    border-radius: 25%;
    transition: background .2s;
    &:hover {
        background: $n3;
        @include dark {
            background: $n6; } }
    svg {
        fill: $n4; } }

.add {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    border: 2px solid $n;
    border-radius: 50%;
    background: $p1;
    font-size: 0;
    transition: background .2s;
    svg {
        fill: $n; }
    &:hover {
        background: darken($p1, 5); } }

.info {
    margin-top: 8px;
    @include title1-s;
    color: $n4; }

.contacts {
    display: none;
    //display: flex
    align-items: center;
    flex-shrink: 0;
    margin-left: 40px;
    @include t {
        margin-left: 0; } }

.wrap {
    display: flex;
    gap: 24px;
    width: 100%;
    position: relative; }

.socials {
    display: flex;
    gap: 12px;
    flex-wrap: wrap; }

.social {
    //padding: 4px 8px
    border-radius: $default-radius;
    //background-color: $n6
    path {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        path {
            fill: $p1; } } }

.button {
    height: 40px;
    margin-left: auto;
    border: solid 1px $n1;
    @include dark {
        border: solid 1px $n5; } }

.status {
    text-transform: uppercase;
    margin-right: 24px; }

.report_button {
    margin-left: 24px; }

.buttons {
    margin-left: auto;
    display: flex;
    justify-items: flex-end;
    align-items: flex-end;
    gap: 8px;
    @include m {
        position: absolute;
        top: 24px;
        right: 24px; } }

.mobile_actions {
    margin-left: auto;
    display: none; }

.actions {
    display: inline-block;
    height: 35px; }

.badges_levels {
    @include element-flex(column, flex-start, stretch);
    gap: 12px;
    z-index: 10;
    padding: 24px;
    background: white;
    border-radius: 12px;
    @include dark {
        background: $n7; } }

.display_name_levels {
    @include body1-m; }

.levels {
    width: 100%;
    display: flex;
    gap: 12px; }

.level {
    flex-shrink: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    @include body1-s;
    margin-right: -5px;
    background-color: $n2;
    color: $n8;
    @include dark {
        color: $n1;
        background-color: $n6; } }

.level_container {
    width: 100%; }

.badge {
    display: flex;
    align-items: center;
    gap: 6px;
    color: $n4;
    img {
        border-radius: 4px;
        height: 24px; } }

.badges_button {
    width: 100%;
    font-size: 12px;
    color: $n4;
    margin-top: 12px;
    display: flex;
    position: relative; }

.badges_text {
    text-align: left;
    font-size: 12px;
    color: $n4; }

.down_arrow {
    position: absolute;
    right: 2%;
    border: solid #b3b9bf;
    @include dark {
        border-color: $n4; }
    width: 8px;
    height: 8px;
    margin-left: 12px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }

.up_arrow {
    position: absolute;
    right: 2%;
    border: solid #b3b9bf;
    @include dark {
        border-color: $n4; }
    width: 8px;
    height: 8px;
    margin-left: 12px;
    border-width: 0 2px 2px 0;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg); }

 :global {
    .mobile {
         :local {
            .man {
                font-size: 20px; }

            .info {
                margin-top: 4px;
                font-size: 15px; }

            .button {
                min-width: 132px; }

            .report_button {
                margin-left: 0; }

            .buttons {
                margin-left: 0; }

            .mobile_actions {
                display: inline-block; }

            .actions {
                display: none; } } } }
