@import "styles/helpers";

.wrapper {
  margin: 0 -12px;
  position: relative;

  display: grid;
  grid-template-columns: max-content minmax(340px, auto) minmax(144px, 160px) minmax(114px, 130px) minmax(120px, 160px) minmax(100px, 160px) minmax(130px, 140px) 80px 1fr;
  grid-template-rows: auto {

    // +r(1550)
    //     grid-template-columns: max-content minmax(340px, auto) minmax(114px, 130px) minmax(120px, 160px) minmax(100px, 160px) minmax(130px, 140px) 80px 1fr
    // +w
    //     grid-template-columns: max-content minmax(340px, auto) minmax(114px, 130px) minmax(120px, 160px) minmax(100px, 160px) minmax(auto, 140px) 1fr
    // +t
    //     display: flex
 }    //     flex-direction: column
  @include s {
    margin: 0; } }

.labels {
  display: contents {
    // +t
 } }    //     display: none

.divider {
  height: 1px;
  width: 98%;
  top: 56px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  background: $n3;
  @include dark {
    background: $n6;
    // +t
    //     top: 80px
    @include m {
      width: 94%; } }
  @include s {
    display: none; } }

.label {
  padding: 16px 12px;
  display: flex;
  align-items: center;
  color: $n4;
  @include dark {
    color: $shades1;
    @include caption1;
    @include s {
      display: none;
      padding: 10px 6px; } } }

.list {
  display: contents {
    // +t
 } }    //     display: block

.foot {
  margin-top: 20px;
  text-align: center {
    // +t
 }    //     margin-top: 0
  .button {
    .loader {
      transform: scale(.8);
      margin: 0 18px 0 8px; } } }

// <-- these are individual labels -->
.product {
  max-width: 460px;
  &-tablet {
    display: none;
    margin-left: 12px {
        // +t
 }        //     display: block
    @include s {
      display: none; } } }

.status {
  display: flex {
    // +r(1550)
 } }    //     display: none

.rating {
  display: flex {
    // +w
 } }    //     display: none

/* -------------------------------------------------------------------------- */
/*                            container responsive                            */
/* -------------------------------------------------------------------------- */

 :global {
  .r-lg { //+r(1550)
    grid-template-columns: max-content minmax(340px, auto) minmax(114px, 130px) minmax(120px, 160px) minmax(100px, 160px) minmax(130px, 140px) 80px 1fr;

     :local {
      .status {
        display: none; } } }

  .r-md { //+w
    grid-template-columns: max-content minmax(340px, auto) minmax(114px, 130px) minmax(120px, 160px) minmax(100px, 160px) minmax(auto, 140px) 1fr;

     :local {
      .rating {
        display: none; } } }

  .r-sm { //+t
    display: flex;
    flex-direction: column;

     :local {
      .labels {
        display: none; }
      .divider {
        top: 80px; }
      .list {
        display: block; }
      .foot {
        margin-top: 0; }
      .product-tablet {
        display: block; } } } }
