@import "styles/helpers";

.container {
    width: 100%;
    @include element-flex(column,space-between);
    gap: 24px; }

.header {
    @include title1-s;
    font-weight: 500; }

.certificates_section {
    width: 100%;
    @include element-flex(column,space-between);
    gap: 12px; }

.certificates_container {
    @include element-flex(column,space-between);
    gap: 12px;
    width: 100%;
    border-radius: 12px; }

.certificates_list {
    width: 100%;
    display: grid;
    grid-template-columns: 72px 1fr;
    gap: 12px; }


.image {
    height: 72px;
    width: 72px;
    border-radius: 12px;
    img {
        border-radius: 12px;
        height: 100%;
        width: 100%; } }

.information {
    word-break: break-all; }


.name {
    @include element-flex(row);
    gap: 12px;
    word-break: break-word;
    @include body1-m;
    a {
        width: fit-content;
        @include element-flex(row,flex-start,center);
        gap: 4px;
        color: black;
        transition: 0.1s linear;
        @include dark {
            color: white; }
        svg {
            fill: $p1; }
        &:hover {
            color: $p1;
            svg {
                fill: $p1; } } } }

.organization {
    word-break: break-word;
    @include element-flex(row);
    gap: 4px;
    @include body1-m;
    color: $n4; }

.id_text {
    word-break: break-word;
    @media (max-width: 600px) {
        display: none; } }

.date {
    word-break: break-word;
    @include body1-m;
    color: $n4; }

.description_display {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center; }

.description_button {
    height: 24px;
    width: 24px;
    padding: 0;
    background: none;
    &:hover {
        background: none; }
    svg {
        fill: $n4;
        margin-right: 0px;
        margin-left: 0px !important; } }

.description_button_open {
    svg {
        transform: rotate(180deg); } }

.description {
    @include body1-m;
    color: $n4; }

.no_information {
    @include body1-m;
    color: $n4; }
