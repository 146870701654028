@import "styles/helpers";

.row {
    display: contents; }

.tags {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    row-gap: 14px;
    column-gap: 10px; }

.tag {
    flex-shrink: 0;
    white-space: nowrap;
    width: fit-content;
    height: fit-content;
    padding: 6px 8px;
    border-radius: $default-radius;
    color: $n7;
    font-size: 13px;
    text-transform: uppercase;
    transition: .2s ease-in-out; }


.count {
    font-size: 13px;
    padding: 6px 8px;
    border-radius: $default-radius;
    background: rgba(0, 0, 0, .03);
    color: $n7;
    @include flex-center;
    @include dark {
        color: inherit;
        background: $n5; } }
