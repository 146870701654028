@import "../../../styles/helpers";


.skeleton {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: auto;
    padding: 36px;
    border-radius: 10px;
    //border: 1px solid #ebebeb
    background: $n1;
    @include dark {
        background: $n7;
 }        //border: 1px solid #313131
    span {
        @include dark {
            --base-color: #313131;
            --highlight-color: #525252; } } }

.title {
    margin-top: 24px;
    display: grid;
    gap: 24px;
    grid-template-columns: min-content auto; }

.title_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include m {
        display: none; } }

.img {
    @include m {
        width: 100%; } }


.head_btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
        display: flex;
        align-items: center;
        gap: 8px;
        &:last-of-type {
            @include m {
                display: none; } } } }

.card {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 48px; }
