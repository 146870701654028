@import "../../styles/helpers";

.breadcrumb {
  @include element-flex(row, flex-start, center);
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 16px;
  background-color: $n2;
  border-radius: 12px;
  @include dark {
    background-color: $n6; } }

.item {
  @include element-flex(row, flex-start, center);
  gap: 8px;
  color: $n7;
  transition: 0.2s ease-in-out;
  @include dark {
    color: $n1; }

  &:hover {
    color: $p1;
    img {
      animation: pulse 2s infinite; } }

  &::after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-right: 2px solid;
    border-top: 2px solid;
    border-radius: 2px;
    border-color: $n4;
    transform: rotate(45deg);
    margin-right: 6px; }

  &:last-child {
    &::after {
      display: none; } }

  img {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    object-fit: contain;
    transition: 0.2s ease-in-out; } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(11, 133, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(11, 133, 255, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    box-shadow: 0 0 0 10px rgba(204,169,44, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0); } }
