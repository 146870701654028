@import "styles/helpers";

.filters {
    position: relative;
    &.active {
        .body,
        .overlay {
            visibility: visible;
            opacity: 1; } } }

.body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 110;
    width: 1000px;
    height: fit-content + 0px;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n1;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    @include dark {
        background: $n7; } }

@media (max-width: 1020px) {
    .body {
        top: 50%;
        right: -50%;
        left: 50%;
        bottom: -50%;
        width: auto;
        overflow: auto; } }

@media (max-height: 900px) {
    .body {
        top: 50%;
        right: -50%;
        left: 50%;
        bottom: -50%;
        width: auto;
        overflow: auto; } }

.radio_box {
    display: grid;
    grid-template-columns: 150px 150px; }


.top {
    display: flex;
    align-items: center;
    margin-bottom: 24px; }

.close {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: $n3;
    margin-left: 24px;
    svg {
        fill: $n7;
        transition: transform .2s; }
    @include dark {
        background: $n6;
        svg {
            fill: $n1; } }
    &:hover {
        svg {
            transform: rotate(90deg); } } }

.title {
    margin-right: auto; }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 109;
    background: rgba($n2, .8);
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: rgba($n6, .9); } }

.input_container {
  width: 100%;
  display: grid;
  gap: 10px;
  position: {}
  .button {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0px 0px 0px 50px;
        border-radius: 12px 12px 12px 12px; } }

.input_title {
  flex: 1 1 40%;
  margin-bottom: 12px; }

.price {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  @include t {
    grid-template-columns: 1fr 1fr; }
  margin: 0px 0px 20px 0px; }

.checkbox_box {
    width: 100%;
    margin-bottom: 12px; }

.data_box {
    width: calc(250px + 10%);
    margin-bottom: 24px; }

.modal_footer {
  margin-top: 25px;
  display: flex;
  justify-content: center; }

.coursesList {
    justify-content: flex-start;
    margin-top: 10px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill,222px); }

.coursesListItem {
    padding: 10px;
    border-radius: 8px;
    background-color: $n3;
    color: black;
    @include dark {
        color: $n3;
        background-color: $n5; }
    text-align: start;
    font-size: 15px;
    fill: transparent;
    &:hover {
        fill: gray;
        @include m {
            fill: red; } } }

.item {
    display: grid;
    grid-template-columns: 90fr 10fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; }

.item_title {
    width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 24pxg; }


.trash_icon {
    &:hover {
        fill: red;
        transform: scale(1.25); } }


.paginationPosition {
    display: flex;
    bottom: 0px;
    margin-top: 10px;
    align-items: center;
    justify-content: center; }

.pagination {
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 10px;
    color: $n7;
    &:hover {
        background-color: $n4; }
    @include dark {
        color: $n2;
        &:hover {
            background-color: $n5; } } }

@media (max-width: 480px) {
    .coursesListItem {
        font-size: 12.5px;
        padding: 7.5px; }
    .coursesList {
        grid-template-columns: repeat(auto-fill, 150px); } }

.courses_title {
    margin-right: auto;
    font-size: 20px; }
