@import "styles/helpers";

.card {
    @include d {
        display: none; } }

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    span {
        @include dark {
            --base-color: #313131;
            --highlight-color: #525252; } } }

.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
    .col {
        display: flex;
        align-items: center;
        gap: 8px; } }

.content_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid $n3;
    @include dark {
        border-color: $n5; } }

.content {
    display: flex;
    flex-direction: column;
    gap: 12px; }

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px; }

.list {
    display: flex;
    flex-direction: column;
    gap: 24px; }

.button {
    width: 100%; }
