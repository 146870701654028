@import "../../../styles/helpers";

.details, .location {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px 48px;
  @include m {
    grid-template-columns: 1fr;
    gap: 24px; }
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    @include m {
      grid-template-columns: 1fr;
      gap: 12px; } }
  .col {
    display: flex;
    flex-direction: column;
    gap: 24px; } }

.price {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  @include t {
    grid-template-columns: 1fr; } }

.add_btn {
  width: fit-content; }

.list {
  margin-bottom: 24px; }

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 8px 12px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid $n3;
  background-color: $n1;
  @include dark {
    background-color: $n7;
    border: 2px solid $n6; }
  .item_data {
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 12px;
    text-transform: uppercase;
    .type {
      color: $n4; } }
  .delete {
    padding: 6px;
    border-radius: 10px;
    transition: .2s ease-in-out;
    color: $n7;
    @include dark {
      color: $n3; }
    &:hover {
      background-color: $n3;
      @include dark {
        background-color: $n6; } }
    path {
      fill: $n7;
      @include dark {
        fill: $n1; } } } }
