@import "styles/helpers";

.container {
  position: relative; }

.bar {
  position: relative;
  height: 100%;
  width: 10px;
  // border: 1px solid $n4
  background-color: $n3;
  border-radius: $default-radius / 2;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  @include dark {
    background-color: $n5; } }

.bar_fill {
  width: 100%;
  height: 0%;
  background-color: $p1;
  border-radius: $default-radius / 2;
  transition: all .5s ease-in-out;
  &.animate {
    background-color: $p4; } }

.add_xp_info {
  display: none;
  &.animation {
    display: block;
    position: absolute;
    bottom: -50%;
    left: -100%;
    font-size: 18px;
    transform: translate(-50%, 50%);
    animation: add_xp 2s ease forwards .1s; } }


@keyframes add_xp {
  0% {
    font-size: 18px; }
  25% {
    bottom: -50%;
    left: -100%;
    opacity: 1; }
  100% {
    bottom: 50%;
    left: 50%;
    opacity: 0;
    font-size: 12px; } }
