@import "../../../styles/helpers";

$color-error: #cf0000;

.input_container {
  width: 100%;
  position: relative;
  button {
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 0 12px 12px 0; } }

.input {
  width: 100%;
  height: 48px;
  padding: 0 10px;
  border-radius: 12px;
  border: 2px solid transparent;
  background: $n2;
  @include inter;
  @include base1-s;
  color: $n7;
  transition: all .2s;
  @include dark {
    border-color: $n6;
    background: $n6;
    color: $n1; }
  @include placeholder {
    color: $n4;
    @include dark {
      color: $shades1; } }
  &:focus {
    border-color: $shades1;
    background: $n;
    @include dark {
      border-color: $n5;
      background: $n8; } } }

.hint {
  margin-top: 4px;
  padding: 10px 15px;
  width: fit-content;
  cursor: pointer;
  border-radius: $default-radius;
  //border-bottom: 2px solid $p1
  color: $tag-text-color;

  &.text_dark {
    color: $n7; }
  &.text_light {
    color: $n1; } }

.container {
  flex-wrap: wrap;
  display: flex;
  gap: 8px;
  width: 100%;
  margin-top: 12px; }

.tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 10px;
  border-radius: 10px;
  width: fit-content;
  //background-color: $n3
  //color: $n7
  color: $tag-text-color;

  &.text_dark {
    color: $n7; }
  &.text_light {
    color: $n1; }

  svg {
    cursor: pointer;
    transition: .2s ease-in-out;
    &:hover {
      transform: rotate(90deg);
      path {
        fill: $color-error; } }
    path {
      fill: $tag-text-color;
      transition: .2s ease-in-out; } } }
