@import "../../../styles/helpers";

.details, .location {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  @include m {
    grid-template-columns: 1fr;
    gap: 24px; }
  .col {
    display: flex;
    flex-direction: column;
    gap: 24px; } }

.price {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  @include t {
    grid-template-columns: 1fr; } }
