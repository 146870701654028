@import "styles/helpers";

.title {
  font-size: 20px;
  margin-bottom: 6px;
  padding: 0 5px; }

.tags {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  list-style-type: none;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px 5px;
  /* width */
  &::-webkit-scrollbar {
      width: 8px;
      height: 8px; }
  /* Track */
  &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: $n1;
      @include dark {
          background: $n7; } }
  /* Handle */
  &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: $n3;
      @include dark {
          background: $n8; } } }

.tag {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  white-space: nowrap;
  width: fit-content;
  //height: fit-content
  padding: 10px 12px;
  border-radius: $default-radius;
  color: $n7;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: .2s ease-in-out;
  &.text_dark {
    color: $n7; }
  &.text_light {
    color: $n1; }
  &.selected {
    svg {
      background-color: $p1;
      border-radius: 50%;
      path {
        fill: $n; } }
    //border: 2px dashed $p1
    //padding: 8px 10px
    // border-color: $p1
    // transform: scale(1.1)
    // margin: 0 6px
    // -webkit-box-shadow: 1px 1px 5px 1px $shades1
    // -moz-box-shadow: 1px 1px 5px 1px $shades1
    // box-shadow: 1px 1px 5px 1px $shades1
    @include dark {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; } } }

.search {
  margin: 16px 0 8px; }

.checkbox {
  margin: 16px 0; }
