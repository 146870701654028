@import "../../../styles/helpers";

$color-error-msg: #cf0000;

.head {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 2px solid $n3;
    @include dark {
        border-color: $n6; } }

.info {
    margin-bottom: 20px;
    @include body2-s; }

.btns {
    display: flex;
    margin: 0 -4px;
    .button {
        flex: 0 0 calc(50% - 8px);
        width: calc(50% - 8px);
        margin: 0 4px;
        padding: 0 16px;
        img {
            margin-right: 10px; } } }

.body {
    .button {
        width: 100%; } }

.button {
    margin-top: 24px;
    // display: flex
    // align-items: center
    // gap: 24px
    // transition: opacity .2s ease-in-out
    // &.disabled
    //     opacity: 0.8
    // .loader
    //     display: none
    //     &.visible
 }    //         display: block

.field {
    margin-bottom: 12px; }

.note {
    margin-top: 32px;
    @include body2-s;
    font-size: 12px;
    color: $shades1; }

.error {
    position: relative;
    padding: 12px;
    padding-left: 48px;
    margin-bottom: 12px;
    color: $color-error-msg;
    border: 1px solid $color-error-msg;
    border-radius: 12px;
    width: 100%;

    svg {
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translate(-50%, -50%);
        fill: $color-error-msg; } }

.privacy_link {
    display: inline-block;
    color: $n7;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 300;
    transition: .2s;
    @include dark {
        color: $n1; }
    &:hover {
        color: $p1; } }

.acceptance_links {
    margin-top: 18px;
    font-size: 14px;
    font-weight: 300;
    color: $n8;
    @include dark {
        color: $n; } }

.acceptance {
    * {
        font-size: 12px;
        line-height: 1.3;
        color: $n6;
        @include dark {
            color: $n3; } } }
