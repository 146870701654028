@import "styles/helpers";

.container {
    position: relative;
    display: flex;
    gap: 24px;
    padding-bottom: 24px;
    border-radius: $default-radius; }

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 36px;
    padding: 24px;
    padding-bottom: 128px;
    overflow: hidden;
    border-radius: $default-radius;
    background-color: $n1;
    @include dark {
        background-color: $n7; } }

 :global {
    .mobile {
         :local {
            .container {
                flex-direction: column-reverse;
                margin-bottom: 64px; } } } }

.course_does_not_exist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 16px;
    width: 100%;
    padding: 36px;
    p {
        font-size: 1.6em; }
    .panel {
        button {
            margin-right: 2em; } } }
