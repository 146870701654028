@import "styles/helpers";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  max-width: fit-content;
  padding: 8px 12px 8px 6px;
  border: 2px solid $n3;
  border-radius: $default-radius;
  font-weight: 600;
  color: $n4;
  transition: .2s;
  svg {
    transform: rotate(180deg);
    fill: $n4;
    transition: .2s; }
  @include dark {
    border-color: $n6; }
  &:hover {
    //border-color: $p1
    color: $p1;
    svg {
      fill: $p1;
      margin-left: -2px;
      margin-right: 2px; } } }
