@import "styles/helpers";

.skeleton {
  display: grid;
  gap: 20px;
  grid-template-columns: 4fr 7fr;
  margin: 0 48px 30px;
  // +t
  //   gap: 0
  //   grid-template-columns: 1fr
  //   grid-template-rows: 1fr 1fr
  @include s {
    margin: 0 0 30px; }
  span {
    @include dark {
      --base-color: #313131;
      --highlight-color: #525252; } } }

.content {
  display: flex;
  align-items: center;
  gap: 20px;
  // +t
 }  //   max-width: 400px

.title {
  width: 100%; }

.rest {
  align-self: center; }
