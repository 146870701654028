@import "../../styles/helpers";

$color-error-msg: #cf0000;

.error {
    position: relative;
    padding: 12px;
    padding-left: 48px;
    margin-bottom: 12px;
    color: $color-error-msg;
    border: 1px solid $color-error-msg;
    border-radius: 12px;
    width: 100%;
    svg {
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translate(-50%, -50%);
        fill: $color-error-msg; } }

.row {
    display: flex;
    padding-bottom: 40px;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 340px);
        width: calc(100% - 340px);
        padding-right: 8px;
        @include d {
            flex: 0 0 calc(100% - 296px);
            width: calc(100% - 296px); }
        @include t {
            width: 100%;
            padding: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 340px;
        @include d {
            width: 296px; }
        @include t {
            width: auto; } } }


.course_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
 }    // position: relative


.postcreated {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 100%;
    padding: 5rem;
    p {
        font-size: 1.6em;
        margin-bottom: 2em; }
    .panel {
        button {
            margin-right: 2em; } } }
// -------------------------
.container {
    padding: 1.5rem;
    background-color: $n;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    @include dark {
        background-color: $n7; }
    @include m {
        padding: 1rem; } }

.ce_content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem; }

.sc_title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 16px;
        height: 32px;
        transform: translateY(-50%);
        border-radius: 4px;
        background-color: #9659e1; }
    h4 {
        margin-left: 2rem;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.6;
        letter-spacing: -.02em;
        @include m {
            font-size: 18px; } } }
.btn_ce {
    background-color: $n3;
    color: $n5;
    transition: 250ms;
    padding: 0.7rem 1rem;
    border-radius: 12px;
    @include dark {
        background-color: $n5;
        color: $n4; }
    &:hover {
        background-color: #2A85FF;
        color: $n1; }
    svg {
        @include dark {
            fill: white; } } }
