@import "../../../../styles/helpers";

.card {
  &.head {
    margin-bottom: 8px; } }

.head {
    @include m {
        display: block; } }

.title {
    @include m {
        margin-bottom: 16px; } }

.chart {
    width: auto;
    height: 250px;
    margin-left: -35px;
    margin-bottom: -10px; }

.container {
  display: flex;
  flex-direction: column; }

.content_title {
  font-size: 1.8rem;
  margin-bottom: 12px; }

.subtitle {
  font-size: 1rem;
  margin-bottom: 16px; }
