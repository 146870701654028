@import "styles/helpers";

.card {
  padding: 0px; }

.container {
  width: 100%; }

.list {
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  // overflow-y: auto
  // &::-webkit-scrollbar
  //   width: 8px
  //   height: 8px
  // /* Track */
  // &::-webkit-scrollbar-track
  //   border-radius: 10px
  //   background: $n1
  //   +dark
  //     background: $n7
  // /* Handle */
  // &::-webkit-scrollbar-thumb
  //   border-radius: 10px
  //   background: $n3
  //   +dark
 }  //     background: $n8

.item {
  min-width: 250px;
  width: 250px;
  max-width: 250px;
  padding: 0 !important; }

.info {
  font-size: 14px;
  color: $n4;
  font-style: italic;
  text-align: center;
  margin-bottom: 32px; }

.course_container {
  padding: 12px;
  border: 3px solid $n3;
  border-radius: 12px;
  @include dark {
    border-color: $n5;
    background: $n7; } }
