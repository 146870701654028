@import "styles/helpers";

.bio_section {
    @include element-flex(column, space-between);
    gap: 24px;
    width: 100%; }

.bio {
    @include body1-m;
    color: $n4;
    width: 100%; }


.contact_data {
    @include element-flex(row, space-between);
    gap: 24px;
    flex-wrap: wrap; }

.phone, .email {
    @include element-flex(row, space-between);
    gap: 4px;
    @include body1-m;
    color: $n4;
    a {
        color: $n4;
        &:hover {
            color: $p1; } }
    svg {
        fill: $n4; } }

.info {
    @include info; }
