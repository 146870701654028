@import "styles/helpers";

$title-size: 24px;
$content-size: 14px;
$badge-size: 13px;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  border-radius: $default-radius;
  padding: 25px;
  background-color: $n3 !important;
  @include dark {
    border-color: $n6;
    background-color: $n6 !important; } }

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px; }

.image {
  > div {
    width: 100%; } }

.image_wrapper {
  background-color: $n;
  border: 1px solid $n3;
  min-height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: auto;
  @include dark {
    border: none; }
  img {
    border-radius: 0;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%; } }

.fixed_image_wrapper {
  height: 150px;
  align-items: center; }

.title {
  max-width: fit-content;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  height: 56px;
  //word-break: break-all
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  //margin-top: 8px
  margin-top: 2px;
  color: $n8;
  @include dark {
    color: $n1; } }

.salary {
  display: flex;
  align-items: center;
  gap: 4px;
  text-align: left;
  font-size: $content-size;
  font-weight: 600;
  min-width: fit-content;
  color: $n6;
  @include dark {
    color: $n3; }
  p {
    font-weight: 600;
    color: $n5;
    &::first-letter {
      text-transform: uppercase; }
    @include dark {
      color: $n4; } } }

.date {
  flex-shrink: 0;
  color: $n4;
  font-size: $content-size; }

 :global {

  .mobile {
     :local {
      .image_wrapper {
        aspect-ratio: auto; } } } }
