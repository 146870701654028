@import "../../styles/helpers";

.field {
  position: relative;
  width: 100%;
  height: 48px;
  padding: 0 10px;
  margin-bottom: 12px;
  border-radius: 12px;
  border: 2px solid transparent;
  background: $n2;
  @include inter;
  @include base1-s;
  color: $n7;
  transition: all .2s;
  @include dark {
      border-color: $n6;
      background: $n6;
      color: $n1; }
  @include placeholder {
      color: $n4;
      @include dark {
          color: $shades1; } }
  &:focus-within {
      border-color: $shades1;
      background: $n;
      @include dark {
          border-color: $n5;
          background: $n8; } } }


input {
  position: relative;
  width: 100%;
  height: 44px;
  background: transparent;
  @include inter;
  @include base1-s;
  color: $n7;
  transition: all .2s;
  @include dark {
      color: $n1; }
  @include placeholder {
      color: $n4;
      @include dark {
          color: $shades1; } } }

.label {
  margin-bottom: 14px;
  @include base2;
  color: $n5;
  @include dark {
      color: $n3; } }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }
