@import "styles/helpers";

article {
    width: 100%; }

.item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;
    //margin-bottom: $cards-margin
    border-radius: $default-radius;
    padding: 25px;
    background-color: $n1;
    @include dark {
        border-color: $n6;
        background-color: $n7; } }

.head {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-right: 78px;
    //margin-bottom: 8px
    @include m {
        margin-bottom: 0; } }

.details {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
 }    //overflow: hidden

.avatar {
    width: 40px;
    height: 40px;
    padding: 2px;
    background-color: #fff;
    border-radius: 50%;
    flex-shrink: 0;
    img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.title {
    font-weight: 600;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.actions {
    //position: absolute
    // top: 25px
    // right: 25px
    // display: flex
    // align-items: center
    // margin-left: auto
    // background-color: $n1
    // padding: 0
    // border-radius: $default-radius
    // flex-shrink: 0
    // gap: 8px
    // +dark
 }    //     background-color: $n7

.pin_btn {
  cursor: pointer;
  img {
    height: 20px;
    transition: .2s ease-in-out; }
  &:hover {
    img {
      filter: brightness(80%);
      @include dark {
        filter: brightness(120%); } } }
  // +dark
  //   background: $n6
 }  //   box-shadow: 1px 1px 2px 1px $n6

.content {
    //margin-bottom: 30px
    font-weight: 500;
    color: $shades1;
    //overflow: hidden
    word-wrap: break-word;
    word-break: break-word;
    text-align: justify;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    strong {
        font-weight: 700;
        color: $n7;
        @include dark {
            color: $n1; } } }

.time {
    font-size: 14px;
    color: $n4; }

.actions {
 }    //position: static

.foot {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px; }

 :global {

    .mobile {
         :local {
            .item {
 }                //flex-direction: column

            .head {
                padding-right: 0;
                flex-direction: column;
                align-items: flex-start; }

            .image {
                width: 100%; }

            .title {
                font-size: 20px;
 }                //margin-top: 8px

            .actions {
                top: 0;
                right: 0;
                padding: 10px; }

            .time {
                bottom: auto;
                right: 40px;
                top: 228px; }

            .foot {
                margin-top: 24px; } } } }
