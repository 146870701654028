@import "styles/helpers";

.container {
    display: flex;
    width: 100%;
    height: auto;
    padding: 25px;
    gap: 24px;
    border-radius: $default-radius;
    background-color: $n1;
    @include dark {
      background-color: $n7 {
 } }        //border: 1px solid $n7
    @include m {
      flex-direction: column; }
    span {
        @include dark {
            --base-color: #313131;
            --highlight-color: #525252; } } }

.image {
  width: 200px;
  min-width: 200px;
  aspect-ratio: 5 / 4;
  * {
    width: 100%;
    height: 100%; }
  @include m {
    min-width: 100%;
    width: 100%; } }

.head {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px; }

.long {
  width: 45%;
  float: left; }

.medium {
  width: 30%;
  float: left; }

.short {
  width: 25%;
  float: left; }

.shortest {
  width: 15%;
  float: left; }

.details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px; }

.title {
  width: 100%;
  max-width: 100%; }

.content {
  width: 100%;
  max-width: 50%; }

.foot {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  gap: 24px; }

.reactions {
  width: 100%;
  max-width: 300px; }

.time {
  width: 100%;
  max-width: 150px; }
