@import "../../../styles/helpers";

.notification {
    position: absolute;
    top: 25px;
    right: 0;
    height: 100%;
    @include m {
        position: static; }
    &.active {
        .add {
            display: block; }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }


.body {
    position: absolute;
    right: 7px;
    top: calc(100% - 20px);
    width: 240px;
    padding: 1rem;
    border: 1px solid transparent;
    border-radius: 16px;
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    // new style
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 9999999999999;
    gap: 1rem;
    @include x {
        top: calc(100% - 15px);
        width: 255px; }

    @include m {
        top: 100%;
        right: 16px;
        left: 16px;
        width: auto;
        padding: 12px 16px 20px; }
    @include dark {
        background: $n8;
        border-color: $n6; } }


.button {
    width: 100%;
    padding: 5px 0;
    height: fit-content; }

.head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    border-radius: 12px;
    white-space: nowrap;
    @include base1-s;
    color: $n4;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: background .2s, box-shadow .2s, color .2s;
    @include d {
        font-size: 0; }
    @include m {
        font-size: 15px; }
    &:hover {
        color: $n7;
        svg {
            fill: $n7; }
        @include dark {
            color: $n1;
            svg {
                fill: $n1; } } }
    &.wide {
        .add {
            display: flex; } }
    &.active:not(.wide) {
        @include d {
            background: $n3;
            box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF;
            color: $n7; }
        @include m {
            background: none;
            box-shadow: none;
            color: $n4; }
        @include dark {
            @include d {
                background: $n6;
                box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11);
                color: $n1;
                svg {
                    fill: $n1; } }
            @include m {
                background: none;
                box-shadow: none;
                color: $n4;
                svg {
                    fill: $n4; } } } }
    svg {
        flex-shrink: 0;
        fill: $n4;
        transition: transform .2s, fill .2s;
        &:first-child {
            margin-right: 12px;
            @include d {
                margin-right: 0; }
            @include m {
                margin-right: 12px; } }
        &:last-child {
            margin-left: auto;
            @include d {
                display: none; }
            @include m {
                display: inline-block; } } } }


.add {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 48px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $n3;
    transform: translateY(-50%);
    font-size: 0;
    transition: border-color .2s;
    display: none;
    @include d {
        display: none; }
    @include m {
        display: flex;
        top: 25px; }
    @include dark {
        border-color: $n5; }
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        border-color: $n7;
        svg {
            fill: $n7; }
        @include dark {
            border-color: $shades1;
            svg {
                fill: $shades1; } } }
    &.active {
        border-color: $shades1;
        svg {
            fill: $shades1; } } }


.counter {
    flex-shrink: 0;
    min-width: 24px;
    margin-left: auto;
    border-radius: 6px;
    text-align: center;
    line-height: 24px;
    color: $n7; }

.groupButtons {
    display: flex;
    width: 100%;
    gap: 0.5rem; }

.cancel {
    background-color: $n3;
    color: $n5;
    transition: 250ms;
    &:hover {
        background-color: #ff4d4d;
        color: $n1; }
    @include dark {
        background-color: $n5;
        color: $n4;
        &:hover {
            background-color: #ff4d4d;
            color: $n1; } } }

.validationCounter {
    font-size: 0.7rem;
    position: absolute;
    top: 30px;
    right: 1.5rem;
    @include m {
        top: 25px; } }


.input {
    padding-right: 2.8rem;
    padding-left: 0.5rem; }

.invalid {
    border: 2px red solid;
    border-radius: 10px; }
