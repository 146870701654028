@import "../../styles/helpers";

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    gap: 12px;
    &:first-child {
        flex: 0 0 calc(100% - 340px);
        width: calc(100% - 340px);
        padding-right: 12px;
        @include d {
            flex: 100%;
            width: 100%; }

        @include t {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; } } }

.column {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &:nth-child(2) {
        flex-shrink: 0;
        width: 340px;
        @include d {
            display: none; } } }

.content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px; }

.children_button {
    @include dark {
        color: $n;
        background: $n5; }
    display: flex;
    flex-direction: column;
    border: 2px solid $n4;
    border-radius: 12px;
    padding: 10px;
    width: 96%;
    margin: 16px;
    height: 100%; }

.children_button_hover {
    @include dark {
        color: $n;
        background: $n5; }
    display: flex;
    flex-direction: column;
    border: 2px solid $n4;
    border-radius: 12px;
    padding: 10px;
    width: 96%;
    margin: 16px;
    height: 100%;
    &:hover {
        text-decoration: none;
        color: #009bee; } }

.items_list {
    display: grid; }
@include uw {
    grid-template-columns: 1fr 1fr;
    gap: 12px; }

.subhead {
    color: $n8;
    // margin-bottom: 12px
    padding: 28px;
    border-radius: 12px;
    background-color: $n;
    font-size: 20px;
    position: relative;
    @include dark {
        background-color: $n7;
        color: $n; }
    h4 {
        margin-left: 2rem;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.6;
        letter-spacing: -.02em; } }

.jzwit_more_options {
    display: flex;
    justify-content: center;
    align-items: center; }

.jzwitlist {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: 12px;
    background-color: $n;
    padding: 24px;
    border-radius: 12px;
    @include dark {
        background-color: $n7; } }

.isEmpty {
    font-size: 1rem;
    text-align: center;
    padding: 1rem;
    border-radius: 12px;
    background-color: $n;
    @include dark {
        background-color: $n6; }
    @include m {
        font-size: 12px; } }
.sc_title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h4 {
        margin-left: 2rem;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.6;
        letter-spacing: -.02em;
        @include m {
            font-size: 16px; } } }

.items_list {
    display: grid; }
@include uw {
    grid-template-columns: 1fr 1fr;
    gap: 12px; }

.roadmap_container {
    padding: 24px; }

.subhead {
    color: $n8;
    // margin-bottom: 12px
    border-radius: 12px;
    background-color: $n;
    font-size: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include dark {
        background-color: $n7;
        color: $n; } }

.tech_tags_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    width: 100%;
    gap: 1rem;
    align-content: space-around;
    border-radius: 12px;
    padding: 24px;
    background-color: $n;
    @include dark {
        background-color: $n7; } }

.tech_tag {
    // background: rgb(121,99,255);
    // background: linear-gradient(47deg, rgba(121,99,255,1) 0%, rgba(255,59,59,1) 100%);
    background-color: $n3;
    padding: 5px 20px;
    color: $n8;
    font-size: 1rem;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    transition: 250ms;
    &:hover {
        transform: scale(1.05); }

    img {
        color: $n;
        width: 25px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        object-fit: cover; }
    @include m {
        font-size: 0.9rem;
        // img
 } }        //     width: 20px

.mainSection,.chartAndTrends,.dynamicSection,.dynamicSectionSide {
    border-radius: 12px;
    padding: 24px;
    background-color: $n;
    // display: grid
    // // grid-template-columns: 65% 35%;
    margin-top: 12px;
    align-items: center;
    // gap: 2rem
    .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        h1 {
            font-size: 1.8rem; }
        h3 {
            font-size: 1rem;
            font-weight: normal; }
        p {
            font-weight: normal;
            margin-top: 24px;
            color: $n4;
            margin-right: 2rem;
            @include m {
                margin: 1rem 0; } } }
    img {
        width: 100%;
        min-width: 200px;
        border-radius: 12px; }
    @include m {
        grid-template-columns: 1fr;
        img {
            max-width: 400px;
            margin: auto; } }
    @include dark {
        background-color: $n7;
        color: $n3; } }

.mainSection {
    display: grid;
    grid-template-columns: 65% 35%;
    @include t {
        grid-template-columns: 1fr;
        .mainSectionText {
            margin-bottom: 2rem; } } }

.dynamicSection,.dynamicSectionSide {
    display: flex;
    gap: 2rem;
    img {
        width: 100%;
        min-width: 300px; }
    .dynamicSectionText {
        p {
            margin-right: 0; } }
    @include t {
        flex-direction: column; } }

.dynamicSectionSide {
    flex-direction: row-reverse;
    @include t {
        flex-direction: column-reverse; } }

.chartAndTrends {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .content {
        margin-top: 2rem;
        padding: 0;
        p {
            margin: 0; } } }

.chart {
    padding: 0;
    width: 100%; }

.isEmpty {
    font-size: 1rem;
    width: 100%;
    text-align: center;
    padding: 1rem;
    border-radius: 12px;
    background-color: $n2;
    @include dark {
        background-color: $n6; }
    @include m {
        font-size: 12px; } }

.nextSteps {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;

    .emptyNextSteps {
        grid-column: 1 / -1;
        margin-bottom: 12px;
        @include body1-s;
        color: $n4;
        text-align: center; } }

.related_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;
    border-radius: $default-radius; }

.related_card {
    padding: 24px; }

.related_item {}
