@import "styles/helpers";

.title {
  margin-bottom: 24px; }

.form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 5%; }

.field {
    margin-bottom: 20px;
    flex: 1 1 40%;
    min-width: 250px;
    &:last-of-type {
        flex: 1 1 100%; } }

.disabled,
.disabled:hover,
.disabled:active, {
    cursor: default;
    background: $n3;
    color: $n4;
    opacity: .7;
    border: none;
    box-shadow: none !important;
    @include dark {
        opacity: .5;
        color: inherit;
        background: $n5; } }

.button {
    // flex: 0 0 max-contetn
    width: max-content; }

.checkbox {
    margin-bottom: 20px; }

.bottom {
    // background: rgba(0, 0, 0, .2) //temp
    margin-top: 30px;
    width: 100%;
    display: flex;
    gap: 40px;
    justify-content: space-between;
    // +t
 }    //     flex-direction: column

.left {
    display: flex; }

a {
    color: $p1; }

.policy {
    font-size: 14px;
    margin-left: 20px; }

.right {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: flex-end;
    // +t
    //     flex-direction: row
    //     justify-content: space-between
    //     flex-wrap: wrap
    //     & button
 }    //         align-self: center

.contactInfo {
    color: $n4;
    margin-top: 40px;
    margin-bottom: 10px;
    width: 100%;
    @include dark {
        color: $shades1; } }

.googlePolicy {
    color: $n4;
    width: 100%;
    font-size: 10px; }

.error {
    color: $p3; }

.container {
    display: flex;
    flex-direction: column;
    gap: 24px; }

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px; }

 :global {
    .r-medium {
         :local {
            .bottom {
                flex-direction: column; }
            .right {
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                & button {
                    align-self: center; } } } } }
