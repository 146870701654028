@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px; }

.content {
  display: flex;
  flex-direction: column;
  gap: 12px; }
