@import "styles/helpers";

$color-delete: #cf0000;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $title-content-margin; }

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $title-content-margin; }

.cv_form_modal_outer {
  max-width: 700px; }

.overlay {
  z-index: 4;
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  border-radius: $default-radius;
  background-color: #eeeeeecc;
  @include dark {
    background-color: #151515cc; } }

.form_section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px 24px; }

.field {
  //grid-column-start: 1
 }  //grid-column-end: 3

.button {
  width: 100%;
  max-width: 200px;
  margin: 0 auto; }

.section_title {
  color: $p1;
  //font-weight: 400
  font-size: 15px; }

.form_section {
  //padding: 24px 0
 }  //border-top: 1px solid $p1

.textarea {
  height: fit-content; }

.attachments, .acceptance {
  grid-column-start: 1;
  grid-column-end: 3; }

.attachments {
  margin-top: 12px; }

.attachment {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  padding: 8px 14px;
  border-radius: $default-radius;
  background-color: $n3;
  @include dark {
    background-color: $n6; } }

.attachment_size {
  font-size: 12px;
  font-weight: 400;
  color: $n4; }

.attachment_remove {
  path {
    fill: $n4;
    transition: .2s ease-in-out; }
  &:hover {
    path {
      fill: $color-delete; } } }

.checkbox {
  * {
    font-size: 14px; } }

.error {
  color: $p3; }

.label {
  margin-bottom: 14px;
  @include base2;
  color: $n5;
  @include dark {
    color: $n3; } }

 :global {
  .mobile {
     :local {

      .form_section {
        display: flex;
        flex-direction: column;
        gap: 24px; } } } }
