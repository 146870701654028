@import "../../styles/helpers";

.item {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    width: 280px;
    background: $n;
    padding: 12px;
    border: 3px solid $n3;
    @include dark {
        border-color: $n5;
        background: $n7; } }

.head {
    margin-top: 12px;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px; }

.categories {
    margin-top: 8px; }

.postimage {
    flex-shrink: 0;
    height: auto;
    width: 250px !important;
    margin: 0;
    align-self: center;
    overflow: hidden;
    height: 200px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
        @include m {
            max-height: 190px; } } }

.linkcont {
    display: none; }

.time {
    position: absolute;
    bottom: 25px;
    left: 0;
    font-size: 13px;
    color: $shades1; }

.content {
    margin-bottom: 40px;
    font-weight: 500;
    color: $shades1;
    overflow: hidden;
    word-break: break-word;
    text-align: justify;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-size: 14px;
    strong {
        font-weight: 700;
        color: $n7;
        @include dark {
            color: $n1; } } }

.categories_styles {
    width: 250px !important;
    margin: 100px; }

.details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    gap: 0; }

.categorieslist {
    display: none; }

.title {
    font-weight: 700;
    font-size: 18px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.actions {
    display: flex;
    flex-shrink: 0;
    gap: 8px; }
.pin_btn {
    cursor: pointer;
    img {
        height: 20px;
        transition: .2s ease-in-out; }
    &:hover {}
    img {
        filter: brightness(80%);
        @include dark;
        filter: brightness(120%); } }
.actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    border-radius: $default-radius;
    background-color: $n1;
    @include dark {
        background-color: $n7; } }

// .actions
//     display: none


// .addfavouritebtn
//     position: absolute
//     bottom: 1rem
//     right: 30px
//     border-radius: 2px 2px 8px 8px
//     padding: 4px 2px 0
//     z-index: 2
//     img
//         height: 30px
//         width: 30px
// .actions
//     display: flex
//     flex-shrink: 0
//     gap: 8px
//     .pin_btn
//         cursor: pointer
//         img
//             height: 20px
//             transition: .2s ease-in-out
//         &:hover
//             img
//                 filter: brightness(80%)
//                 +dark
//                 filter: brightness(120%)
// .actions
//     position: absolute
//     top: -30px
//     right: 0
//     padding: 10px
//     border-radius: $default-radius
//     background-color: $n1
//     z-index: 99999999999 !important
//     +dark
//         background-color: $n7

// .actions
//   z-index: 2
//   position: absolute
//   bottom: 0
//   right: 0
//   display: flex
//   align-items: center
//   padding: 0 0 5px 10px
//   border-radius: 0 0 0 10px
//   flex-shrink: 0
//   gap: 8px
//   padding: 0

// .relatedOptions
//     display: none

// .actionsBody
//     position: absolute
//     top: -135px
//     right: -12px
//     width: 264px
//     padding: 12px
//     border-radius: 16px
//     border: 1px solid transparent
//     box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
//     background: $n
//     visibility: hidden
//     opacity: 0
//     transform: translateY(3px)
//     transition: all .2s
//     +dark
//         background: $n8
//         border-color: $n6
//     &:before
//         content: ""
//         position: absolute
//         right: 17px
//         top: 100%
//         width: 20px
//         height: 10px
//         transform: rotate(180deg)
//         background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
//         +dark
//             background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23272B30'/%3E%3C/svg%3E")
