@import "styles/helpers";

.container {
    //margin-bottom: 16px
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center; }

.level {
    display: flex;
    gap: 6px;
    align-items: baseline; }

.divider {
    color: $n4;
    font-size: 12px; }

.currentlvl {
    font-size: 15px; }

.xpPoints {
    font-size: 15px;
    color: $n4; }

.badge {
    display: flex;
    align-items: center;
    gap: 6px;
    img {
        height: 28px;
        border-radius: 10px; } }
