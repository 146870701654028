@import "styles/helpers";

.card {
    padding: 24px; }


.header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    &.hasIcon {
        gap: 8px; }
    img {
        width: 32px;
        max-height: 32px;
        object-fit: contain; }
    .title {
        @include title1-s; } }


.container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    &.hasImage {
        grid-template-columns: 1fr 33%; }
    @include m {
        grid-template-columns: 1fr;
        &.hasImage {
            grid-template-columns: 1fr; } } }

.content {}

.image_container {
    width: 100%;
    img {
        border-radius: 4px;
        width: 100%;
        flex-shrink: 0; }
    @include m {
        display: none; } }
