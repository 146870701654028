@import "styles/helpers";

.modal {
  max-width: 700px; }

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px; }

.details {
  display: flex;
  align-items: center;
  gap: 16px;

  .image {
    width: 80px;
    height: 80px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%; } } }

.user_info {
  display: flex;
  flex-direction: column;
  //gap: 4px

  .username {
    font-weight: 600;
    font-size: 18px;
    color: $n1; }

  .time {
    font-weight: 400;
    font-size: 14px;
    color: $n4; } }


.informations_title {
  margin-bottom: 12px; }

.list_item {
  display: flex;
  align-items: center;
  gap: 8px;
  b {
    color: $n4; }
  svg {
    path {
      fill: $n4; } } }


.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  button {
    flex-shrink: 0; } }
