@import "../../../styles/helpers";

.filter {
  width: fit-content;
  justify-self: end;
  .filter_body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-transform: capitalize; } }
