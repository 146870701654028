@import "styles/helpers";

.container {
  min-height: 400px; }

.checkbox {
  margin-bottom: 24px; }

.tags_list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 24px;
  max-height: 200px;
  overflow-y: hidden;
  //overflow-y: scroll
  pointer-events: none;
  overflow-x: hidden;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid $n6;
  &::-webkit-scrollbar {
    width: 8px; }
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $n1;
    @include dark {
      background: $n7; } }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $n3;
    @include dark {
      background: $n8; } }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: .8;
    background-color: $n1;
    @include dark {
      background-color: $n7; } } }

.item {
  cursor: pointer;
  flex-shrink: 0;
  white-space: nowrap;
  width: fit-content;
  height: fit-content;
  border: 1px solid $n4;
  padding: 8px;
  border-radius: $default-radius;
  //background-color: $n1
  color: $n7;
  text-transform: uppercase;
  transition: .2s ease-in-out;
  @include dark {
    //background-color: $n7
 }    //color: $n1
  &.selected {
    border-color: $p1;
    transform: scale(1.05); } }

.button {
  transition: color .2s ease-in-out;
  color: $n8;
  @include dark {
    color: $n1; }
  &:hover {
    color: $p1; } }
