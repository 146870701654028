@import "../../../styles/helpers";
.SubsCarusel {
  display: none;
  margin-bottom: 1rem; }


@media only screen and (max-width: "1259px") {
        .SubsCarusel {
          width: 100%;
          height: fit-content;
          display: flex;
          align-items: left;
          //border-bottom: 1px solid $n4
          flex-direction: row;
          gap: 0.3rem;
          padding: 0.5rem;
          border-radius: 12px;
          overflow-y: scroll;
          overflow-x: visible;
          margin-bottom: auto;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: none;
          scrollbar-width: none;
          background-color: $n;
          margin-bottom: 1rem;
          @include dark {
              background-color: $n7; }
          &::-webkit-scrollbar {
              display: none; }

          p {
            color: $n4;
            align-self: center; }

          li {
            display: flex;
            border-radius: 1rem;
            width: 90px;
            height: 100px;
            &:hover {
                background-color: $n2;
                .userName {
                    color: $n5; } }

            @include dark {
                  &:hover {
                      background-color: $n6;
                      .userName {
                          color: $n; } } }

            a {
              color: $n4;
              width: 90px;
              padding: 0.8rem;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column; }
            .userName {
                width: 100%;
                height: 24px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center; }




            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-bottom: 5px; } } } }

