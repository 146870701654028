@import "../../../styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: 48px; }

.company_info {
  display: flex;
  //align-items: center
  gap: 20px;
  @include m {
    flex-direction: column; }
  .logo {
    position: relative;
    flex-shrink: 0;
    width: 120px;
    height: 120px;
    margin-right: 16px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%; } }
  .data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    color: $n7;
    @include dark {
      color: $n3; }
    .name {
      font-size: 28px;
      font-weight: 600; }
    .tax_number {
      color: $n4;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: .1px;
      b {
        font-size: 600; } }
    .contact {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      color: $n4;
      svg {
        path {
          fill: $n4; } }
      span {
        display: flex;
        align-items: center;
        gap: 4px; } } } }

.about_us {
  .content {
    font-size: 15px;
    margin-bottom: 0;
    color: $n6;
    @include dark {
      color: $n3; } } }

.subtitle {
  margin-bottom: 24px; }

.locations {
  ul {
    li {
      padding: 2px 0; } } }

.info {
  width: 100%;
  padding: 30px 0;
  text-align: center;
  color: $n4;
  font-weight: lighter;
  font-style: italic; }

.image_wrapper {
  background-color: $n1;
  padding: 24px;
  aspect-ratio: auto;
  height: auto;
  img {
    border-radius: 0; } }
