@import "styles/helpers";

.menu {
  cursor: pointer;
  svg {
    //transform: rotate(90deg)
    path {
      fill: $n4; } } }


.settings {
  border-radius: 10px;
  padding: 1px 3px;
  width: auto;
  height: auto;
  background-color: $n1;
  @include dark {
    background-color: $n7; }
  &:hover {
    background-color: $n3;
    @include dark {
      background-color: $n6; } } }

.settings_button {
  height: 48px;
  padding: 0 20px;
  background: #2a85ff;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  color: white;
  &:hover {
    background: #2a3fff; } }
