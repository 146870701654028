@import "styles/helpers";

.modal {
  width: 100%;
  max-width: 1200px; }

.container {
  width: 100%;
  max-width: 1200px;
  height: 80vh;
  max-height: 800px; }

.map {
  width: 100%;
  height: 100%;
  border-radius: 16px; }

.active_marker {
  transform: scale(2); }

.marker_icon {
  border-radius: 50%; }

.popup_item {
  display: flex;
  gap: 12px;
  color: $n8;
  background-color: $n1;
  padding: 12px;
  transition: background-color .2s ease-in-out;
  &:hover {
    background-color: $n2; }
  &:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  &:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; } }

.image {
  flex-shrink: 0;
  width: 50px;
  img {
    object-fit: contain;
    width: 100%; } }

.details {
  flex-shrink: 0; }

.popup_title {
  color: $n8;
  font-weight: 600; }

.popup_company {
  color: $n8; }

.popup_salary {
  color: $p2; }

.popup_link {}
