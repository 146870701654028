@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px; }

.delete_btn {
  background-color: transparent;
  border: 2px solid #ff0000;
  color: $n8;
  @include dark {
    color: $n1; }
  &:hover {
    background-color: #ff0000;
    color: $n1; } }

.color_box {
  width: 20px;
  height: 14px;
  border-radius: 5px; }

.list_item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  b {
    color: $n4; }
  svg {
    path {
      fill: $n4; } } }

.edit_button {
  width: fit-content;
  @include m {
    width: 100%; } }

.buttons {
  display: flex;
  gap: 12px;
  @include m {
    flex-direction: column; } }
