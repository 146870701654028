@import "styles/helpers";

.title {
    margin-bottom: 24px; }

.description {
    color: $shades2;
    margin-top: -12px; }

.fields {
    display: flex;
    flex-direction: column;
    gap: 12px; }

.field {
    position: relative;
    .input_error {
        box-shadow: inset 0 0 0 2px #ff0000;
        @include dark {
            box-shadow: inset 0 0 0 2px #ff0000; } } }


.counter {
    position: absolute;
    font-size: 12px;
    bottom: 10px;
    right: 10px;
    color: $n4;
    &.error {
        color: #ff0000; } }

.textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    border-radius: 12px;
    border: 2px solid transparent;
    background: $n2;
    @include inter;
    @include base1-s;
    font-size: 14px;
    color: $n7;
    resize: none;
    @include dark {
        border-color: $n6;
        background: $n6;
        color: $n1; }
    @include placeholder {
        color: $n4;
        @include dark {
            color: $shades1; } }
    &:focus {
        border-color: $shades1;
        background: $n;
        @include dark {
            border-color: $n5;
            background: $n8; } }
    &.error {
        border-color: #ff0000;
        @include dark {
            border-color: #ff0000; } } }
