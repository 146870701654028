@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px; }

.item {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  font-weight: 400;
  padding: 4px 0;
  path {
    fill: $p1; } }
