@import "styles/helpers";

.overall {
    padding-bottom: 8px;
    border-bottom: 1px solid $n3;
    @include dark {
        border-color: $n5; } }

.earnings {
    display: flex;
    margin-bottom: 4px; }

.categories {
    display: flex;
    flex-wrap: wrap;
    border-radius: 2px;
    width: 100%; }

.category {
    flex-shrink: 0;
    white-space: nowrap;
    width: fit-content;
    height: fit-content;
    padding: 5px 6px;
    border-radius: 6px;
    color: $n7;
    font-size: 13px;
    text-transform: uppercase;
    transition: .2s ease-in-out; }

.earnings_font {
    display: flex;
    @include body2-m; }

.brutto {
    color: $n4; }

.seniorities {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid $n3;
    @include dark {
        border-color: $n5; } }

.seniorities_font {
    @include body2-m; }

.seniorities_earnings_font {
    text-align: right;
    @include body2-m;
    color: $n5;
    @include dark {
        color: $n4; } }
