@import "styles/helpers";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px; }

.pin_button {
  padding: 12px;
  border-radius: 10px;
  transition: background-color .2s ease-in-out;
  border: 1px solid $n3;
  svg {
    path {
      fill: $p3; } }
  &:hover {
    background-color: $n3;
    border: 1px solid $n3; }
  @include dark {
    color: $n1;
    border: 2px solid $n6;
    &:hover {
      background-color: $n6; } } }
