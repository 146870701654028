@import "../../../styles/helpers";

.head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding-bottom: 24px;
    border-bottom: 1px solid $n3;
    @include caption1;
    color: $n4;
    @include dark {
        border-color: $n6; } }

.list {
    margin: 0 -12px; }

.item {
    display: flex;
    align-items: center;
    padding: 12px;
    color: $n7;
    cursor: pointer;
    @include dark {
        color: $n1; }
    &:hover {
        .title {
            color: $p1; } }
    &:not(:last-child) {
        margin-bottom: 12px; } }

.preview {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    margin-right: 12px;
    border-radius: 8px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.title {
    flex-grow: 1;
    padding-right: 16px;
    transition: color .2s;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; }

.status {
    text-transform: uppercase; }

.details {
    flex-shrink: 0;
    text-align: right; }

.button {
    width: 100%;
    margin-top: 12px; }
