@import "../../styles/helpers";

$color-error-msg: #cf0000;

.card_links {
    margin-top: 12px; }

.error {
    position: relative;
    padding: 12px;
    padding-left: 48px;
    margin-bottom: 12px;
    color: $color-error-msg;
    border: 1px solid $color-error-msg;
    border-radius: 12px;
    width: 100%;
    svg {
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translate(-50%, -50%);
        fill: $color-error-msg; } }

.row {
    display: flex;
    padding-bottom: 40px;
    @include d {
        display: block; } }

.col {
    width: 100%;
    // &:first-child
    //     flex: 0 0 calc(100% - 340px)
    //     width: calc(100% - 340px)
    //     padding-right: 12px
    //     +d
    //         // flex: 0 0 calc(100% - 296px)
    //         // width: calc(100% - 296px)
    //     +d
    //         width: 100%
    //         padding: 0
    // &:nth-child(2)
    //     flex-shrink: 0
    //     width: 340px
    //     +d
    //         //width: 296px
    //     +d
 }    //         width: auto


.course_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px; }


.postcreated {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 100%;
    padding: 5rem;
    p {
        font-size: 1.6em;
        margin-bottom: 2em; }
    .panel {
        button {
            margin-right: 2em; } } }
