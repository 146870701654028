@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  //border-right: 1px solid $n6
  padding-right: 12px; }

.item {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
  padding: 24px 0;
  &:first-child {
    padding-top: 0; }
  &:not(:last-child) {
    border-bottom: 1px solid $shades3; } }

.head {
  display: flex;
  justify-content: space-between;
  gap: 24px; }

.author {
  color: $n4; }

.time {
  color: $n4; }

.content {
  display: flex;
  flex-direction: column;
  gap: 8px; }

.title {
  display: flex;
  align-items: center;
  gap: 12px;
  @include title2;
  // color: $n8
  // font-size: 20px
  // font-weight: 700
  @include dark {
    color: $n1; } }

.description {
  @include body1-m; }
