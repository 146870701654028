@import "styles/helpers";

.item {
  padding: 10px 0; }

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: white;
  padding: 36px;
  border-radius: 12px;
  @include dark {
    background: $n7; } }

.info {
  width: 100%;
  padding: 64px 0;
  @include info;
  text-align: center; }
