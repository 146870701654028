@import "styles/helpers";

.container {
  @include element-flex(column, space-between);
  gap: 12px;
  width: 100%;
  margin-bottom: 32px; }

.header {
  @include element-flex(row, space-between);
  gap: 8px;
  width: 100%; }

.header_button {
  height: 32px;
  width: 32px;
  border-radius: 12px; }

.field {
  width: 100%; }

.input_container {
  width: 100%;
  @include element-flex(row, space-between);
  gap: 8px; }

.addButton {
  width: 75px;
  border-radius: 12px; }

.remove_button {
  background: $n3;
  &:hover {
    background: red; }
  @include dark {
    background: $n5;
    &:hover {
      background: red; } }
  svg {
    fill: black;
    @include dark {
      fill: white; } } }

.input {
  height: 48px;
  padding: 0 10px;
  border-radius: 12px;
  border: 2px solid transparent;
  background: $n2;
  @include inter;
  @include base1-s;
  color: $n7;
  transition: all .2s;
  @include dark {
    border-color: $n6;
    background: $n6;
    color: $n1; }
  @include placeholder {
    color: $n4;
    @include dark {
      color: $shades1; } }
  &:focus {
    border-color: $shades1;
    background: $n;
    @include dark {
      border-color: $n5;
      background: $n8; } } }

.input_title {
  width: 100%;
  min-width: 100px; }

.input_level {
  max-width: 250px;
  min-width: 100px; }

.dropdown {
  width: 100%; }

.dropdownHead {
  width: 250px;
  @include s {
    width: 128px; }
  background: $n2;
  @include dark {
    background: $n6 !important;
    box-shadow: none; } }
