@import "../../styles/helpers";

.container {
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;
  gap: 2px;

  .paginationItem {
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 10px;
    color: $n7;
    transition: background-color 0.2s linear;
    cursor: pointer;
    @include dark {
      color: $n2; }

    &:hover {
      background-color: $n1;
      @include dark {
        background-color: $n7; } }

    &.selected {
      background-color: $n1;
      @include dark {
        background-color: $n7; } }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
      @include dark {
        background-color: transparent; } }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.6em;
        height: 0.6em;
        border-right: 0.16em solid;
        border-top: 0.16em solid;
        border-color: $n1; }

      &.left {
        transform: rotate(-135deg); }

      &.right {
        transform: rotate(45deg); } }

    &.disabled {
      display: none; } } }

