@import "styles/helpers";

.item {
  border-radius: 0;
  margin-bottom: 0;
  background: white; }

.info {
  width: 100%;
  padding: 64px 0;
  @include info;
  text-align: center; }
