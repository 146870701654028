@import "styles/helpers";

.content {
  white-space: pre-wrap;

  p {
    min-height: 15px;
    margin: 10px 0;
    &:first-of-type {}
    margin-top: 0;
    &:last-of-type {}
    margin-bottom: 0; }

  ul {
    margin: 10px 17px;
    list-style: disc; } }
