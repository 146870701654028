@import "styles/helpers";

.items_list {
  display: flex;
  flex-direction: column; }

.item {
  //padding: 24px 0
  border-radius: 0;
  border-bottom: 1px solid $n4; }

.info {
  width: 100%;
  padding: 30px 0;
  text-align: center;
  color: $n4;
  font-weight: lighter;
  font-style: italic; }
