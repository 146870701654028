@import "styles/helpers";

.item {
  // display: flex
  // align-items: center
  // justify-content: space-between
  padding: 18px 0;
  // display: flex
  //flex-direction: column
  align-items: center;
  border-bottom: 1px solid $n3;
  display: grid;
  // grid-template-columns: minmax(300px, 400px) minmax(300px 400px) min-content
  grid-template-columns: 350px 1fr 40px;
  gap: 16px;
  background-color: $n1;
  color: $n8;
  @include dark {
    background-color: $n7;
    color: $n1;
    border-color: $n6; } }

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px; }


.head_content {
  display: flex;
  gap: 16px;
  align-items: center; }

.head_actions {
  display: none; }

.actions {
  display: block; }

.data {
  display: grid;
  align-items: center;
  grid-template-columns: 150px 150px 300px;
  gap: 16px; }

.image {
  width: 30px;
  height: 30px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%; } }

.user_data {}

.col {
  display: flex;
  align-items: center;
  gap: 6px;
  color: $n8;
  @include dark {
    color: $n1; }
  label {
    display: none;
    color: $n4;
    path {
      fill: $n4; } }
 }  //text-align: center

.content_counts {
  display: flex;
  gap: 16px;
  align-items: center;
  label {
    display: none; } }

.col_item {
  display: flex;
  align-items: center;
  gap: 6px;
  label {
    display: block;
    color: $n4;
    path {
      fill: $n4; } } }


.name {
  //text-transform: uppercase
  text-decoration: none;
  min-width: fit-content;
  overflow: hidden;
  word-break: break-word;
  text-align: justify;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: $n8;
  @include dark {
    color: $n1; } }

.menu {
  cursor: pointer;
  svg {
    path {
      fill: $n4; } } }

.color {
  width: 40px;
  height: 20px;
  border-radius: $default-radius / 2;
  border: 1px solid $shades1; }

.settings {
  border-radius: 10px;
  background-color: $n1;
  @include dark {
    background-color: $n7; }
  &:hover {
    background-color: $n3;
    @include dark {
      background-color: $n6; } } }


 :global {
  // grid-template-columns: min-content 30px 256px 110px 110px max-content
  .r-lg { //+r(1550)
     :local {
      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }

      .content_counts {
        label {
          display: block; } }

      .col {
        label {
          display: block; } }

      .head {
        width: 100%; }

      .head_actions {
        display: block; }

      .actions {
        display: none; }

      .data {
        display: flex;
        align-items: center;
        gap: 16px; } } }

  .r-md { //+w
     :local {
      .data {
        flex-direction: column;
        align-items: flex-start; } } }



  .r-sm { //+t
    //display: flex
    //flex-direction: column
     :local {
      .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px; }

      .col {
        label {
          display: block; } } } } }

