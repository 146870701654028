@import "styles/helpers";

.overlayWrapper {
  position: relative;

  .overlay {
    position: absolute;
    inset: 0;
    z-index: 1;
    @include element-flex(column, center, center);
    border-radius: $default-radius;
    background-color: $n1;
    transition: 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    @include dark {
      background-color: $n7; }

    &.visible {
      opacity: 0.8;
      visibility: visible;
      pointer-events: all; } } }
