@import "styles/helpers";

.tag {
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  flex-shrink: 0;
  white-space: nowrap;
  width: fit-content;
  height: fit-content;
  padding: 10px 12px;
  border-radius: $default-radius;
  color: $n7;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: .2s ease-in-out;
  &.text_dark {
    color: $n7; }
  &.text_light {
    color: $n1; }
  &.selected {
    border-color: $p1;
    svg {
      background-color: $p1;
      border-radius: 50%;
      path {
        fill: $n; } }
    @include dark {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; } } }


.item_tag {
  flex-shrink: 0;
  word-break: break-word;
  width: fit-content;
  height: fit-content;
  cursor: default;
  padding: 8px;
  border-radius: 6px;
  font-size: 14px;
  transition: .2s ease-in-out;
  color: $n4;
  background-color: $n3;
  @include dark {
    color: rgb(200,200,200);
    background-color: $n6; } }
