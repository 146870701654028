@import "../../../styles/helpers";

.modal {
    max-width: 300px; }

.notification {
    position: relative;
    @include m {
        position: static; }
    // &.active
    //     .body
    //         visibility: visible
    //         opacity: 1
 }    //         transform: translateY(0)


// .body
//     position: absolute
//     top: calc(100% + 18px)
//     right: -80px
//     width: 200px
//     padding: 1rem
//     border: 1px solid transparent
//     border-radius: 10px
//     box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
//     background: $n
//     visibility: hidden
//     opacity: 0
//     transform: translateY(3px)
//     transition: all .2s
//     // new style
//     display: flex
//     justify-content: center
//     flex-direction: column
//     gap: 1rem
//     +x
//         top: calc(100% + 15px)
//     +m
//         top: -270px
//         right: 0
//         left: -300%
//         width: auto
//         padding: 12px 16px 20px
//     +dark
//         background: $n8
//         border-color: $n6
//     &:before
//         content: ""
//         position: absolute
//         right: 90px
//         bottom: 100%
//         width: 20px
//         height: 10px
//         background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto

//         +m
//             display: none

//         +dark
//             background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E")



.button {
    width: 100%;
    padding: 5px 0;
    height: fit-content;
    border-radius: $default-radius;
    @include m {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 10px;
        right: 10px; } }


.addList {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    margin-top: 1rem;
    .element {
        display: flex;
        //justify-content: space-between
        align-items: center;
        gap: 12px;
        //padding: 0.5rem 1rem
        padding: 6px 0;
        background-color: $n1;
        border-radius: 12px;
        color: $n8;
        transition: .2s ease-in-out;
        &:hover {
            color: $p1;
            .icon {
                fill: $p1;
                transform: scale(1.1); }
 }            //background-color: $n3
        @include dark {
            color: $n;
            background-color: $n7;
            &:hover {
                color: $p1;
                .icon {
                    fill: $p1;
                    transform: scale(1.1); }
 } }                //background-color: $n6
        .icon {
            transition: .2s ease-in-out;
            fill: $n4;
            @include dark {
                fill: $n4; } } } }


 :global {
  .mobile {
     :local {
      .button {
        //padding: 10px 0
        border-radius: 50%;
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 10px;
        right: 10px; } } } }
