@import "styles/helpers";

.card {
  padding: 0; }

.details {
  display: flex;
  //align-items: center
  gap: 16px;

  .image {
    width: 120px;
    aspect-ratio: 5 / 4;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: $default-radius; } } }

.user_info {
  display: flex;
  flex-direction: column;
  //gap: 4px

  .username {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    font-size: 18px;
    color: $n8;
    @include dark {
      color: $n1; } }

  .time {
    font-weight: 400;
    font-size: 14px;
    color: $n4; } }

.subheader, .description {
  margin-top: 24px; }

.informations_title {
  margin-bottom: 12px; }

.list_item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  b {
    color: $n4; }
  svg {
    path {
      fill: $n4; } } }

.head {
  margin-bottom: 12px; }

.description_title {
  width: 100%; }

.title_head {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  svg {
    transition: transform .2s ease-in-out; }
  &.active {
    svg {
      transform: rotate(180deg); } }
  svg {
    path {
      fill: $n4; } } }

.info {
  width: 100%;
  padding: 30px 0;
  text-align: center;
  color: $n4;
  font-weight: lighter;
  font-style: italic; }

.content {
  margin-top: 24px; }
