@import "styles/helpers";

.allWrap {
  display: flex;
  flex-direction: column;
  gap: 12px; }

.topContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $cards-margin; }

.card {
  min-width: 33%; }


 :global {
  .r-mobile {
     :local {
      .topContainer {
        grid-template-columns: 1fr; } } } }
