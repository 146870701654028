@import "../../../../styles/helpers";

.item {
    display: flex;
    align-items: center;
    padding: 12px;
    cursor: pointer; }

.link {
    display: flex;
    align-items: center;
    flex-grow: 1;
    &:hover {
        .title {
            color: $p1; } } }

.preview {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    //border-radius: 8px
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; }
    &.contain {
        background-color: $n;
        padding: 2px;
        img {
            object-fit: contain; } } }

.content {
    margin-bottom: 4px;
    @include caption1;
    color: $n4; }

.title {
    color: $n7;
    transition: color .2s;
    white-space: wrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    @include dark {
        color: $n1; } }

.close {
    flex-shrink: 0;
    margin-left: 24px;
    svg {
        fill: $shades1;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $p3; } } }
