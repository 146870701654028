@import "styles/helpers";

.filters {
    position: relative;
    &.active {
        .body,
        .overlay {
            visibility: visible;
            opacity: 1; } } }

.body {
    position: absolute;
    top: -24px;
    right: 0;
    z-index: 20;
    width: 408px;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n1;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    @include m {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: auto;
        overflow: auto; }
    @include dark {
        background: $n7; } }

.top {
    display: flex;
    align-items: center;
    margin-bottom: 24px; }

.title {
    margin-right: auto; }

.close {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: $n3;
    margin-left: 24px;
    svg {
        fill: $n7;
        transition: transform .2s; }
    @include dark {
        background: $n6;
        svg {
            fill: $n1; } }
    &:hover {
        svg {
            transform: rotate(90deg); } } }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 18;
    background: rgba($n2, .8);
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: rgba($n6, .9); } }

.disabled,
.disabled:hover,
.disabled:active, {
    cursor: default;
    svg {
        fill: #6F767E; } }

.trash_button {
    @include dark {
        box-shadow: 0 0 0 2px #272B30 inset; }
    box-shadow: 0 0 0 2px #EFEFEF inset;
    border-radius: 8px;
    width: 40px;
    height: 40px; }

.enabled {
    svg {
        fill: red; }
    border: solid 2px red;
    box-shadow: none; }

.modalActiveTitle {
    position: relative;
    margin-left: 10px;
    margin-bottom: 8px;
    width: 80%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &::before {
        content: '';
        display: block;
        float: left;
        background: $p1;
        border-radius: 100rem;
        width: 7px;
        height: 7px;
        margin-right: 10px;
        margin-top: 9px; } }
