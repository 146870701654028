@import "styles/helpers";

.subtitle {
  margin-bottom: $title-content-margin; }

.about {
    display: flex;
    gap: 24px; }

.image {
    flex-shrink: 0;
    width: 96px;
    aspect-ratio: 1;
    height: auto;
    margin: 0;
    @include s {
        display: none; }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }

.image_wrapper {
    background-color: #ffffff;
    padding: 24px;
    aspect-ratio: auto;
    img {
        border-radius: 0; } }

.info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 0; }

.name {
    display: flex;
    align-items: center;
    gap: 12px;
    @include title2;
    font-weight: 600;
    color: $n7;
    @include dark {
        color: $n1; }
    @include s {
        margin-top: 12px; }
    img {
        display: none;
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
        @include s {
            display: block; } } }

.content {
    @include body1-m;
    margin-bottom: 0;
    color: $n6;
    @include dark {
        color: $n3; } }

.message {
    color: $shades1;
    font-weight: 400;
    font-style: italic; }
