@import "../../../styles/helpers";
.select {
  [class$="-control"], [class$="-menu"] {
    background-color: $n2;
    border-radius: 12px;
    box-shadow: none;
    border: none;
    @include dark {
      background-color: $n6; } }
  [class$="-menu"] {
    border: 2px solid $n4; }

  [class$="-multiValue"] {
    background-color: #9659e1;
    border-radius: 6px;
    color: $n;
    div {
      color: $n; }
    [class$="-xb97g8"] {
      border-radius: 6px;
      cursor: pointer;
      &:hover {
        background-color: #7341b1; } } }

  [class$="-option"] {
    cursor: pointer;
    // border-radius: 6px
    &:hover {
      background-color: #9659e1;
      color: $n; }
    &:focus {
      background-color: #fff; } }
  [class$="-input"] {
    color: $n; } }

.children_button {
    @include dark {
        color: $n; }
    h1 {
        font-size: 1.8rem; }
    h3 {
        font-size: 1rem;
        font-weight: normal; }
    p {
        font-weight: normal;
        margin-top: 24px;
        color: $n4;
        margin-right: 2rem;
        @include m {
            margin: 1rem 0; } } }
