@import "styles/helpers";

.row {
  display: grid;
  grid-template-columns: 44px 80fr 50px;
  grid-gap: 10px;
  padding: 10px; }

.button_row {
  display: grid;
  grid-template-columns: 30fr 10fr 10fr 10fr 10fr 10fr;
  grid-gap: 10px;
  font-size: 15px;
  color: black;
  @include dark {
    color: white; }
  padding: 10px 0px 10px 0px; }

.edit_button {
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center; }

.item {
  width: 100%;
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  position: relative;
  top: 50%;
  color: $n6;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  @include dark {
    color: $n1; } }

.item:nth-child(1) {
  justify-content: flex-start;
  font-size: 15px;
  word-break: break-all;
  text-align: start;
  padding-left: 10px; }

.item_checkbox {
  width: 100%;
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center; }

.divider {
  height: 1px;
  width: 100%;
  left: 1%;
  right: 0;
  background: $n3;
  @include dark {
    background: $n6 {
      // +t
 }      //     top: 80px
    @include m {
      width: 100%; } } }

.mobile_item_code {
  width: 100%;
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  word-break: break-all;
  text-align: start;
  justify-content: flex-start;
  color: black;
  margin-bottom: 75px;
  @include dark {
    color: white; }
  grid-area: 1 / 1 / 3 / 3;
  padding: 10px; }

.item_mobile {
  display: flex;
  color: gray;
  width: 100%;
  font-size: 15px;
  justify-content: flex-start;
  align-items: center; }

.button_row_mobile {
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 25px);
  grid-column-gap: 25px;
  grid-row-gap: 0px;
  padding: 10px; }

.mobile_hover {
  padding-left: 10px;
  padding-right: 10px;
  &:hover {
    border-radius: 8px;
    background-color: $n4;
    @include dark {
      background-color: $n5; } } }


.name {
  transition: all .3s ease;
  &:hover {
    cursor: pointer;
    color: $p1; } }

@media(max-width: 1020px) {
  .button_row_mobile {
    padding-bottom: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px; }
  .mobile_item_code {
    margin-bottom: 0px;
    padding: 0 0 5px 0; }
  .item_mobile {
    padding: 0; }
  .button_row_mobile {
    padding: 0; } }

@media(max-width: 450px) {
  .item_mobile:nth-child(3) {
    justify-content: flex-end; }
  .item_mobile:nth-child(5) {
    justify-content: flex-end; } }
