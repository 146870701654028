@import "styles/helpers";

.edit_tag {
  display: flex;
  align-items: flex-end;
  gap: 12px;
  @include m {
    flex-direction: column;
    align-items: stretch; } }

.input_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  @include m {
    width: 100%; } }

.input {
  width: 100%;
  max-width: 200px;
  height: 48px;
  padding: 0 10px;
  border-radius: $default-radius;
  border: 2px solid transparent;
  background: $n2;
  @include inter;
  @include base1-s;
  color: $n7;
  transition: all .2s;
  @include dark {
    border-color: $n6;
    background: $n6;
    color: $n1; }
  @include placeholder {
    color: $n4;
    @include dark {
      color: $shades1; } }
  &:focus {
    border-color: $shades1;
    background: $n;
    @include dark {
      border-color: $n5;
      background: $n8; } }
  @include m {
    max-width: 100%; } }

.input_color_container {
  display: flex;
  position: relative;
  //gap: 12px
  max-width: 130px;
  @include m {
    max-width: 100%; }
  .input {
    border-radius: 0 $default-radius $default-radius 0;
    border-left: 0;
    &:focus {
      border-left: 0; } } }

.input_color_box {
  z-index: 10;
  cursor: pointer;
  position: relative;
  border-radius: $default-radius 0 0 $default-radius;
  overflow: hidden;
  width: 60px;
  height: 48px;
  padding: 0; }

.input_color {
  cursor: pointer;
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  border: none;
  padding: 0;
  border-radius: $default-radius; }

.save_btn {
  @include m {
    margin-top: 12px; } }
