@import "styles/helpers";

.container {
    display: flex;
    width: 100%;
    gap: 8px;
    overflow: hidden;
    border-radius: $default-radius;
    background-color: $n1;
    padding: 8px 0 13px;
    @include dark {
      background-color: $n7 {
 } }        //border: 1px solid $n7
    span {
        @include dark {
            --base-color: #313131;
            --highlight-color: #525252; } } }

.card {
    margin-bottom: $cards-margin;
    padding: 12px 25px; }
