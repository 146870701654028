@import "styles/helpers";

.container {
  flex: 1;
  min-width: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-color: $n1;
  padding: 16px 32px;
  border-radius: $default-radius;
  border: 2px solid $n3;
  @include dark {
    border-color: $n5;
    background-color: $n7; } }

.icon {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $p1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid $p1;
  @include dark {
    background-color: $p1; }
  path {
    fill: $n; } }


.content {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: capitalize;
  b {
    font-size: 14px;
    color: $n4;
    text-transform: none; } }
