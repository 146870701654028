@import "../../../styles/helpers";

.field {
  padding: 0; }



.checkbox {
    display: grid;
    grid-template-columns: 1fr 99fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    border-radius: 12px; }
