@import "../../styles/helpers";

$color-error-msg: #cf0000;

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 48px;
    background: $n1;
    @include m {
        padding: 24px; }
    @include dark {
        background: $n7; } }

.wrapper {
    max-width: 296px;
    @include m {
        max-width: 100%; } }

.logo {
    display: inline-block;
    width: 48px;
    margin-bottom: 32px;
    img {
        width: 100%; } }

.guest {
    margin-top: 32px; }

.title {
    margin-bottom: 32px; }

.head {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 2px solid $n3;
    @include dark {
        border-color: $n6; } }

.subtitle {
    margin-bottom: 20px;
    @include body2-s; }

.btns {
    display: flex;
    margin: 0 -4px;
    .button_account {
        //flex: 0 0 calc(50% - 8px)
        //width: calc(50% - 8px)
        width: 100%;
        margin: 0 4px;
        padding: 0 16px;
        img {
            margin-right: 10px;
            max-height: 24px; } } }

.button {
    // display: flex
    // align-items: center
    // gap: 24px
    // transition: opacity .2s ease-in-out
    // &.disabled
    //     opacity: 0.8
    // .loader
    //     display: none
    //     &.visible
 }    //         display: block

.body {
    .button {
        width: 100%; } }

.field {
    margin-bottom: 12px; }

.note {
    margin-top: 32px;
    @include body2-s;
    font-size: 12px;
    color: $shades1; }

.links {
    text-align: center;
    margin-top: 32px; }

.info_link {
    @include caption1;
    color: $n4;
    transition: color .2s;
    &:hover {
        color: $p1; } }

.info {
    @include caption1;
    color: $n4;
    a {
        //font-weight: 700
        color: $n7;
        transition: color .2s;
        @include dark {
            color: $n1; }
        &:hover {
            color: $p1; } } }

.error {
    position: relative;
    padding: 12px;
    padding-left: 48px;
    margin-bottom: 12px;
    color: $color-error-msg;
    border: 1px solid $color-error-msg;
    border-radius: 12px;
    width: 100%;

    svg {
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translate(-50%, -50%);
        fill: $color-error-msg; } }
