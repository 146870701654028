@import "../../../../styles/helpers";

.item {
    position: relative;
    display: flex;
    padding: 12px;
    border-radius: 8px;
    transition: background .2s;
    &:hover {
        background: $n2;
        .icon {
            border-color: $n2; }
        @include dark {
            background: $n5;
            .icon {
                border-color: $n5; } }
        @include m {
            background: none; } }
    &:not(:last-child) {
        &:before {
            content: "";
            position: absolute;
            left: 12px;
            right: 12px;
            bottom: 0;
            height: 1px;
            background: $n3;
            @include dark {
                background: $n6; } } }
    &:nth-child(n+3) {
        @include m {
            display: none; }
        @include a {
            display: flex; } }
    &:nth-child(n+6) {
        @include a {
            display: none; } } }

.avatar {
    position: relative;
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    & > img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.icon {
    position: absolute;
    right: -2px;
    bottom: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 2px solid $n;
    background: $p1;
    fill: white;
    @include dark {
        border-color: $n7; }
    img {
        width: 16px; } }

.details {
    flex: 0 0 calc(100% - 48px);
    width: calc(100% - 48px);
    padding: 0 12px; }

.line {
    display: flex;
    align-items: center;
    padding-right: 24px; }

.subtitle {
    margin-right: 8px;
    font-weight: 700;
    color: $n7;
    p {
        font-weight: normal;
        a {
            font-weight: 700; } }
    @include dark {
        color: $n1; } }

.login {
    margin-right: auto;
    font-weight: 500;
    color: $shades1; }

.time {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 5px;
    @include caption1;
    color: $shades1; }

.content {
    width: 100%;
    font-weight: 500;
    color: $n4;
    strong {
        font-weight: 700;
        color: $n7;
        @include dark {
            color: $n1; } }
    p {
        line-height: 18px;
        margin-bottom: 10px;
        width: 100%; } }

.control {
    z-index: 6; }

.isread {
    position: absolute;
    top: 10px;
    right: 12px;
    padding: 0px 10px;
    font-size: 0.9em;
    color: white;
    border-radius: 20px;
    background: $shades1;
    cursor: pointer;
    z-index: 2;
    &.new {
        background: $p1; } }

.link {
    color: $p1;
    font-style: italic;
    &:hover {
        color: lighten($p1, 15%); } }
