@import "styles/helpers";

.background {
    height: 400px;
    border-radius: 12px;
    @include d {
        height: 320px; }
    img {
        border-radius: 12px;
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.background_loader {
    background-color: white;
    height: 400px;
    @include d {
        height: 320px; }
    @include dark {
        background-color: $n6; } }

.card {
    max-width: 1200px;
    min-height: 500px;
    margin: -224px auto 0;
    background: none;
    @include dark {
        background: none; }
    @include m {
        margin-top: -188px; } }

.control {
    padding: 24px;
    display: flex;
    align-items: center;
    gap: 24px;
    border-radius: 12px 12px 0px 0px;
    @include m {
        flex-wrap: wrap; } }

.separator {
    margin-left: 24px;
    margin-right: 24px;
    border-bottom: 1px solid $n3;
    @include dark {
        border-color: $n6; } }

.head {
    display: flex;
    gap: 12px;
    margin-bottom: 24px;
    @include m {
        margin-bottom: 12px; } }

.container {
    border-radius: 12px;
    background: white;
    @include dark {
        background: $n7; } }

.nav {
    // overflow-y: hidden
    // overflow-x: auto
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around
    row-gap: 10px;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        height: 6px; }
    &::-webkit-scrollbar-track {
        background: $n1; }
    &::-webkit-scrollbar-thumb {
        background: $n3;
        border-radius: 10px; }
    @include dark {
        &::-webkit-scrollbar-track {
            background: $n7; }
        &::-webkit-scrollbar-thumb {
            background: $n8;
            border-radius: 10px;
            padding: 8px 0;
            transition: .2s ease-in-out;
            display: flex;
            margin-right: auto; } }
    @include m {
        width: 100%;
        margin: 0 0 16px; }
    & > button {
        flex-grow: 0; } }

.link {
    position: relative;
    padding: 8px 16px;
    border-radius: 8px;
    @include base1-s;
    color: $n4;
    flex-shrink: 0;
    @include m {
        flex-grow: 1; }
    &:hover,
    &.active {
        color: $n7;
        @include dark {
            color: $n1; } }
    &.active {
        background: $n3;
        @include dark {
            background: $n6; } }
    &:not(:last-child) {
        margin-right: 8px;
        @include m {
            margin-right: 0; } } }

.company {
 }    //padding: 0 16px

.dropdownBox {
    @include m {
        flex-grow: 1; } }

.filters {
    margin-left: 16px;
    @include m {
        margin-left: 12px; } }

.posts {
    display: flex;
    flex-direction: column;
    @include a {
        display: block;
        margin: 0; } }

.product {
    flex: 0 0 calc(33.333% - 24px);
    width: calc(33.333% - 24px);
    margin: 32px 12px 0;
    @include t {
        flex: 0 0 calc(50% - 24px);
        width: calc(50% - 24px); }
    @include a {
        width: 100%;
        margin: 24px 0 0; } }

.foot {
    margin-top: 40px;
    text-align: center;
    @include x {
        margin-top: 32px; }
    @include m {
        margin-top: 24px; } }

.info {
    width: 100%;
    padding: 30px 0;
    text-align: center;
    color: $n4;
    font-weight: lighter;
    font-style: italic; }

.level_container {
    display: flex;
    gap: 24px; }

.level_info {
    display: flex;
    gap: 4px;
    .title {
        writing-mode: vertical-rl;
        text-orientation: upright;
        font-size: 12px; }
    @include s {
        width: 100%; } }

.level_bar {
    @include s {
        width: 100%; } }

.rank_info {
    display: flex;
    //flex-direction: column
    align-items: center;
    color: $n4;
    gap: 8px;
    .badge {
        display: flex;
        align-items: center;
        gap: 6px;
        color: $n4;
        img {
            border-radius: 10px;
            height: 28px; } } }

.followed_user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid $n5;
    .followed_user_data {
        display: flex;
        align-items: center;
        .followed_user_img {
            position: relative;
            flex-shrink: 0;
            width: 80px;
            height: 80px;
            margin-right: 16px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
                border: 2px solid aqua; }
            .level {
                position: absolute;
                bottom: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 2px solid aqua;
                background-color: $n5;
                color: $n1; } } } }

.quizzes {
    background: white;
    padding: 24px;
    border-radius: 12px;
    @include dark {
        background: $n7; } }

.notExistsInfo {
    text-align: center; }

.count_indicator {
    z-index: 2;
    position: absolute;
    top: -5px;
    right: 10px;
    transform: translateX(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    min-width: 18px;
    height: 18px;
    border-radius: 100px;
    padding: 0 5px;
    font-size: 10px;
    // background-color: $n4
    background-color: $p1;
    color: $n1;
    transition: .5s ease; }

 :global {}
.mobile {
     :local {

        .card {
            margin-top: -188px; }

        .control {
            flex-wrap: wrap; }

        .head {
            gap: 12px; }

        .userinfo {
            flex-direction: column;
            margin-bottom: 5px;
            padding-bottom: 12px; }

        .nav {
            width: 100%;
            margin: 0 0 16px; }

        .link {
            flex-grow: 1;
            &:not(:last-child) {
                margin-right: 0; } }

        .dropdownBox {
            flex-grow: 1; }

        .filters {
            margin-left: 12px; }

        .foot {
            margin-top: 24px; } } }
