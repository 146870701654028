@import "styles/helpers";

.container {
  display: grid;
  grid-template-columns: 1fr 250px;
  gap: 24px;
  padding: 24px;
  border: 2px solid $n3;
  border-radius: $default-radius;
  background-color: $n1;
  @include dark {
    border-color: $n5;
    background-color: $n7; } }

.author {
  color: $n4; }

.job_item {
  //background-color: $n2
  margin-bottom: 0;
  //border: 2px solid $n3
  padding: 0 !important;
  // +dark
 }  //   background-color: $n6

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  font-size: 14px;
  font-weight: 400; }

.heading {
  color: $p1;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid $p1;
  margin-bottom: 8px; }

.attachment {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  margin-top: 12px;
  padding: 8px 14px;
  border-radius: $default-radius;
  background-color: $n3;
  svg {
    fill: $p1; }
  @include dark {
    background-color: $n6; }
  &::after {
    content: 'Pobierz';
    pointer-events: none;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $default-radius;
    transition: .2s;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: darken($n3, 10);
    opacity: 0;
    @include dark {
      background-color: lighten($n6, 10); } }
  &:hover {
    &::after {
      opacity: 1; } } }

.attachment_size {
  font-size: 12px;
  font-weight: 400;
  color: $n4; }

 :global {
  .tablet {
     :local {

      .container {
        grid-template-columns: 100%; }

      .job_item {
        margin: 24px auto 0;
        width: 250px; } } } }
