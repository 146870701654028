@import "../../styles/helpers";

.sidebar {
  z-index: 100;
  position: sticky;
  top: 0;
  left: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 96px;
  height: 100vh;
  padding: 8px;
  background: $n1;
  transition: width .3s ease-in-out;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; }
  @include dark {
    background: $n7; }
  @include m {
    position: fixed;
    transform: translateX(-100%);
    width: 100%;
    align-items: stretch; }

  .version {
    color: $shades2; }

  &.active {
    width: 300px;
    @include m {
      transform: translateX(0);
      width: 100%;
      height: calc(100vh - calc(100vh - 100%));
      align-items: stretch; }
    .socialmedia {
      flex-direction: row;
      align-items: stretch;
      width: 100%;
 }      //box-shadow: 4px 0 32px rgba($n8, .05)
    .item,
    .link {
      width: 100%;
      font-size: 15px;
      svg {
        margin-right: 12px; } }
    .counter {
      display: block; }
    .toggle {
      font-size: 15px; }
    .logotitle {
      display: block {

        // .toggle
        //     position: absolute
        //     top: 16px
        //     right: 16px
        //     svg
        //         fill: $n7
        //         &:first-child
        //             display: none
        //         &:nth-child(2)
        //             display: inline-block
        //         +dark
 } }        //             fill: $n1

    .footer_info {
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      font-size: 11px;
      text-align: center; }

    .logo {
      justify-content: left;
 } } }      //margin: 0

.logo {
  display: flex;
  width: fit-content;
  position: relative;
  margin-bottom: 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px;
  padding-bottom: 0;

  img {
    height: 50px; }

  .logotitle {
    font-family: 'Comfortaa', sans-serif;
    font-size: 20px;
    color: $n4;
    margin: 0;
    padding: 0 3px;
    line-height: 100%;
    display: none;
    @include dark {
      color: $n4; } } }

.menu {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-bottom: auto;
  overflow-y: visible;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  gap: 8px;
  padding-left: 16px;
  padding-right: 8px;
  @include hide-scrollbar;
  &:hover {
    @include custom-scrollbar; } }

.item,.link,.toggle {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 12px;
  font-weight: 600;
  white-space: nowrap;
  color: $n4;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  font-size: 0;
  @include m {
    width: 100%;
    font-size: 15px; }
  svg {
    flex-shrink: 0;
    fill: $n4;
    margin-right: 0;
    @include m {
      margin-right: 12px; } }
  &:hover,&.active {
    color: $n7;
    svg {
      fill: $n7; }
    @include dark {
      color: $n1;
      svg {
        fill: $n1; } } }
  &.active {
    background: $n3;
    box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF;
    @include dark {
      background: $n6;
      box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11); } } }

.toggle {
  padding-inline: 16px;
  border-radius: 10px;
  &:hover {
    background-color: $n2;
    @include dark {
      background-color: $n6; } }
  @include m {
    display: none; } }

.burger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  //margin-right: auto
  @include m {
    display: flex; }
  &:before,
  &:after {
    content: "";
    width: 16px;
    height: 2px;
    border-radius: 2px;
    background: $n4; }
  &:before {
    margin-bottom: 6px; } }

.close {
  display: none;
  @include m {
    display: block;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 48px;
    height: 48px;
    svg {
      fill: $n7;
      @include dark {
        fill: $n1; } } } }

.foot {
  margin-top: 12px;
  margin-bottom: 12px;
  padding-top: 12px;
  border-top: 2px solid $n2;
  @include d {
    margin-top: 12px;
    padding-top: 12px; }
  @include dark {
    border-color: $n6; } }

.footer_info {
  display: none; }

.policy_links {
  color: $shades2;
  a {
    color: $shades2;
    text-decoration: underline;
    transition: color .2s ease-in-out;
    &:hover {
      color: $p1; } } }

.theme {
  // margin-top: 16px
  margin-top: 12px;
  margin-bottom: 8px; }

.counter {
  flex-shrink: 0;
  min-width: 24px;
  margin-left: auto;
  border-radius: 6px;
  background: $s2;
  text-align: center;
  line-height: 24px;
  color: $n7;
  @include d {
    display: none; }
  @include m {
    display: block; } }

.overlay {
  @include d {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    background: rgba($n2, .8);
    visibility: hidden;
    opacity: 0;
    @include dark {
      background: rgba($n6, .9); }
    &.active {
      visibility: visible;
      opacity: 1; } }
  @include m {
    display: none; } }

.socialmedia {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  column-gap: 16px;
  position: relative;
  width: 100%;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    //border: 2px solid $n3
    padding: 10px;
    svg {
      //filter: alpha(.6)
      fill: $n4;
      margin-right: 0!important; }
    // span
    //     color: $n4
    //     text-align: center
    //     flex: auto
    //     +d
    //         display: none
    //     +m
    //         display: block
    //         text-align: left
    &:hover {
      cursor: pointer;
      background: $n2;
      svg {
        fill: $n6;
        @include dark {
          fill: $n2; } }
      span {
        color: $n6; }
      @include dark {
        background: $n6;
        span {
          color: $n2; } } } } }
