@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px; }

.section_header {
  color: $p1;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid $p1;
  margin-bottom: 8px; }

.section_content {
  display: flex;
  gap: 24px;
  @include t {
    flex-direction: row-reverse;
    justify-content: space-between; } }

.data_row {
  font-weight: 400;
  a {
    color: $n7;
    text-decoration: none;
    @include dark {
      color: $n3; }
    &:hover {
      text-decoration: underline; } }
  b {
    font-weight: 600;
    color: $p1; } }

.personal_data {
  display: flex;
  flex-direction: column;
  gap: 4px; }

.image {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 6px double $p1;
  overflow: hidden;
  @include t {
    width: 90px;
    height: 90px; }
  img {
    object-fit: cover;
    width: 100%; } }

.footer {
  color: $n4;
  font-weight: 400;
  font-size: 14px; }

.footer_header {
  color: $n4;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid $n4;
  margin-bottom: 8px; }
