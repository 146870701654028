@import "styles/helpers";

.links {
  margin-bottom: 24px; }

.list {
  display: flex;
  flex-direction: column;
  gap: 12px; }

.link {
  text-decoration: underline;
  transition: color .2s ease-in-out;
  color: $n8;
  @include dark {
    color: $n1; }
  &:hover {
    color: $p1; } }

.container {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center; }

.checkbox {
  cursor: default; }
