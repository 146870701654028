@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: $title-content-margin; }

.content {
  li {
    @include body1-m;
    color: $n6;
    @include dark {
      color: $n3; }
    b {
      color: $n4; } } }
