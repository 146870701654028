@import "../../../../../styles/helpers";

.control {
    display: flex;
    align-items: center;
    margin: 8px 0 15px 0; }

.button {
    @include button1;
    color: $n7;
    transition: color .2s;
    @include dark {
        color: $n1; }
    &:hover {
        color: $p1; }
    &:not(:last-child) {
        margin-right: 24px; }
    &.favorite {
        svg {
            &:nth-child(2) {
                display: none; } }
        &.active {
            svg {
                &:first-child {
                    display: none; }
                &:nth-child(2) {
                    display: inline-block; } } } } }

.answer {
    &.show {
        display: block;
        margin: 10px 0; } }

.favorite {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    color: $shades1;
    transition: color .2s;
    svg {
        fill: $shades1;
        transition: fill .2s; }
    &:hover {
        color: $p1;
        svg {
            fill: $p1;
            cursor: pointer; } }
    &.active {
        color: $n7;
        svg {
            fill: $n7; }
        @include dark {
            color: $n1;
            svg {
                fill: $n1; } } }
    &:not(:last-child) {
        margin-right: 15px; } }

.favorite {
    svg {
        &:nth-child(2) {
            display: none; } }
    &.active {
        svg {
            &:first-child {
                display: none; }
            &:nth-child(2) {
                display: inline-block; } } } }
