@import "styles/helpers";

.modal_body {
    position: relative;
    &.active {
        .body,
        .overlay {
            visibility: visible;
            opacity: 1; } } }

.body {
    position: absolute;
    top: -24px;
    right: 0;
    left: -380px;
    z-index: 1000;
    width: 600px;
    height: fit-content + 50px;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n1;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    @include m {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: auto;
        overflow: auto; }
    @include dark {
        background: $n7; } }

.top {
    display: flex;
    margin-bottom: 24px; }

.title {
    margin-right: auto; }

.close {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: $n3;
    margin-left: 24px;
    svg {
        fill: $n7;
        transition: transform .2s; }
    @include dark {
        background: $n6;
        svg {
            fill: $n1; } }
    &:hover {
        svg {
            transform: rotate(90deg); } } }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba($n2, .8);
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: rgba($n6, .9); } }

.enabled {
    border: solid 2px green;
    box-shadow: none;
    svg {
        fill: green; } }

.enabledFooter {
    border: solid 2px black;
    box-shadow: none;
    color: black;
    @include dark {
        border: solid 2px white;
        color: white; } }

.disabledFooter,
.disabledFooter:hover,
.disabledFooter:active, {
    cursor: default;
    border: solid 2px #6F767E;
    color: #6F767E; }

.disabled,
.disabled:hover,
.disabled:active, {
    cursor: default;
    svg {
        fill: #6F767E; } }

.choiceButton {
    @include dark {
        box-shadow: 0 0 0 2px #272B30 inset; }
    box-shadow: 0 0 0 2px #EFEFEF inset;
    border-radius: 8px;
    width: 40px;
    height: 40px; }

.foot {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  text-align: center; }

.footerButton {
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px; }

.pagination_numbers {
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 10px;
  color: $n7;
  &:hover {
    background-color: $n4; }
  @include dark {
    color: $n2;
    &:hover {
      background-color: $n5; } } }

.row {
    display: grid;
    grid-template-columns: 1fr 15fr;
    margin-bottom: 5px; }

.container {
  display: grid;
  grid-template-columns: 50fr 50fr;
  grid-gap: 10px; }

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.itemTitle {
  font-size: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.divider {
  height: 1px;
  width: 100%;
  left: 1%;
  right: 0;
  background: $n3;
  @include dark {
    background: $n6;
    @include m {
      width: 94%; } }
  @include s {
    display: none; } }
