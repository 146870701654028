@import "styles/helpers";

.container {
    display: flex;
    flex-direction: column;
    gap: 24px; }

.tags {
    // display: flex
    align-items: center;
    // flex-wrap: wrap
    // gap: 24px 36px
    border-radius: 2px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 36px; }

.tag {
    flex-shrink: 0;
    white-space: nowrap;
    width: fit-content;
    height: fit-content;
    padding: 4px 8px;
    border-radius: $default-radius;
    color: $n7;
    font-size: 13px;
    text-transform: uppercase;
    transition: .2s ease-in-out; }

.item {
 }    //max-width: 120px
