@import "styles/helpers";

.row {
    display: flex;
    padding-bottom: 40px;
    @include t {
        display: block; } }

.col {
    width: 100%;
    // &:first-child
    //     flex: 0 0 calc(100% - 340px)
    //     width: calc(100% - 340px)
    //     padding-right: $cards-margin
    //     +d
    //         flex: 0 0 calc(100% - 296px)
    //         width: calc(100% - 296px)
    //     +t
    //         width: 100%
    //         padding: 0
    // &:nth-child(2)
    //     flex-shrink: 0
    //     width: 340px
    //     +d
    //         width: 296px
    //     +t
 }    //         width: auto

.card {
    &:not(:last-child) {
        margin-bottom: $cards-margin; } }

.postcreated {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    width: 100%;
    padding: 5rem;
    p {
        font-size: 1.6em;
        margin-bottom: 2em; }
    .panel {
        button {
            margin-right: 2em; } } }

.details {
    margin-bottom: 12px; }

.container {
    margin-bottom: 12px; }


.card_links {
    margin-top: 12px;
    margin-bottom: 12px; }
