@import "styles/helpers";

.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  &:hover {
    .controls {
      opacity: 1;
      visibility: visible;
      .prev {
        left: 2px; }
      .next {
        right: 2px; } } } }

.scroll {
  position: relative;
  width: 100%;
  overflow-x: auto;
  // scroll-snap-type: x mandatory
  // scroll-behavior: smooth
  // scroll-behavior-inline: contain
  // & > *
  //   scroll-snap-align: start
  // Scrollbar
  &::-webkit-scrollbar {
    display: block;
    height: 5px; }
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: $default-radius;
    background: $n1;
    @include dark {
      background: $n7; } }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: $default-radius;
    background: $n3;
    @include dark {
      background: $n6; } }

  &.scrollbar_hidden {
    &::-webkit-scrollbar {
      display: none; } } }

.controls {
  opacity: 0;
  visibility: hidden;
  transition: .2s ease-in-out;
  path {
    fill: $n1; }
  .prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $p1;
    transition: .2s ease-in-out;
    &.wide {
      width: 50px;
      height: 50px;
      svg {
        width: 30px;
        height: 30px; } }
    &.hidden {
      opacity: 0;
      visibility: hidden; } }

  .prev {
    left: -3px;
    svg {
      transform: rotate(180deg); } }
  .next {
    right: -3px; }

  &.hidden {
    display: none; } }
