@import "styles/helpers";

article {
    width: 100%;
    &.article_auto_width {
        width: auto; } }

.item {
    position: relative;
    display: flex;
    gap: 24px;
    width: 100%;
    margin-bottom: $cards-margin;
    border-radius: $default-radius;
    padding: 25px;
    background-color: $n1;
    @include dark {
        border-color: $n6;
        background-color: $n7; } }

.head {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-right: 78px; }

.details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden; }

.title {
    line-height: 1.4;
    font-weight: 700;
    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.actions {
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    align-items: center;
    margin-left: auto;
    background-color: $n1;
    padding: 0;
    border-radius: 0 $default-radius 0 $default-radius;
    flex-shrink: 0;
    gap: 8px;
    @include dark {
        background-color: $n7; } }

.pin_btn {
  cursor: pointer;
  img {
    height: 20px;
    transition: .2s ease-in-out; }
  &:hover {
    img {
      filter: brightness(80%);
      @include dark {
        filter: brightness(120%); } } } }

.imagecontainer {
    position: relative; }
.views {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: transparentize($n4, 0.3);
    padding: 5px 10px;
    color: $n1;
    transition: .2s ease-in-out;
    @include base2;
    svg {
        margin-right: 8px;
        fill: $n1; } }

.content {
    display: flex;
    gap: 2px;
    @include body1-m;
    margin-bottom: 0;
    color: $shades1;
    strong {
        font-weight: 700;
        color: $n7;
        @include dark {
            color: $n1; } } }

.price {
    @include body1-m;
    display: flex;
    margin-right: 4px;
    margin-top: 2px; }

.price_detail {
    @include body1-m;
    margin-top: 2px;
    color: $n7;
    @include dark {
        color: $n1; } }

.specifics {
    display: flex;
    align-items: center;
    gap: 4px 16px;
    flex-wrap: wrap;
    margin-top: 4px; }

.specifics_item {
    display: flex;
    align-items: center;
    gap: 4px;
    @include body1-m;
    path {
        fill: $n4; } }

.specifics_item_salary {
    color: $shades1; }

.language_flag {
    height: 16px; }

.language {
    text-transform: uppercase; }

.time {
    font-size: 14px;
    color: $n4; }

.foot {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    @include m {
        margin-top: 24px; } }

 :global {

    .mobile {
         :local {
            .item {
                flex-direction: column;
                gap: 12px; }

            .head {
                padding-right: 0;
                flex-direction: column;
                align-items: flex-start; }

            .image {
                width: 100%; }

            .title {
                font-size: 20px;
                margin-top: 8px; }

            .actions {
                top: 0;
                right: 0;
                padding: 10px; }

            .time {
                bottom: auto;
                right: 40px;
                top: 228px; }

            .foot {
                margin-top: 24px; } } } }
