@import "styles/helpers";

.content {
  display: flex;
  flex-direction: column;
  gap: $cards-margin; }

.item {
  padding: 0 !important; }

.loader {
  align-self: center;
  transform: scale(1.2);
  margin: 20px 0 40px; }

.empty {
  font-style: italic;
  text-align: center;
  color: $n4; }
