@import "../../styles/helpers";

.title {
  font-size: 20px;
  margin-bottom: 16px; }

.settings {
  display: flex;
  flex-direction: column; }

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  height: 50px;
  cursor: pointer;
  padding: 0 6px;
  border-radius: 10px;
  transition: .2s ease-in-out;
  span {
    color: $n7;
    @include dark {
      color: $n1; } }
  &:hover {
    background-color: $n3;
    @include dark {
      background-color: $n6; } }
  svg {
    path {
      fill: $n4; } }

  .item_name {
    display: flex;
    align-items: center;
    gap: 12px; }

  .item_options {
    z-index: 20;
    display: flex;
    align-items: center;
    gap: 12px; }

  .item_btn {
    z-index: 30;
    padding: 6px 6px;
    border-radius: 10px;
    transition: color .2s ease-in-out;
    background-color: $n3;
    color: $n7;
    @include dark {
      background-color: $n5;
      color: $n3; }
    &:hover {
      background-color: $n4; } } }

.red {
  background: transparentize($p3, 0.9); }
