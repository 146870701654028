@import "styles/helpers";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &:not(:last-child) {
    padding-bottom: 12px;
    border-bottom: 1px solid $n3;
    @include dark {
      border-color: $n5; } } }

.row {
  display: flex;
  align-items: center;
  gap: 12px; }

.image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%; } }

.username {
  color: $n8;
  @include dark {
    color: $n; } }

.inner_button {
  display: none; }

 :global {
  .mobile {
     :local {

      .inner_button {
        display: block; } } } }
