@import "../../../styles/helpers";

.link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 12px;
  border-radius: 12px;
  font-weight: 600;
  white-space: nowrap;
  color: $n4;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  font-size: 0;
  flex-shrink: 0;
  @include m {
    width: 100%;
    font-size: 15px; }
  svg {
    flex-shrink: 0;
    fill: $n4;
    margin-right: 0;
    @include m {
      margin-right: 12px; } }
  &:hover,&.active {
    color: $n7;
    svg {
      fill: $n7; }
    @include dark {
      color: $n1;
      svg {
        fill: $n1; } } }
  &.active {
    background: $n3;
    box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05), inset 0px 1px 1px #FFFFFF;
    @include dark {
      background: $n6;
      box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11); } } }


.menu_hidden {
  width: 48px; }
