@import "../../styles/helpers";

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  img {
    height: 50px; } }

.btns {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  margin-top: 24px; }
