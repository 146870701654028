@import "styles/helpers";

.nav {
  @include element-flex(row, flex-start, center);
  width: 100%; }

.navItem {
  @include body1-m;
  padding: 12px 16px;
  width: 100%;
  color: $n4;
  position: relative;
  transition: all .3s ease;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    background: $p1;
    transition: all .3s ease;
    top: 80%;
    left: 0;
    opacity: 0; }

  &:hover {
    color: $n6;
    @include dark {
      color: $n1; }
    &::after {
      opacity: 1;
      top: 90%; } }

  &.active {
    color: $n6;
    @include dark {
      color: $n1; }
    &::after {
      opacity: 1;
      top: 90%; } } }
