@import "styles/helpers";

.chart {
    @include flex-center;
    width: auto;
    height: 329px;
    margin-left: -20px;
    margin-bottom: -10px;
    // &>div
    //     border-radius: $default-radius
    //     +dark
    //         background-color: rgba(255, 255, 255, .02)
    @include t {
        height: 300px; } }

