@import "../../../../../styles/helpers";

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 48px;
  background: rgba($n2,.8);
  opacity: 0;
  animation: showModal .4s forwards;
  overflow: auto;
  z-index: 999;
  @include m {
    padding: 16px; }
  @include dark {
    background: rgba($n6, .9); } }

@keyframes showModal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.outer {
  position: relative;
  width: min-content;
  // max-width: 408px
  margin: auto;
  background: $n1;
  border-radius: 16px;
  box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  z-index: 2;
  @include dark {
    background: $n7; }
  @include m {
    box-shadow: none; }
  & > div {
    padding: 1rem; } }

.close {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: $n3;
  font-size: 0;
  svg {
    fill: $n5;
    transition: transform .2s; }
  @include dark {
    background: $n6;
    svg {
      fill: $n1; } }
  &:hover {
    svg {
      transform: rotate(90deg); } }

 }  // Layout Module
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem; }
.LayoutContent {
  display: flex;
  padding: 1rem;
  gap: 1rem;
  @include m {
    padding: 0; }
  .LayoutBtnContent {
    border: 2px solid $n3;
    border-radius: 12px;
    padding: 1rem;
    display: flex;
    gap: 1rem;
    transition: 250ms ease-in-out;
    &:hover {
      border-color: $n4;
      transform: scale(1.04); }
    .LayoutBtnText {
      width: 200px;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      @include m {
        width: 100px; }
      div {
        background-color: $n3;
        border-radius: 6px;
        height: 20px;
        transition: 250ms ease-in-out;
        @include m {
          height: 10px; } }

      .LayoutBtntitle {
        width: 100px;
        height: 30px;
        @include m {
          width: 50px;
          height: 15px; } } }
    .LayoutBtnImage {
      width: 150px;
      height: 150px;
      background-color: $n3;
      transition: 250ms ease-in-out;
      border-radius: 12px;
      @include m {
        width: 100px;
        height: 100px; } } }

  @include dark {
    .LayoutBtnContent {
      border-color: $n5;
      &:hover {
        border-color: $n4; }
      .LayoutBtnText {
        div {
          background-color: $n5; } }
      .LayoutBtnImage {
        background-color: $n5; } } }

  @include t {
    flex-direction: column; } }

.active {
  border-color: $n4 !important;
  .LayoutBtnImage {
    background-color: $n4 !important; }
  .LayoutBtnText {
    div {
      background-color: $n4 !important; } } }

.btn_ce {
  background-color: $n3;
  color: $n5;
  transition: 250ms;
  padding: 0.7rem 1rem;
  border-radius: 12px;
  width: 100%;
  max-width: 200px;
  @include dark {
    background-color: $n5;
    color: $n4;
    &:hover {
      background-color: #2A85FF;
      color: $n1; }
    svg {
      @include dark {
        fill: white; } } } }

h2 {
  @include m {
    font-size: 1rem; } }
