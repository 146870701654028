@import "styles/helpers";


.container {
  display: flex;
  flex-direction: column;
  gap: $title-content-margin;
  padding: 24px;
  background: white;
  border-radius: 12px;
  @include dark {
    background: $n7; } }

.head {
  display: flex;
  align-items: center;
  gap: 12px;
  @include title1-s; }

.comments_count {
  flex-shrink: 0;
  padding: 2px 12px;
  border-radius: $default-radius;
  background-color: $s2;
  text-align: center;
  @include dark {
    color: $n7; } }

.field {
  position: relative;
  .counter {
    position: absolute;
    font-size: 12px;
    bottom: 10px;
    right: 8px;
    color: $n4;
    &.error {
      color: #ff0000; } } }

.textarea {
  width: 100%;
  min-height: 5em;
  padding: 10px;
  border-radius: 12px;
  border: 2px solid transparent;
  background-color: $n2;
  @include inter;
  @include base1-s;
  color: $n7;
  resize: none;
  @include dark {
    border-color: $n6;
    background-color: $n7;
    color: $n1; }
  @include placeholder {
    color: $n4;
    @include dark {
      color: $shades1; } }
  &:focus {
    transition: all .2s;
    border-color: $shades1;
    background-color: $n;
    @include dark {
      border-color: $n5;
      background-color: $n8; } }
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; }
  &.error {
    border-color: #ff0000;
    @include dark {
      border-color: #ff0000; } } }

.button {
  width: fit-content;
  margin-top: -12px; }

.list {
  margin-top: 12px; }

 :global {
  .mobile {
     :local {
      .button {
        width: 100%; } } } }
