@import "styles/helpers";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px; }

.title {
  @include title1-s; }

.date {
  color: $n4; }

 :global {
  .mobile {
     :local {
      .container {
        flex-direction: column;
        align-items: flex-start;
        gap: 0; } } } }
