@import "styles/helpers";

.button {
  border: solid 1px $n1;
  @include dark {
    border: solid 1px $n5; }
  @include m {
    padding: 0 5px;
    width: 50px; } }

.inner_desktop {
  display: block;
  @include m {
    display: none; } }

.inner_mobile {
  display: none;
  @include m {
    display: block; }

  &.loading {
    margin-right: -20px; } }

 :global {
  .mobile {
     :local {

      .button {
        width: 50px;
        padding: 0 5px; }

      .inner_mobile {
        display: block; }

      .inner_desktop {
        display: none; } } } }
