@import "styles/helpers";

.list {
  @include element-flex(column, flex-start, stretch);
  list-style: none; }

.empty {
  @include element-flex(column, center, center); }

.info {
  width: 100%;
  padding: 40px 0;
  @include info;
  text-align: center; }
