@import "styles/helpers";

.filters {
    &.active {
        .body,
        .overlay {
            visibility: visible;
            opacity: 1; } } }

.body {
    position: absolute;
    top: -24px;
    z-index: 1000;
    left: 0;
    width: 950px;
    height: fit-content;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n1;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    @include m {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: auto;
        overflow: auto; }
    @include dark {
        background: $n7; } }

@media (max-width: 1140px) {
    .body {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: auto;
        height: auto;
        overflow: auto; } }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba($n2, .8);
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: rgba($n6, .9); } }

.details {
    font-size: 20px;
    margin-bottom: 24px; }

.row {
    display: flex;
    align-items: center;
    gap: 4px; }

.emptyCourses {
    color: $n4; }

.code_title {
    word-wrap: break-word;
    word-break: break-all;
    font-size: 25px;
    inline-size: 30ch;
    margin-left: 32px; }

@media(max-width: 650px) {
    .code_title {
        inline-size: auto; } }

.text_colour {
    color: gray; }

.top {
    display: flex;
    margin-bottom: 24px; }

.title {
    margin-right: auto;
    margin-bottom: 8px;
    @include title2; }

.main_title {
    margin-right: auto; }

.title_position {
    display: flex;
    top: 20px; }

.close {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: $n3;
    margin-left: 24px;
    svg {
        fill: $n7;
        transition: transform .2s; }
    @include dark {
        background: $n6;
        svg {
            fill: $n1; } }
    &:hover {
        svg {
            transform: rotate(90deg); } } }

.image_style {
    fill: black;
    @include dark {
        fill: white; }
    display: block; }

.item {
    text-align: baseline;
    @include body1-m; }

.list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%; }


.coursesList {
    margin-top: 10px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 210px); }

.coursesListItem {
    padding: 10px;
    border-radius: 8px;
    background-color: $n3;
    color: black;
    @include dark {
        color: $n3;
        background-color: $n5; }
    text-align: start;
    font-size: 15px; }

@media (max-width: 480px) {
    .coursesListItem {
        font-size: 12.5px;
        padding: 7.5px; }
    .coursesList {
        grid-template-columns: repeat(auto-fill, 150px); } }

.foot {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    text-align: center; }

.paginationPosition {
    display: flex;
    bottom: 0px;
    margin-top: 10px;
    align-items: center;
    justify-content: center; }

.pagination {
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 10px;
    color: $n7;
    &:hover {
        background-color: $n4; }
    @include dark {
        color: $n2;
        &:hover {
            background-color: $n5; } } }
