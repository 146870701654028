@import "styles/helpers";


.rating {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 0;
  border: 1px solid $n3;
  border-radius: 10px;
  padding: 10px;
  transition: background-color .2s ease-in-out;
  @include dark {
    border: 2px solid $n6; }
  @include base2;
  svg {
    margin-right: 8px;
    fill: #FFC554; }
  &:hover {
    background-color: $n3; }
  @include dark {
    color: $n1;
    border: 2px solid $n6;
    &:hover {
      background-color: $n6; } } }

.ratechange {
  position: relative;
  margin-left: 10px;
  margin-top: -4px; }
