@import "../../styles/helpers";

.theme {
    display: block;
    position: relative;
    user-select: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 0;
    &.wide {
        .input:checked + .inner {
            .box {
                &:first-child {
                    display: flex; }
                &:nth-child(2) {
                    display: flex; } } }
        .inner {
            display: flex;
            padding: 4px 2px;
            border-radius: 20px;
            background: $n3;
            @include dark {
                background: $n8; } }
        .box {
            display: flex;
            height: 32px;
            border-radius: 16px;
            font-size: 15px;
            svg {
                margin-right: 8px; } }
        .theme_text {
            display: block; } } }

.theme_text {
    display: none; }

.input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    &:checked + .inner {
        background: none;
        .box {
            &:first-child {
                background: none;
                box-shadow: none;
                color: $n4;
                display: none;
                @include d {
 }                    //display: none
                @include m {
                    display: flex; }
                svg {
                    fill: $n4; }
                &:hover {
                    color: $n1;
                    svg {
                        fill: $n1; } } }
            &:nth-child(2) {
                box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.49), inset 0px 2px 1px rgba(255, 255, 255, 0.06);
                background: $n7;
                display: flex;
                @include d {
                    //box-shadow: none
 }                    //background: none

                @include m {
                    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.49), inset 0px 2px 1px rgba(255, 255, 255, 0.06);
                    background: $n6; }
                color: $n1;
                svg {
                    fill: $n1; }
                @include d {
                    display: flex; } } } } }


.inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    //padding: 4px 2px
    //border-radius: 20px
    transition: background .2s;
    //background: $n2
    background: none;
    //display: block
    padding: 2px;
    border-radius: 50%;
    @include t;

    @include d {
        //background: none
        display: block;
        padding: 2px;
        border-radius: 50%; }
    @include m {
        display: flex;
        padding: 4px 2px;
        border-radius: 20px;
        background: $n2; } }

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 calc(50% - 4px);
    height: 32px;
    margin: 2px;
    border-radius: 16px;
    font-size: 15px;
    transition: background .2s, box-shadow .2s, color .2s;
    @include d {
        height: 40px;
        border-radius: 50%;
        font-size: 0; }
    @include m {
        height: 32px;
        border-radius: 16px;
        font-size: 15px; }
    svg {
        //margin-right: 8px
        transition: fill .2s;
        @include d {
            margin-right: 0; }
        @include m {
            margin-right: 8px; } }
    &:first-child {
        box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
        background: $n1;
        @include d {
            //box-shadow: none
 }            //background: none

        @include m {
            box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
            background: $n1; } }
    &:nth-child(2) {
        color: $n4;
        display: none;
        @include d {
 }            //display: none
        @include m {
            display: flex; }
        svg {
            fill: $n4; } }
    &:hover {
        color: $n7;
        svg {
            fill: $n7; } } }



