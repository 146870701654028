@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px; }

.btns {
  flex-wrap: wrap;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center; }

.cancel {
  box-shadow: none;
  background-color: transparent;
  border: 2px solid #ff0000;
  color: $n8;
  transition: .2s ease-in-out;
  @include dark {
    color: $n1;
    box-shadow: none;
    &:hover {
      box-shadow: none; } }
  &:hover {
    box-shadow: none;
    background-color: #ff0000;
    color: $n1; } }
