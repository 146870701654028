@import "styles/helpers";

.modal {
  max-width: 700px; }

.container {
  @include element-flex(column, flex-start, center);
  gap: 24px; }

.title {
  @include title1-s;
  color: $n8;
  @include dark {
    color: $n1; } }

.text {
  width: 100%;
  @include body1-m;
  text-align: justify;
  color: $n6;
  @include dark {
    color: $n3; } }

.imgContainer {
  width: 100%;

  img {
    width: 100%;
    object-fit: contain;
    border-radius: 12px; } }
