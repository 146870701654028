@import "styles/helpers";

.card {
  min-height: 180px;
  @include m {
    min-height: auto; } }

.container {
  @include element-flex(column, flex-start, stretch);
  gap: 32px;
  width: 100%;
  position: relative; }

.list {
  display: flex;
  flex-wrap: nowrap;
  gap: 24px; }

.info {
  width: 100%;
  padding-bottom: 30px;
  text-align: center;
  @include info; }

.item {
  min-width: 250px;
  width: 250px;
  max-width: 250px;
  padding: 0 !important; }
