@import "styles/helpers";

.container {
  display: flex;
  align-items: center; }

.avatar {
  position: relative;
  flex-shrink: 0;
  width: 96px;
  height: 96px;
  margin-right: 32px;
  @include m {
    width: 80px;
    height: 80px;
    margin-right: 16px; }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%; } }

.file {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  overflow: hidden;
  @include m {
    margin-right: 0; }
  input {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 160px;
    opacity: 0; }
  .button {
    cursor: pointer;
    @include m {
      padding: 0 10px; } }
  input {
    height: 100%;
    cursor: pointer;
    &:hover + .button {
      background-color: darken($p1, 10);
      cursor: pointer; } } }

.remove {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: $n1;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  font-size: 0;
  @include m {
    display: inline-block; } }

.button_remove {
  @include m {
    display: none; } }
