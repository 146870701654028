@import "styles/helpers";

.container {
  z-index: 9998;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 15px;
  border-top: 1px solid $p1;
  background-color: $n3;
  @include dark {
    background-color: $n8; } }

.content {
  font-size: 12px;
  font-weight: 500;
  color: $n4;
  @include dark {
    color: $n4; }
  * {
    text-decoration: underline;
    color: $n6;
    @include dark {
      color: $n3; } } }

.button {
  height: 36px;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 500; }
