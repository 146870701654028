@import "styles/helpers";

.content {
  word-break: break-word;
  font-weight: 500;
  font-size: 15px;
  strong {
    font-weight: bold; }
  border-radius: $default-radius;
  background: $n2;
  padding: 20px 30px;
  color: $n6;
  overflow-y: auto;
  @include dark {
    background: rgba(255, 255, 255, .03);
    color: #c9c9c9;
    strong {
      color: $n2; } }

  video {
    width: 100% !important; }

  p {
    margin: 5px 0; }

  ul, ol {
    margin: 10px 17px;
    padding-left: 20px; }

  ul {
    list-style: disc; }

  ol {
    list-style: number; }

  h1, h2, h3, h4, h5, h6 {
    color: $n6;
    margin-top: 15px;
    margin-bottom: 5px;
    @include dark {
      color: whitesmoke; } }

  h1 {
    font-size: 2.2em; }
  h2 {
    font-size: 1.8em; }
  h3 {
    font-size: 1.4em; }
  h4,
  h5,
  h6 {
    font-size: 1em; }

  img {
    border-radius: $default-radius;
    margin: 10px auto;
    max-width: 100%;
    height: auto;
    vertical-align: middle; }

  hr {
    border: none;
    width: 100%;
    height: 2px;
    background: $n4;
    margin: 30px 0; }

  table {
    margin: 25px 0;
    border: 2px solid #d4d6d9;
    @include dark {
      border: 2px solid $n5; }

    border-collapse: collapse;
    font-size: 0.9em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
    @include dark {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12); }

    th,
    td {
      padding: 12px 15px; }

    tbody tr:nth-of-type(even) {
      background-color: $n3;
      @include dark {
        background-color: rgba(255, 255, 255, 0.03); } } }

  pre {
    margin: 10px 0;
    code {
      font-size: .9em; } }

  p > code {
    border-radius: 3px;
    padding: 2px 6px;
    background-color: hsl(0, 0%, 93%);
    @include dark {
      background-color: hsl(210, 7%, 17%); } } }
