@import "styles/helpers";

.image {
    flex-shrink: 0;
    width: 200px;
    aspect-ratio: 5 / 4;
    //margin-right: 12px
    overflow: hidden;
    border-radius: $default-radius;
    @include m {
        width: 100%;
 }        //aspect-ratio: initial
    img {
        //object-fit: cover
        width: 100%;
        height: 100%;
        border-radius: $default-radius; } }

 :global {
    .mobile {
         :local {
            .image {
                width: 100%;
 } } } }                //aspect-ratio: initial
