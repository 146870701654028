@import "styles/helpers";

.container {
  width: 100%;
  @include element-flex(column, flex-start, center);
  gap: 16px;
  background: $n2;
  padding: 24px;
  border-radius: 12px;

  @include dark {
    background: $n6; }

  @include m {
    align-items: flex-start;
    padding: 0; } }

.label {
  @include body1-s; }

.text {
  @include body2-s;
  color: $n4; }

.wrapper {
  @include element-flex(row, flex-start, center);
  gap: 16px;
  @include m {
    @include element-flex(column); } }

.answers {
  @include element-flex(row, flex-start, center);
  gap: 36px;
  @include m {
    .text {
      display: none; } } }

.radioInput {
  @include element-flex(row-reverse, center, center);
  gap: 12px;

  label {
    display: none;
    @include body2-s;
    font-weight: 400;

    @include m {
      display: block; } } }
