@import "styles/helpers";

.card {
  padding: 0px; }

.container {
  width: 100%; }

.list {
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  // overflow-y: auto
  // &::-webkit-scrollbar
  //   width: 8px
  //   height: 8px
  // /* Track */
  // &::-webkit-scrollbar-track
  //   border-radius: 10px
  //   background: $n1
  //   +dark
  //     background: $n7
  // /* Handle */
  // &::-webkit-scrollbar-thumb
  //   border-radius: 10px
  //   background: $n3
  //   +dark
 }  //     background: $n8

.related_job {
  min-width: 300px;
  width: 300px;
  max-width: 300px;
  padding: 0 !important;
  & > * {
    height: 100% !important; } }

.item, .related_job {}


.item {
  height: 100% !important;
  padding: 10px 15px !important;
  background-color: $n6; }

.info {
  font-size: 14px;
  color: $n4;
  font-style: italic;
  text-align: center;
  margin-bottom: 32px; }
