@import "styles/helpers";


.skeleton, .skeleton_user {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid $n3;
    margin-bottom: 1rem;
    @include dark {
        border: 1px solid $n6; }
    span {
        @include dark {
            --base-color: #313131;
            --highlight-color: #525252; } } }
.img {
    margin-left: 16px;
    margin-right: 16px; }


.content {
    display: grid;
    grid-template-columns: 256px 110px 110px 200px;
    gap: 16px; }

// ---------SKELETON-USER-------
.skeleton_user {
    flex-direction: column;
    padding: 0.5rem;
    width: 100px !important;
    justify-content: center;
    align-items: center;
    .img {
        margin-right: 0;
        margin-bottom: 5px; }
    .content {
        height: 24px;
        width: 100%; } }

