@import "styles/helpers";

.card {
    position: relative;
    padding: 24px;
    background: $n1;
    border-radius: 10px;
    @include m {
        padding: 16px; }
    @include dark {
        background: $n7; } }

.head {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 32px;
    @include m {
        display: block;
        height: 100px;
        margin-bottom: 24px; } }

.title {
    width: fit-content;
    margin-right: auto;
    @include m {
        line-height: 28px;
        margin-bottom: 20px; } }

.form {
    margin-top: 10px;
    width: 320px;
    input {
        height: 50px;
        padding-left: 64px; } }

.formIcon {
    padding-left: 20px; }

.list {
    @include element-flex(column);
    gap: 8px; }

.info {
    font-size: 14px;
    margin-bottom: 16px;
    color: $n4;
    font-style: italic;
    text-align: center; }

.grid {
    display: grid;
    grid-template-columns: 80% 20%;
    grid-template-rows: 1fr; }

.tree {}

.dropdown {
    display: flex;
    align-items: center; }
