@import "styles/helpers";

.author {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-shrink: 0;
    //border: 1px solid $n3
    border-radius: $default-radius;
    padding: 6px 2px;
    width: fit-content;
    color: $n7;
    background: $n;
    //margin-right: 10px
    //margin-bottom: 10px
    text-decoration: none;
    &:hover {
        background-color: $n3; }
    @include dark {
        background-color: $n7;
        color: $n1;
        //border: 1px solid $p1
        &:hover {
            background-color: $n6; } }
    @include m {
 } }        //margin-bottom: 10px

.authorRss:hover {
    background-color: $n;
    @include dark {
        background-color: $n7; } }

.author_image {
    flex-shrink: 0;
    height: 25px;
    width: 25px;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 50%; } }

.levelplaceholder {}

.level {
    border-radius: $default-radius / 2;
    padding: 0 5px;
    background: $p1;
    //position: absolute
    //top: 100%
    color: white;
    font-size: 10px; }
