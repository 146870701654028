@import "styles/helpers";

.item {
  @include element-flex(row, flex-start, center);
  gap: 16px;
  min-height: 57px;
  @include body1-s;
  padding: 8px 0;
  border-bottom: 1px solid $n3;
  @include dark {
    border-color: $n6; }

  &:first-child {
    padding-top: 0; }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0; }

  @include m {
    @include element-flex(column);
    gap: 4px;
    padding: 24px 0; } }

.date {
  color: $n4; }

.type {
  color: $n8;
  @include dark {
    color: $n1; } }

.buttons {
  margin-left: auto;
  @include m {
    margin-left: 0;
    margin-top: 8px; } }

.resultButton {
  padding: 8px 12px;
  border-radius: 6px;
  height: auto; }
