@import "styles/helpers";

.tags {
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 2px;
    //width: 100%
    // overflow-y: hidden
    // overflow-x: scroll
    // -webkit-overflow-scrolling: touch
    // -ms-overflow-style: none
    // scrollbar-width: none
    // touch-action: auto
    // &::-webkit-scrollbar
 }    //     display: none

.item {
    padding: 6px 8px;
    font-size: 13px; }

.tag {
    flex-shrink: 0;
    white-space: nowrap;
    width: fit-content;
    height: fit-content;
    padding: 6px 8px;
    border-radius: $default-radius;
    color: $n7;
    font-size: 13px;
    text-transform: uppercase;
    transition: .2s ease-in-out; }

.scroll_controls {
    padding: 0 !important; }
