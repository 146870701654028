@import "styles/helpers";

$title-size: 24px;
$content-size: 14px;
$badge-size: 13px;

article {
  width: 100%; }

.item {
  position: relative;
  display: flex;
  gap: 24px;
  width: 100%;
  margin-bottom: $cards-margin;
  border-radius: $default-radius;
  padding: 25px;
  background-color: $n1;
  @include dark {
    border-color: $n6;
    background-color: $n7; } }

.head {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 78px; }

.author {
  flex-shrink: 0;
  color: $n4;
  &.one_line {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; } }

.menu {
  cursor: pointer;
  // position: absolute
  // top: 0px
  // right: 0px
  svg {
    //transform: rotate(90deg)
    path {
      fill: $n4; } } }

.settings {
  border-radius: 10px;
  padding: 1px 3px;
  width: auto;
  height: auto;
  background-color: $n1;
  @include dark {
    background-color: $n7; }
  &:hover {
    background-color: $n3;
    @include dark {
      background-color: $n6; } } }

.imagecontainer {
  position: relative;
  margin-right: 24px; }
.views {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: transparentize($n4, 0.3);
  padding: 5px 10px;
  color: $n1;
  transition: .2s ease-in-out;
  @include base2;
  svg {
    margin-right: 8px;
    fill: $n1; } }

.image_wrapper {
  background-color: $n;
  border: 1px solid $n3;
  min-height: 150px;
  //padding: 12px
  display: flex;
  align-items: center;
  justify-content: center;
  @include dark {
    border: none; }
  img {
    border-radius: 0;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%; }
  &.no_padding {
 } }    //padding: 0

.fixed_image_wrapper {
  height: 150px;
  align-items: center; }

// .image
//   flex-shrink: 0
//   width: 25%
//   height: 100%
//   margin-right: 16px
//   overflow: hidden
//   min-width: 200px
//   max-width: 200px
//   border-radius: 10px
//   img
//     width: 100%
//     height: 100%
//     object-fit: contain
//     border-radius: 10px
//     +m
//       max-height: 100px
//   +x
//     max-width: 200px
//     max-height: 150px
//   +m
//     width: 100%
//     max-width: 100%
//     max-height: 100px
//     margin-bottom: 20px

section {
  display: flex;
  flex-direction: column;
  gap: 4px; }

.details {
  width: 100%;
  //overflow: hidden
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
  overflow: hidden;
  .row {
    display: flex;
    justify-content: space-between;
    gap: 24px; }

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    //margin-top: 8px
    margin-top: 2px;
    color: $n8;
    @include dark {
      color: $n1; } }

  .company_name {
    font-size: $content-size;
    font-weight: 600;
    color: $n5;
    @include dark {
      color: $n4; } }
  .salary {
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: center;
    text-align: left;
    font-size: $content-size;
    font-weight: 600;
    min-width: fit-content;
    color: $n6;
    @include dark {
      color: $n3; }
    p {
      font-weight: 600;
      color: $n5;
      &::first-letter {
        text-transform: uppercase; }
      @include dark {
        color: $n4; } } }

  .seniority, .localization_info {
    font-size: $content-size;
    font-weight: 600;
    color: $n5;
    @include dark {
      color: $n4; } }

  .seniority {
    text-transform: capitalize; }

  .locations {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: $content-size;
    font-weight: 600;
    color: $n5;
    @include dark {
      color: $n4; }
    .extend_locations {
      cursor: pointer;
      padding: 2px 5px;
      border-radius: 10px;
      transition: .2s ease-in-out;
      &:hover {
        background-color: $n3;
        @include dark {
          background-color: $n6; } }
      .rotate180 {
        transform: rotate(-180deg); }
      svg {
        transition: .2s ease-in-out;
        path {
          fill: $n5;
          @include dark {
            fill: $n4; } } } } }
  .date {
    width: fit-content;
    flex-shrink: 0;
    margin-top: 6px;
    color: $n4;
    font-size: $content-size;
    .new_badge {
      padding: 4px 8px;
      background-color: $s5;
      color: $n6;
      font-size: $badge-size;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 10px; } }

  .detail_tags {
    display: flex;
    align-items: center;
    gap: 4px; }

  .format {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $badge-size;
    color: $n6;
    text-transform: uppercase;
    white-space: nowrap;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 10px;
    background-color: $s2; }

  .tags {
    //width: 100%
    //width: fit-content
    //flex-shrink: 1
    //margin-left: auto
    // +m
    //   margin: 6px 0
    // .tag
    //   font-size: $badge-size
    //   color: $n7
    //   white-space: nowrap
    //   text-transform: uppercase
    //   width: fit-content
    //   padding: 6px 12px
    //   border-radius: 10px
    //   border: 1px solid $n4
    //   +dark
    //     border: 2px solid $n4
 } }    //     color: $n2

.show_locations {
  display: none;
  z-index: 10;
  //position: absolute
  left: 0;
  bottom: 10px;
  //transform: translateY(100%)
  width: 100%;
  background-color: $n1;
  border-radius: 10px;
  @include dark {
    background-color: $n7; }
  &.active {
    display: block; }
  ul {
    li {
      padding: 8px 25px;
      cursor: pointer;
      color: $n7;
      border-radius: 10px;
      @include dark {
        color: $n2; }
      &:hover {
        background-color: $n3;
        @include dark {
          background-color: $n6; } } } } }

.actions {
  position: absolute;
  top: 25px;
  right: 25px;
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: $n1;
  padding: 0;
  border-radius: $default-radius;
  flex-shrink: 0;
  gap: 8px;
  @include dark {
    background-color: $n7; }
  .pin_btn {
    cursor: pointer;
    img {
      height: 20px;
      transition: .2s ease-in-out; }
    &:hover {
      img {
        filter: brightness(80%);
        @include dark {
          filter: brightness(120%); } } } } }

 :global {

  .mobile {
     :local {
      .item {
        flex-direction: column;
        gap: 12px; }

      .image {
        margin-right: 0;
        > div {
          width: 100%; } }

      .image_wrapper {
        aspect-ratio: auto;
 }        //padding: 24px

      .head {
        padding-right: 0;
        flex-direction: column;
        align-items: flex-start; }

      .title {
        -webkit-line-clamp: 1;
        font-size: 20px;
        margin-top: 8px; }

      .details {
        gap: 8px;
        .row {
          flex-direction: column;
          gap: 12px;
          &.mobile_row {
            flex-direction: row; } }

        .salary {
          align-self: flex-start; }
        .date {
          .new_badge {
            position: absolute;
            top: 12px;
            right: 12px; } }
        .format {
          margin-top: 0;
          margin-bottom: 2px; } }

      .foot {
        margin-top: 8px; }

      .actions {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        border-radius: 0 $default-radius 0 $default-radius;
        background-color: $n1;
        @include dark {
          background-color: $n7; } } } } }
