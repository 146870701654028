@import "../../styles/helpers";


.Breadcrumb {
    margin-left: 2rem;
    color: $n8;
    @include dark {
      color: $n; }

    a {
      cursor: pointer;
      color: $n4;
      font-size: 20px;
      font-weight: 600;

      &:hover {
        text-decoration: none; } }

    .JZWIT {
        color: $n8;
        cursor: pointer;
        transition: 150ms;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.6;
        letter-spacing: -.02em;
        font-family: 'Inter', sans-serif;
        @include dark {
          color: $n; }
        .JZWITIcon {
            fill: $n8;
            margin-right: 5px;
            transition: 150ms;
            @include dark {
              fill: $n; } }

        // &:hover
        //   color: $n
        //   text-decoration: none
        //   .JZWITIcon
 } }        //     fill: $n

.last {
    color: #9659e1;
    font-size: 20px !important;
    font-weight: 600 !important; }








