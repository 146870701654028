@import "styles/helpers";
$color-error-msg: #cf0000;

.row {
    display: flex;
    gap: 16px;
    margin-top: 16px;
    @include m {
        display: block;
        margin: 0; }
    .field {
        flex: 0 0 calc(50% - 8px);
        width: calc(50% - 8px);
        @include m {
            width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 24px; } } } }

.button {
    margin-left: 16px;
    @include m {
        width: 100%; } }

.error {
    position: relative;
    padding: 12px;
    padding-left: 48px;
    margin-bottom: 24px;
    color: $color-error-msg;
    border: 1px solid $color-error-msg;
    border-radius: 12px;
    width: 100%;

    svg {
        position: absolute;
        top: 50%;
        left: 24px;
        transform: translate(-50%, -50%);
        fill: $color-error-msg; } }

.password {
    @mixin title2 {} }

.password_container {
    margin-left: 16px; }

.password_tip {
    margin-top: 4px;
    color: $n4;
    font-size: 14px;
    margin-bottom: 16px; }

.divider {
    margin: 16px 0px;
    border-bottom: 1px solid $n3;
    @include dark {
        border-color: $n5; } }

.dangerZone {}

.dangerTitle {
    @mixin title2 {} }

 :global {}
.mobile {
     :local {

        .row {
            display: block;
            margin: 0;
            margin-bottom: 24px;

            .field {
                width: 100%;
                margin: 0;
                &:not(:last-child) {
                    margin-bottom: 24px; } }

            .button {
                width: 100%; } } } }
