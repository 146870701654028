@import "styles/helpers";

.chartContainer {
  display: flex;
  flex-direction: column;
  gap: 40px; }

.chartTitle {
  @include title1-s;
  margin-left: 8.2%;
  margin-top: 30px; }

.globalChart {
  border-radius: $default-radius;
  @include dark {
    background-color: rgba(255, 255, 255, .02); } }

.dropdown {
  margin-bottom: 20px; }
