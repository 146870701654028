@import "styles/helpers";

.panel {
    position: relative;
    display: flex;
    align-items: center;
    padding: 30px 20px 24px;
    border-radius: 10px 10px 0 0;
    margin-top: auto;
    margin-bottom: -40px;
    background: $n1;
    box-shadow: inset 1px 0px 0px $n2;
    @include dark {
        background: $n7;
        box-shadow: inset 1px 0px 0px $n8; }
    @include s {
        display: none; } }

.info {
    margin-right: auto;
    @include caption1;
    color: $n4;
    svg {
        margin-right: 12px;
        margin-left: 20px;
        fill: $n4; } }

.info--modal {
    margin: 6px 0 20px;
    svg {
        margin-left: unset; } }

.disabled,
.disabled:hover,
.disabled:active, {
    cursor: default;
    background: $n3;
    color: $n4;
    opacity: .7;
    border: none;
    box-shadow: none !important;
    @include dark {
        opacity: .5;
        color: inherit;
        background: $n5; } }

.disabled:hover {
    & svg {
        fill: #FF6A55; } }

.button {
    &:not(:last-child) {
        margin-right: 8px; } }

.modalWarning {
    font-size: 24px;
    // color: #ff0000
    color: $p3; }

.modalActiveTitles {
    max-height: 12em;
    overflow-y: auto;
    margin-bottom: 20px; }

.modalActiveTitle {
    position: relative;
    margin-left: 10px;
    margin-bottom: 8px;
    width: 80%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &::before {
        content: '';
        display: block;
        float: left;
        background: $p1;
        border-radius: 100rem;
        width: 7px;
        height: 7px;
        margin-right: 10px;
        margin-top: 9px; } }

.modalActiveTitleWarn {
    &::before {
        background: $p3; } }
