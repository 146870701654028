@import "styles/helpers";

.trend {
  display: flex;
  flex-direction: column; }

.content_title {
  font-size: 1.8rem;
  margin-bottom: 12px; }

.subtitle {
  font-size: 1rem; }

.head {
    @include m {
        display: block; } }

.title {
    @include m {
        margin-bottom: 16px; } }

.chart {
    width: 100%;
    height: auto; }

.chartAndTrends {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 1.8rem;

    .content {
        margin-top: 2rem;
        padding: 0;
        p {
            margin: 0; } } }

.empty {
  margin-bottom: 12px;
  @include body1-s;
  color: $n4;
  text-align: center; }
