@import "styles/helpers";

.container {
  @include element-flex(column, flex-start, stretch);
  gap: $cards-margin;
  width: 100%; }

.info {
  width: 100%;
  padding: 30px 0;
  text-align: center;
  color: $n4;
  font-weight: lighter;
  font-style: italic; }
