@import "styles/helpers";

.container {
    @include element-flex(column, space-between);
    gap: 32px;
    width: 100%; }

.field {
    width: 100%;
    input {
        width: 100%; } }
