@import "styles/helpers";

.button {
    position: relative;
    height: 40px;
    padding: 0 24px;
    width: fit-content;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    color: $n7;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    user-select: none;
    border: solid 2px #EFEFEF;
    @include m {
        padding: 0 8px; }
    &:hover {
        background: #2a85ff;
        color: white; }
    @include dark {
        border: solid 2px #272B30;
        background: none;
        color: $n4;
        &:hover {
            background: #2a85ff;
            color: white; } } }

.filters {
    position: relative;
    &.active {
        .body,
        .overlay {
            visibility: visible;
            opacity: 1; } } }

.body {
    position: absolute;
    color: black;
    top: -24px;
    right: 0;
    z-index: 1000;
    min-width: 600px;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n1;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    @include m {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: auto;
        overflow: auto; }
    @include dark {
        background: $n7;
        color: white; } }

.top {
    display: flex;
    align-items: center;
    margin-bottom: 24px; }

.title {
    margin-right: auto;
    margin-bottom: 24px; }

.close {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: $n3;
    margin-left: 24px;
    svg {
        fill: $n7;
        transition: transform .2s; }
    @include dark {
        background: $n6;
        svg {
            fill: $n1; } }
    &:hover {
        svg {
            transform: rotate(90deg); } } }

.content {
    width: 100%;
    height: fit-content;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: 0.8rem;
    scrollbar-color: transparent transparent;
    &::-webkit-scrollbar {
        visibility: hidden;
        width: 0.8rem;
        height: 0.8rem; }
    // @media (min-width: 1000px)
 }    //     max-height: calc(100vh - 400px)

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba($n2, .8);
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: rgba($n6, .9); } }

.roadmap_container {
    padding: 0; }

.modal_container {
    max-width: 600px;
    @include m {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: auto;
        overflow: auto; } }
