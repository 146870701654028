@import "../../../styles/helpers";
// .section
//     padding: 1.5rem;
//     background-color: $n
//     border-radius: 12px
//     display: flex
//     flex-direction: column
//     gap: 2rem

//     +dark
//         background-color: $n7





// .sc_title
//     position: relative
//     display: flex
//     justify-content: space-between
//     flex-direction: row
//     align-items: center
//     &::before
//         content: "";
//         position: absolute;
//         top: 50%;
//         left: 0;
//         width: 16px;
//         height: 32px;
//         transform: translateY(-50%);
//         border-radius: 4px;
//         background-color: #9659e1
//     h4
//         margin-left: 2rem
//         font-size: 20px;
//         font-weight: 600;
//         line-height: 1.6;
//         letter-spacing: -.02em;

.form {
    display: flex;
    flex-direction: column;
    gap: 12px; }
.btn_remove {
    background-color: $n3;
    color: $n5;
    transition: 250ms;
    padding: 0.7rem 1rem;
    border-radius: 12px;
    &:hover {
        background-color: #ff4d4d;
        color: $n1; }
    @include dark {
        background-color: $n5;
        color: $n4;
        &:hover {
            background-color: #ff4d4d;
            color: $n1; } } }


.btn_add {
    background-color: #9659e1;
    // padding: 1rem
    border-radius: 50px;
    color: transparent;
    width: 60px;
    height: 60px;
    margin: 1rem auto;
    font-weight: 700;
    font-size: 0.01rem;
    transition: 250ms;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before, &::after {
        content: "";
        position: absolute;
        width: 5px;
        height: 32px;
        border-radius: 4px;
        background-color: white;
        transition: 250ms; }
    &::after {
      transform: rotate(90deg); }
    &:hover {
        width: 200px;
        color: $n;
        font-size: 1rem;
        border-radius: 12px;
        &::before, &::after {
          background-color: transparent; } } }


// .btn_ce
//     background-color: $n3;
//     color: $n5
//     transition: 250ms
//     padding: 0.7rem 1rem
//     border-radius: 12px
//     +dark
//         background-color: $n5
//         color: $n4
//     &:hover
//         background-color: #4d4dff
//         color: $n1

.group_btn {
    display: flex;
    gap: 1rem; }









