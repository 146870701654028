@import "styles/helpers";
@import "styles/common";

.row {
  transition: background .2s;
  display: contents;

  // +t
  //   display: flex
  //   flex-wrap: wrap
 }  //   margin: 20px auto

.divider {
  width: 98%;
  margin: 0 auto;
  grid-column: 1/ -1;
  height: 1px;
  background: $n3;
  @include dark {
    background: $n6; }
  @include m {
    width: 94%; } }

// <-- these are for moving the part
//  when screen is getting smaller and
// content doesn't fit -->
.mobile-wrapper {
  display: contents;
  // +t
  //   display: flex
  //   flex-basis: 100%
 }  //   max-width: 100%

.laptop-wrapper {
  display: contents;
  // +w
  //   display: flex
  //   gap: 3px
  //   flex-direction: column
  //   justify-content: center
  // +t
  //   justify-content: start
  //   display: contents

  & .col {
    display: flex;
    // +w
    //   padding: unset
    //   align-self: unset
    //   margin-left: 10%
    // +t
    //   padding: 16px 12px
 } }    //   margin-left: unset

.mobile-details {
  // +t
  //   margin-left: 54px
  //   display: grid
  //   grid-template-columns: minmax(110px, 130px) minmax(110px, 150px) minmax(90px, 150px) minmax(160px, 160px) minmax(100px, 100px)

  // +r(690)
  //   grid-template-columns: minmax(110px, 160px) minmax(110px, 200px) minmax(100px, 200px)

  @include s {
    margin-left: 4px !important; }

  & .col {
    position: relative;
    // +t
    //   align-self: unset
    //   padding: 7px 10px
 } }    //   flex-direction: column

.ghost {
  display: contents; }

// <-- basically in every grid cell -->
.col {
  padding: 16px 12px;
  color: $n7;
  align-self: center;
  @include body1-s;
  @include dark {
    color: $n1; }
  @include s {
    padding: 10px 6px; } }

// <-- stuff in each row -->
.checkbox {
  display: flex;
  // +w
  //   align-self: center
  @include s {
    display: none; } }

.product {
  max-width: 460px;
  word-break: break-word;
  display: flex;
  min-width: 0;

  &.col:hover .product-name {
    color: $p1; }

  &-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 0; }

  .details {
    display: flex;
    flex-direction: column;
    gap: 3px;
    overflow: hidden; }

  &-name {
    transition: color .2s ease-in-out;
    // 3 dots
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; } }

.status {
  font-weight: 700;
  &-color {
    background: $n3;
    display: inline-block;
    padding: 4px 8px;
    border-radius: 6px;
    @include dark {
      background: $n5; }
    &.published {
      background: $s4;
      @include dark {
        background: #367a3f; } }
    &.scheduled {
      background: $s5;
      @include dark {
        background: #b2701f; } }
    &.draft {
      background: $n3;
      @include dark {
        background: $n5; } } }

  &.col {
    // +r(1550)
 }    //   display: none

  &.moved {
    @include caption2;
    display: none;
    // +r(1550)
 } }    //   display: block

.type {
  // +t
  //   width: 110px
  // & .post,
  // & .course,
  // & .job,
  //   padding: 4px 8px
  //   border-radius: 6px

  // & .post
 }  //   background: blue

.reactions {
  display: flex;
  justify-content: space-around;
  // +w
  //   flex-direction: column
  //   gap: 3px
  //   +caption2
  // +t
 }  //   +body1-s

.star {
  filter: $star-filter;
  // +w
  //   width: 16px
  // +t
  //   width: unset
  &--inactive {
    filter: unset; } }

.rating {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;

  // +w
  //   +caption2

  // +t
  //   +body1-s
  //   align-items: flex-start
  //   grid-column: 5/ 6
  //   grid-row: 1

  // +r(690)
  //   grid-column: 1/ 2
 }  //   grid-row: 2

.thumbnail {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  margin-right: 20px;
  border-radius: 8px;
  overflow: hidden;
  @include s {
    margin-right: 14px; }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; } }

.date {
  display: flex;
  align-items: center;
  gap: 4px;
  color: $shades2;
  @include base2;
  @include dark {
    color: $shades1; }
  & > svg {
    filter: invert(48%) sepia(4%) saturate(802%) hue-rotate(172deg) brightness(95%) contrast(89%); } }

.label {
  display: none;
  color: $n4;
  @include caption1;
  @include dark {
    color: $shades1; }
  // +t
 }  //   display: block

.bar {
  width: 100%;
  height: 8px;
  background: $n3;
  border-radius: 2px;
  overflow: hidden;
  @include dark {
    background: $n5; } }

.progress {
  height: 100%;
  width: 50%; // change later
  background: $p1;
  border-radius: 2px;
  &--violet {
    background: $p4; } }

//testing
.icon-in-row {
  display: contents;
  // +t
  //   display: flex
  //   flex-direction: row
  //   gap: 8px
  //   & > .star
  //     margin-left: -2px
  //   & > .thumb:first-of-type
 }  //     margin-right: 8px

.thumb {
  display: flex;
  flex-direction: column;
  align-items: center;
  // +w
  //   flex-direction: row
  //   gap: 6px
  & > img {
    width: 20px;
    // +w
    //   width: 16px
    // +t
 } }    //   width: 20px

.NA {
  color: $n4; }

.actions {
  display: flex;
  justify-content: flex-end;
  // +t
  //   margin-left: auto
  &--mobile {
    display: none;
    // +t
 }    // display: flex
  &--desktop {
    display: flex;
    // +t
 } }    // display: none

.actionsHead {
  background: $n1;
  @include dark {
    background: $n7; }
  &:hover {
    background: $n2;
    @include dark {
      background: $n6; } } }

/* -------------------------------------------------------------------------- */
/*                            CONTAINER RESPONSIVE                            */
/* -------------------------------------------------------------------------- */

 :global {

  .r-lg { //+r(1550)
     :local {
      .status.col {
        display: none; }
      .status.moved {
        display: block; } } }

  .r-md { //+w
     :local {
      .laptop-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3px;
        & .col {
          padding: unset;
          align-self: unset;
          margin-left: 10%; } }
      .checkbox {
        align-self: center; }
      .reactions {
        flex-direction: column;
        gap: 3px;
        @include caption2; }
      .star {
        width: 16px; }
      .rating {
        @include caption2; }
      .thumb {
        flex-direction: row;
        gap: 6px;
        & > img {
          width: 16px; } } } }

  .r-sm { //+t
     :local {
      .row {
        display: flex;
        flex-wrap: wrap;
        margin: 20px auto; }
      .mobile-wrapper {
        display: flex;
        flex-basis: 100%;
        max-width: 100%; }
      .laptop-wrapper {
        justify-content: start;
        display: contents;
        & .col {
          padding: 16px 12px;
          margin-left: unset; } }
      .mobile-details {
        margin-left: 54px;
        margin-right: 20px;
        display: grid;
        // grid-template-columns: minmax(110px, 130px) minmax(110px, 150px) minmax(90px, 150px) minmax(160px, 160px) minmax(100px, 100px)
        grid-template-columns: minmax(110px, 130px) minmax(110px, 1fr) minmax(90px, 1fr) minmax(160px, 160px) minmax(100px, 100px);
        & .col {
          align-self: unset;
          padding: 7px 10px;
          flex-direction: column; } }
      .type {
        width: 110px; }
      .reactions {
        @include body1-s; }
      .star {
        width: unset; }
      .rating {
        @include body1-s;
        align-items: flex-start;
        grid-column: 5/ 6;
        grid-row: 1; }
      .label {
        display: block; }
      .icon-in-row {
        display: flex;
        flex-direction: row;
        gap: 8px;
        & > .star {
          margin-left: -2px; }
        & > .thumb:first-of-type {
          margin-right: 8px; } }
      .thumb > img {
        width: 20px; }
      .actions {
        margin-left: auto;
        &--mobile {
          display: flex; }
        &--desktop {
          display: none; } } } }

  .r-sm-s { //+r(690)
     :local {
      .mobile-details {
        grid-template-columns: minmax(110px, 130px) minmax(110px, 200px) minmax(100px, 200px); }
      .rating {
        grid-column: 1/ 2;
        grid-row: 2; } } } }
