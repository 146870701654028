@import "styles/helpers";

.menu {
  cursor: pointer;
  svg {
    //transform: rotate(90deg)
    path {
      fill: $n4; } } }


.settings {
  border-radius: 10px;
  padding: 3px 3px;
  width: auto;
  height: auto;
  background-color: $n1;
  @include dark {
    background-color: $n7; }
  &:hover {
    background-color: $n3;
    @include dark {
      background-color: $n6; } } }
