@import "styles/helpers";

.card {
  min-height: 180px;
  @include m {
    min-height: auto; } }

.list {
  @include element-flex(column);
  gap: 8px; }

.loader {
  margin: 0 auto 36px; }

.info {
  width: 100%;
  padding-bottom: 30px;
  text-align: center;
  @include info; }
