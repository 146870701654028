@import "styles/helpers";

.card {
  width: 100%;
 }  //min-width: 900px

.container {
  //width: 100%
 }  //overflow-x: auto

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  border-bottom: 1px solid $n4; }

.labels {
  //min-width: 800px
  display: grid;
  align-items: center;
  //grid-template-columns: min-content 302px 110px 110px max-content
  grid-template-columns: 40px 294px 150px 150px 160px 40px;
  gap: 16px; }

.label {
  //padding: 16px 12px
  display: flex;
  align-items: center;
  //text-align: center
  color: $n4;
  @include caption1; }

.checkbox {
  display: flex;
  align-items: center; }

.users_list {
  display: flex;
  flex-direction: column;
  //min-width: 800px
 }  //gap: 12px

.pagination {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center; }

.form {
  display: block;
  @include m {
    display: none; } }

.form_mobile {
  display: none;
  @include m {
    display: block; } }

.info {
  margin: 30px 0;
  font-style: italic;
  text-align: center;
  color: $n4; }

 :global {
  .r-lg { //+r(1550)
     :local {
      .label {
        display: none; }
      .labels {
        display: flex; } } }
  .r-sm { //+r(1550)
     :local {
      .label {
        display: none; }
      .form_mobile {
        width: 100%; } } } }
