@import "styles/helpers";

$title-size: 24px;
$content-size: 14px;
$badge-size: 12px;

.tags, .format {
    display: none; }

.mobile_row_reverse {
    flex-direction: column;
    gap: 12px !important;
    margin-top: 5px; }

 :global {
    .tiny {
         :local {
            .item {
                height: 100%;
                font-size: .8rem;
                min-width: 200px; }
            .bottom {
                margin-bottom: 30px; }
            .salary {
 }                // +caption2
            .item {
                width: unset; }
            .imageNew {
                height: 100px;
                & > div {
                    height: 100%;
 } }                    // object-fit: cover
            .company_name {
                margin-bottom: 10px; } } } }

// NEW RELATED STYLES

.item {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    border-bottom: 1px solid $n3;
    border-radius: 12px;
    width: 300px;
    padding: 1rem;
    background: $n;
    border: 3px solid $n3;
    height: 100%;
    @include dark {
        border-color: $n5;
        background: $n7; } }

.thumbnail {
    flex-shrink: 0;
    height: auto;
    width: 300px;
    margin-bottom: 1rem;
    align-self: center;
    overflow: hidden;
    padding: 0 !important;
    height: 200px;
    border-radius: 20px !important;
    img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover !important;
        border-radius: 12px !important;
        @include m {
            max-height: 190px; } } }

.title {
    font-weight: 700;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

// .actions
//     display: none

.actions {
    display: flex;
    flex-shrink: 0;
    gap: 8px; }
.pin_btn {
    cursor: pointer;
    img {
        height: 20px;
        transition: .2s ease-in-out; }
    &:hover {}
    img {
        filter: brightness(80%);
        @include dark;
        filter: brightness(120%); } }
.actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    border-radius: $default-radius;
    background-color: $n1;
    @include dark {
        background-color: $n7; } }
