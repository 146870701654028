@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: $title-content-margin; }

.info {
  font-style: italic;
  text-align: center;
  color: $n4; }

.empty {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px; }

.list {
  display: flex;
  flex-direction: column;
  gap: $cards-margin; }
