@import "../../../styles/helpers";

$ICON_SIZE: 2rem;
$DEFAULT_PADDING: 0.75rem;

.icon {
    display: inline-block;
    &:symbol {
        width: $ICON_SIZE;
        height: $ICON_SIZE;
        text-align: center;
        line-height: $ICON_SIZE; } }

.group {
    list-style-type: none;
    padding-left: 0;
    border-top: 1px solid #ccc;
    text-align: left;
    width: 100%; }

.item {
    padding: 0.75rem 1rem;
    cursor: pointer;
    background: none;
    border-bottom: 1px solid #ccc;
    box-shadow: none;
    z-index: unset;
    position: relative;

    &:active {
        color: white;
        background: #179ed3;
        border-bottom: none; }

    &:focused {
        box-shadow: 0 0 5px 0 #222;
        z-index: 999; } }
