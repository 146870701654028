@import "styles/helpers";

.container {
  display: flex;
  gap: 12px; }

.option {
  display: flex;
  align-items: center;
  gap: 4px;
  fill: $n4;
  color: $n4;
  text-decoration: none;
  transition: .2s ease-in-out;
  img {
    height: 20px;
    filter: invert(72%) sepia(78%) saturate(386%) hue-rotate(341deg) brightness(120%) contrast(102%); }
  span {
    @include body2-m;
    color: $n4; }
  &:hover {
    fill: $p1;
    cursor: pointer; } }

.rating {}

