@import "styles/helpers";

.card {
  margin-bottom: 30px; }

.head {
  display: flex;
  white-space: nowrap;
  gap: 20px;

  & > .title {
    margin-right: unset;
    margin-bottom: 4px; } }

@media(max-width: 1100px) {
  .head {
    align-items: unset;
    flex-direction: column; }
  .form {
    width: 100%; } }

.buttonPosition {
  margin-top: 4px; }

.form {
  @include s {
    width: 100%; } }

.options {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 0px;
  .box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px; } }

.column {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
  float: right;
  gap: 12px;
  &.sorting {
    margin-right: auto;
    margin-left: 0; } }

.row {
  display: flex;
  width: 100%; }

.dropdownHead, {
  width: max-content; }

.dropdown {
  width: 200px; }

@media(max-width: 600px) {
  .dropdownHead, {
    width: 100px; }
  .dropdown {
    width: 100px;
    inline-size: 90px;
    overflow-wrap: break-word; } }

.info {
  width: 100%;
  text-align: center; }

/* -------------------------------------------------------------------------- */
/*                                 PAGINATION                                 */
/* -------------------------------------------------------------------------- */

.foot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  text-align: center;
  margin-top: 25px; }

.pagination_numbers {
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 10px;
  color: $n7;
  &:hover {
    background-color: $n4; }
  @include dark {
    color: $n2;
    &:hover {
      background-color: $n5; } } }

.table_container {
  display: grid;
  grid-template-columns: 44px 30fr 10fr 10fr 10fr 10fr 10fr 50px;
  grid-gap: 10px;
  padding: 10px;
  font-size: 12px;
  margin-bottom: 10px;
  color: $n4;
  @include dark {
    color: $shades1; } }

.item {
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center; }

.item:nth-child(2) {
  justify-content: start;
  font-size: 15px;
  padding-left: 10px; }

@media(max-width: 1230px) {
  .item {
    display: none; }
  .item:first-child {
    display: flex; }
  .item:nth-child(2) {
    display: flex; } }

.divider {
  height: 1px;
  width: 100%;
  left: 1%;
  right: 0;
  background: $n3;
  @include dark {
    background: $n6;
    // +t
    //     top: 80px
    @include m {
      width: 100%; } } }
