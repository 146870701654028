@import "styles/helpers";

.container {
  position: relative;
  width: 100%; }

.btnban {
  background: $p3;
  &:hover {
    background: #fd8575; } }
.btnban, .btnunban {
  margin: 5px 0 10px 0; }
.banned {
  color: $p3; }

.list {
  position: relative;
  width: 400px;
  .item {
    width: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: $n2;
    padding: 5px 15px;
    border-radius: 0 10px 10px 0;
    border-left: 3px solid $n3;
    margin: 10px 0;
    .reason {
      font-style: italic; }
    @include dark {
      background: $n6;
      border-color: $n5; } }
  @include m {
    width: 100%; } }
