@import "../../styles/helpers";


.add {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 48px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $n3;
    transform: translateY(-50%);
    font-size: 0;
    transition: border-color .2s;
    @include d {
        display: none; }
    @include m {
        display: flex; }
    @include dark {
        border-color: $n5; }
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        border-color: $n7;
        svg {
            fill: $n7; }
        @include dark {
            border-color: $shades1;
            svg {
                fill: $shades1; } } }
    &.active {
        border-color: $shades1;
        svg {
            fill: $shades1; } } }

.counter {
    flex-shrink: 0;
    min-width: 24px;
    margin-left: auto;
    border-radius: 6px;
    text-align: center;
    line-height: 24px;
    color: $n7; }

.groupButtons {
    display: flex;
    width: 100%;
    gap: 0.5rem; }



.modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 48px;
    background: rgba($n2,.8);
    opacity: 0;
    animation: showModal .4s forwards;
    overflow: auto;
    z-index: 999;
    @include m {
        padding: 16px; }
    @include dark {
        background: rgba($n6, .9); } }

@keyframes showModal {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

.outer {
    position: relative;
    width: 100%;
    max-width: 408px;
    margin: auto;
    background: $n1;
    border-radius: 16px;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    z-index: 2;
    @include dark {
        background: $n7; }
    @include m {
        box-shadow: none; }
    & > div {
        padding: 26px 24px 24px; } }

.body {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-direction: column; }


.sidesubs {
    width: 100%;
    height: 100%;
    max-height: 200px;
    display: flex;
    align-items: left;
    flex-direction: column;
    margin-bottom: 20px;
    overflow-y: scroll;
    overflow-x: visible;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-right: 5px;
    &::-webkit-scrollbar {
        width: 6px; }
    &::-webkit-scrollbar-track {
        border-radius: 100px; }
    &::-webkit-scrollbar-thumb {
        background-color: $n3;
        border-radius: 100px;
        @include dark {
            background-color: $n6; } }

    p {
      color: $n4;
      align-self: center; }

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.2rem; }

    label {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: 250ms;
        padding: 0.5rem 0.5rem;
        border-radius: 10px;
        &:hover {
            color: $n7;
            background-color: $n3; }
        @include dark {
            color: $n;
            &:hover {
                background-color: $n6; } } }

    input {
        width: 20px;
        height: 20px;
        cursor: pointer; } }


.validationCounter {
    font-size: 0.7rem;
    position: absolute;
    top: 40px;
    right: 5rem; }

.input {
    padding-left: 0.5rem;
    width: 87%; }


.button {
    width: 100%; }

.invalid {
    border: 2px red solid;
    border-radius: 10px; }

.attention, .unpinHeadline {
    width: 85%; }


.select {
    position: absolute;
    padding: 0.5rem;
    width: 60%;
    align-self: center;
    bottom: 3rem;
    border-radius: 10px;
    color: #ff4d4d;
    transition: 250ms;
    text-align: center;
    font-size: 0.8rem;
    opacity: 0;
    visibility: hidden; }

.selectInvalid {
    opacity: 1;
    visibility: visible;
    bottom: 4.5rem; }


.cancel {
    background-color: $n3;
    color: $n5;
    transition: 250ms;
    &:hover {
        background-color: #ff4d4d;
        color: $n1; }
    @include dark {
        background-color: $n5;
        color: $n4;
        &:hover {
            background-color: #ff4d4d;
            color: $n1; } } }
