@import "styles/helpers";

table {
  td, th {
    padding: 10px 0;
    word-break: break-word;
    word-wrap: break-word; }
  th {
    text-align: left; } }

.buttons {
  display: flex;
  gap: 4px;
  align-items: center; }

.edit_button,
.cancel_button,
.delete_button {
  padding: 6px 8px;
  border-radius: $default-radius;
  fill: $n;
  color: $n;
  transition: .2s; }

.edit_button {
  background-color: $p1;
  &:hover {
    background-color: darken($p1, 10); } }

.delete_button {
  padding: 4px 6px;
  border: 2px solid $n4;
  &.confirm_delete {
    border-color: #ff3b3b;
    background-color: #ff3b3b;
    &:hover {
      background-color: darken(#ff3b3b, 10); } } }
.cancel_button {
  background-color: #ff3b3b;
  &:hover {
    background-color: darken(#ff3b3b, 10); } }

.input {
  background-color: $n6;
  border-radius: $default-radius;
  padding: 0 12px;
  max-width: 200px; }
