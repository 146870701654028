@import "styles/helpers";

$color-error: #cf0000;

.input_container {
  width: 100%;
  position: relative;
  display: grid;
  gap: 10px;
  .button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0px 0px 0px 50px;
    border-radius: 12px 12px 12px 12px; } }

.input {
  // width: 100%
  min-width: 300px;
  height: 48px;
  padding: 0 10px;
  border-radius: 12px;
  border: 2px solid transparent;
  background: $n2;
  @include inter;
  @include base1-s;
  color: $n7;
  transition: all .2s;
  @include dark {
    border-color: $n6;
    background: $n6;
    color: $n1; }
  @include placeholder {
    color: $n4;
    @include dark {
      color: $shades1; } }
  &:focus {
    border-color: $shades1;
    background: $n;
    @include dark {
      border-color: $n5;
      background: $n8; } } }

.input_title {
  flex: 1 1 40%; }

.input_link {
  flex: 1 1 50%; }

.container {
  flex-wrap: wrap;
  display: flex;
  gap: 8px;
  width: 100%;
  margin-top: 12px; }

.code {
  word-break: break-word;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 10px;
  border-radius: 10px;
  width: fit-content;
  background-color: $n3;
  color: $n7;
  @include dark {
    color: $n3;
    background-color: $n5; }

  &.text_dark {
    color: $n7; }
  &.text_light {
    color: $n1; }

  svg {
    cursor: pointer;
    transition: .2s ease-in-out;
    &:hover {
      transform: rotate(90deg);
      path {
        fill: $color-error; } }
    path {
      fill: $tag-text-color;
      transition: .2s ease-in-out; } } }

.price {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  @include t {
    grid-template-columns: 1fr 1fr; }
  margin: 0px 0px 20px 0px; }

.radio_box {
  display: grid;
  grid-template-columns: 150px 150px; }

.checkbox_box {
    width: calc(150px + 10%); }

.data_box {
    width: calc(250px + 10%); }

.filters {
    position: relative;
    &.active {
        .body,
        .overlay {
            visibility: visible;
            opacity: 1; } } }

.body {
    position: absolute;
    top: -24px;
    left: -430px;
    right: 0;
    z-index: 101;
    width: 600px;
    height: fit-content + 50px;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n1;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    @include m {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: auto;
        overflow: auto; }
    @include dark {
        background: $n7; } }

.top {
    display: flex;
    align-items: center;
    margin-bottom: 24px; }

.title {
    margin-right: auto; }

.close {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: $n3;
    margin-left: 24px;
    svg {
        fill: $n7;
        transition: transform .2s; }
    @include dark {
        background: $n6;
        svg {
            fill: $n1; } }
    &:hover {
        svg {
            transform: rotate(90deg); } } }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: rgba($n2, .8);
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: rgba($n6, .9); } }

.promo_dropdown {
  margin-top: 16px;
  margin-bottom: 16px; }

.modal_footer {
  margin-top: 25px;
  display: flex;
  justify-content: center; }
