@import "styles/helpers";

.container {
  position: relative;
  width: fit-content; }

.main_location {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  color: $n5;
  @include dark {
    color: $n4; } }

.extend_locations {
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 10px;
  margin-left: 8px;
  transition: .2s ease-in-out;
  &:hover, &.active {
    background-color: $n3;
    @include dark {
      background-color: $n6; } }
  .rotate180 {
    transform: rotate(-180deg); }
  svg {
    transition: .2s ease-in-out;
    path {
      fill: $n5;
      @include dark {
        fill: $n4; } } } }

.list {
  position: absolute;
  top: calc(100% + 8px);
  left: -10px;
  z-index: 4;
  display: block;
  padding: 10px;
  background-color: $n1;
  border-radius: $default-radius;
  border: 1px dashed $p1;
  background-color: $n3;
  @include dark {
    background-color: $n7; } }

.item {
  // white-space: nowrap
  // font-weight: 400
  // font-size: 14px
  // line-height: 1.5
  // color: $n7
  // +dark
  //   color: darken($n3, 10)
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  padding: 8px;
  line-height: 1.5;
  color: $n5;
  border-top: 1px solid $n3;
  &:first-child {
    border-top: none; }
  &:hover {
    background-color: $n3;
    @include dark {
      background-color: $n6; } }
  @include dark {
    border-color: $n6;
    color: darken($n3, 10); } }
