@import "styles/helpers";

.container {
  background-color: $n2;
  // padding: 18px 25px 10px
  padding: 18px 25px 6px;
  border-radius: $default-radius;
  margin-bottom: 20px;
  @include dark {
    background-color: $n6; } }

// .title
//   margin-bottom: 10px

.list {
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  padding-bottom: 12px; }

.box {
  flex-shrink: 0;
  width: fit-content;
  height: fit-content;
  border-radius: $default-radius;
  font-size: 13px;
  transition: .2s ease-in-out;
  color: $n4;
  background-color: #eaeaea;
  @include base1-s;
  padding: 5px 16px;
  @include dark {
    color: $n4;
    background-color: $n5; }
  &:hover {
    cursor: pointer; } }

.active {
  color: $n7;
  @include dark {
    color: $n3; } }
