@import "styles/helpers";

.categories {
    display: flex;
    flex-wrap: wrap;
    gap: 12px; }

.item {
    @include element-flex(row, flex-start, center);
    flex-shrink: 0;
    width: fit-content;
    padding: 8px;
    @include body1-s;
    border-radius: 12px;
    border: solid 2px #DCDCDC;
    @include dark {
        border: solid 2px #6f767e; } }

.item_button {
    display: flex;
    width: fit-content;
    @include body1-s; }

.item_cursor {
    cursor: default; }

.item_shake {
    &:active {
        animation: shake 0.5s; } }

@keyframes shake {
    0% {
        transform: translateX(0); }
    25% {
        transform: translateX(7px); }
    50% {
        transform: translateX(-14px); }
    75% {
        transform: translateX(7px); }
    100% {
        transform: translateX(0); } }
