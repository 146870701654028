@import "styles/helpers";

.average_earnings_card {
    margin-bottom: 0; }

.related_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: $default-radius; }

.related_card {
    padding: 24px; }

.related_item {}
