@import "styles/helpers";

.item,
.answer {
    display: flex; }

.item {
    .avatar {
        width: 48px;
        height: 48px; }
    .details {
        // flex: 0 0 calc(100% - 48px)
        // width: calc(100% - 48px)
        width: 100%; }
    //&:not(:first-child)
    margin-top: 12px;
    padding-top: 24px;
    //border-top: 1px solid $n3
    @include dark {
        border-color: $n6; } }

.answer {
    margin-top: 12px;
    padding: 15px 0 0 10px;
    .avatar {
        width: 32px;
        height: 32px; }
    .details {
        flex: 0 0 calc(100% - 32px);
        width: calc(100% - 32px);
        padding-left: 16px; } }

.avatar {
    flex-shrink: 0;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.line {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.rating_and_time {
    display: flex;
    align-items: center;
    gap: 4px; }

.author {
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $n7;
    @include dark {
        color: $n2; } }

.time {
    margin-left: 8px;
    white-space: nowrap;
    @include caption2;
    font-weight: 500;
    color: $shades1; }

.rating {
    display: flex;
    align-items: center;
    margin-left: 16px;
    @include base2;
    svg {
        margin-left: 8px;
        fill: #FFC554; } }

.login {
    margin-top: 4px;
    @include caption1;
    font-weight: 500;
    color: $n4; }

.comment {
    margin-top: 12px;
    font-weight: 500;
    white-space: wrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    color: $n5;
    @include dark {
        color: $n1; }
    strong {
        font-weight: 600; }
    &.comment_full {
        display: block; } }

.show_more {
    cursor: pointer;
    color: $n4;
    &:hover {
        color: $p1; } }

.answerlist {
    display: none;
    margin-left: 24px;
    //border-left: 1px solid $n3
    > * {
        margin-left: 20px; }
    &.show {
        display: block; }
    @include dark {
        border-color: $n6; } }

.itemcontainer {
    margin-bottom: 20px; }

.showanswers {
    position: relative;
    margin: 28px 0 28px 24px;
    padding-left: 50px;
    color: $n4;
    font-size: 15px;
    font-weight: 400;
    line-height: (16/13);
    @include m {
        padding-left: 52px; }
    &:before {
        content: "";
        position: absolute;
        top: -10px;
        left: 0;
        width: 33px;
        height: 22px;
        border-bottom-left-radius: 12px;
        border-width: 0 0 2px 2px;
        border-style: solid;
        border-color: $n3;
        @include m {
            left: -37px; }
        @include dark {
            border-color: $n5; } }
    &.hide {
        display: none; }
    &:hover {
        color: $n5; } }

.reply_to {
    background-color: #2A85FF90;
    padding: 4px;
    border-radius: 10px; }

.actions {
  display: flex;
  align-items: center;
  //background-color: $n7
  margin-left: 8px; }
