@import "styles/helpers";

.label {
    margin-bottom: 14px;
    @include base2;
    color: $n5;
    @include dark {
        color: $n3; } }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    border-radius: 12px;
    background: $n2;
    background-size: cover;
    overflow: hidden;
    @include dark {
        background: $n6; }
    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: .5; } }

.input {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    font-size: 340px;
    opacity: 0;
    height: 100%;
    cursor: pointer;
    &:hover + .box {
        border-color: $n4;
        @include dark {
            border-color: $n5; } } }

.box {
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    padding: 0 20px;
    border: 2px solid $n3;
    border-radius: 12px;
    background: $n1;
    box-shadow: 0px 12px 13px -6px rgba(0, 0, 0, 0.04);
    @include button1;
    transition: .2s ease-in-out;
    &:hover {
        color: $n1;
        background: $p1;
        svg {
            fill: $n1; } }
    @include dark {
        background: $n7;
        border-color: $n6;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 12px 13px -6px rgba(0, 0, 0, 0.04), inset 0px 2px 4px #31353B;
        &:hover {
            background: $p1; } }
    svg {
        transition: .2s ease-in-out;
        margin-right: 8px;
        @include dark {
            fill: $n1; } } }

