@import "styles/helpers";

.card {
    width: max-content; }


.list {
    display: flex;
    justify-content: space-between;
    // +m
 }    //     flex-direction: column

.item {
    display: block;
    // padding: 0 32px 0 64px
    max-width: 260px;
    padding: 0 32px 0 44px;
    color: $n7;
    // +x
    //     padding: 0 32px 0 32px
    // +t
    //     padding: 0
    // +m
    //     display: flex
    //     flex-direction: row-reverse
    //     width: 100%
    @include dark {
        color: $n1; }
    &:not(:first-child) {
        border-width: 0 0 0 1px;
        border-style: solid;
        border-color: $n3;
        // +m
        //     margin-top: 24px
        //     padding-top: 24px
        //     border-width: 1px 0 0
        @include dark {
            border-color: $n6; } }
    &:first-child {
        padding-left: 12px; }
    &:last-child {
        padding-right: 0; }
    &:not(:last-child) {} }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-bottom: 24px;
    border-radius: 50%;
    // +m
    //     margin: 0 0 0 auto
    svg {
        fill: $n7; } }

.label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @include caption1;
    color: $n5;
    @include dark {
        color: $n3; } }

.tooltip {
    svg {
        fill: $n4; } }

.counter {
    min-width: 146px;
    margin-bottom: 8px;
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -.03em;
    // +t
    //     min-width: auto
    //     font-size: 40px
    // +m
 }    //     font-size: 48px

.indicator {
    display: inline-flex;
    align-items: center;
    padding: 4px;
    color: $n4;
    @include dark {
        background: $n6; }
    span {
        margin-left: 4px;
        @include caption2; } }

 :global {
    .r-medium {
         :local {
            .list {
                flex-direction: column; }
            .item {
                display: flex;
                flex-direction: row-reverse;
                width: 100%;
                padding: 0;
                &:not(:first-child) {
                    margin-top: 24px;
                    padding-top: 24px;
                    border-width: 1px 0 0; } }
            .icon {
                margin: 0 0 0 auto; }
            .counter {
                font-size: 48px;
                min-width: auto; } } } }
