@import "styles/helpers";

.container {
  @include element-flex(column, flex-start, stretch);
  gap: 24px;
  width: 100%; }

.text {
  @include element-flex(column);
  gap: 4px; }

.title {
  @include title1-s;
  color: $n8;
  @include dark {
    color: $n1; } }

.description {
  @include body1-m;
  color: $n4; }
