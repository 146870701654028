@import "styles/helpers";

.container {
    @include element-flex(column, space-between);
    gap: 16px;
    width: 100%; }

.header {
    @include element-flex(row, space-between);
    gap: 8px;
    width: 100%;
    @include m {
        @include element-flex(column, space-between);
        gap: 16px; } }

.header_button {
    height: 32px;
    width: 32px;
    border-radius: 6px;
    width: fit-content; }

.buttons_container {
    @include element-flex(row, flex-end);
    gap: 4px;
    @include d {
        @include element-flex(column, flex-end); }
    @include m {
        position: absolute;
        right: 48px; } }

.button {
    display: block;
    height: 34px;
    width: 34px;
    border-radius: 6px;
    padding: 0;
    background: $n3;
    @include dark {
        background: $n5;
        &:hover {
            background: #2A85FF; } }
    svg {
        margin-right: 8px;
        fill: black;
        @include dark {
            fill: white; } } }

.button_remove {
    display: block;
    height: 34px;
    width: 34px;
    border-radius: 6px;
    padding: 0;
    background: $n3;
    @include dark {
        background: $n5;
        &:hover {
            background: red; } }
    svg {
        margin-right: 8px;
        fill: black;
        @include dark {
            fill: white; } } }

.list {
    @include element-flex(column, space-between);
    gap: 8px;
    width: 100%; }

.labels {
    display: contents; }

.info {
    @include info;
    width: 100%;
    text-align: center; }

.education_info {
    display: grid !important;
    @include element-flex(row);
    grid-template-columns: repeat(5, 1fr) 10%;
    gap: 8px;
    width: 100%;
    color: $n4;
    padding-left: 24px;
    padding-right: 24px;
    @include m {
        display: none !important; } }

.education_info_mobile {
    display: grid !important;
    @include element-flex(row);
    grid-template-columns: 1fr 1fr 1fr 10%;
    gap: 8px;
    width: 100%;
    color: $n4;
    padding-left: 24px;
    padding-right: 24px;
    @include m {
        display: none !important; } }

.list_item {
    word-break: break-word;
    hyphens: auto;
    display: flex;
    height: 100%;
    align-items: center;
    @include dark {
        border-color: $n5; } }

.doubled_list_item {
    word-break: break-word;
    hyphens: auto;
    @include element-flex(column,center);
    height: 100%;
    @include dark {
        border-color: $n5; } }

.list_container {
    width: 100%;
    border-radius: 12px;
    &:nth-child(2n) {
        background: $n2;
        @include dark {
            background: $n6; } } }

.item_container {
    gap: 8px;
    @include element-flex(column, space-between);
    padding: 24px; }

.education_info_grid {
    @include element-flex(row);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 10%;
    gap: 8px;
    width: 100%;
    @include dark {
        border-color: $n5; }
    @include m {
        @include element-flex(column);
        width: calc(100% - 32px - 12px);
        gap: 0; } }

.education_info_grid_mobile {
    @include element-flex(row);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 10%;
    gap: 8px;
    width: 100%;
    @include dark {
        border-color: $n5; }
    @include m {
        @include element-flex(column);
        width: calc(100% - 32px - 12px);
        gap: 0; } }

.list_description {
    @include body2-s;
    color: $n4; }

.empty_history {
    margin-top: 16px;
    width: 100%;
    text-align: center; }

.modal {
    max-width: 600px;
    @include m {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: auto;
        overflow: auto; } }

.modal_container {
    @include element-flex(column, space-between);
    gap: 16px;
    width: 100%;
    margin-top: 8px; }

.names_container {
    @include element-flex(row, space-between);
    gap: 24px;
    width: 100%; }

.field {
    width: 100%; }

.text_area {
    width: 100%; }

.dates_container {
    @include element-flex(row, space-between);
    gap: 24px;
    width: 100%; }

.modal_button {
    width: 100%; }
