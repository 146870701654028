@import "styles/helpers";

.controls {
  display: flex;
  flex-direction: column;
  gap: 8px; }

.button {
  padding: 8px;
  border-radius: $default-radius;
  background-color: $n3;
  transition: .2s ease-in-out;
  @include dark {
    background-color: $n6;
    &:hover {
      svg {
        //transform: scale(1.05)
        path {
          fill: $n2; } } } }

  &:hover {
    svg {
      transition: .2s ease-in-out;
      transform: scale(1.1);
      path {
        fill: $n7; } } }
  svg {
    path {
      fill: $n4; } } }
