@import "styles/helpers";

.container {
  @include element-flex(row, space-between, center);
  gap: 12px; }

.user {
  @include element-flex(row, flex-start, center);
  gap: 12px;

  .avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover; } }

.button {
  display: block; }
