@import "../../../styles/helpers";

.search {
    position: relative;
    flex-shrink: 0;
    width: 360px;
    @include m {
        position: absolute;
        left: 0;
        right: 0;
        top: 72px;
        width: 100%;
        padding: 12px 16px;
        background: $n1;
        box-shadow: 0 8px 12px rgba($n8, .05);
        @include dark {
            background-color: $n7; } }
    &.active {
        .start,
        .result {
            visibility: hidden;
            opacity: 0; }
        .direction,
        .close {
            visibility: visible;
            opacity: 1; }
        .body {
            visibility: visible;
            opacity: 1; } } }

.head {
    position: relative;
    z-index: 2; }

.start,
.direction,
.result,
.close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: transform .2s;
    svg {
        transition: fill .2s; } }

.start {
    z-index: 3;
    left: 12px;
    svg {
        fill: $n4; }
    &:hover {
        svg {
            fill: $n7; } } }

.result {
    right: 8px;
    width: 68px;
    height: 32px;
    background: $n;
    border-radius: 8px;
    box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.05), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25);
    font-size: 16px;
    font-weight: 600;
    color: $n7;
    @include dark {
        background: $n7;
        box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.05), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 1px 0px rgba(255, 255, 255, 0.08);
        color: $n1; }
    @include m {
        display: none; }
    &:hover {
        color: $p1; } }

.direction {
    left: 10px;
    svg {
        fill: $p1; }
    &:hover {
        transform: translate(-2px,-50%); } }

.close {
    right: 10px;
    width: 32px;
    height: 32px;
    svg {
        fill: $shades1; }
    &:hover {
        svg {
            fill: $p3; } } }

.direction,
.close {
    visibility: hidden;
    opacity: 0; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 68px 0 42px;
    background: $n2;
    border: 2px solid transparent;
    border-radius: 12px;
    transition: none;
    @include base1-s;
    color: $n7;
    @include dark {
        background: $n6;
        color: $n1; }
    &::placeholder {
        color: $shades1; }
    &:focus {
        transition: all .2s;
        border-color: $p1;
        background: $n;
        @include dark {
            background: $n8; } } }

.body {
    position: absolute;
    top: -12px;
    left: -12px;
    right: -12px;
    padding: 84px 12px 12px;
    border: 1px solid transparent;
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    background: $n;
    border-radius: 16px;
    visibility: hidden;
    opacity: 0;
    @include m {
        top: 100%;
        left: 0;
        right: 0;
        max-height: calc(100vh - 152px);
        padding: 16px 8px;
        border-radius: 0;
        border-top: 1px solid $n3;
        box-shadow: 0 12px 32px rgba($n8, .05);
        overflow: auto; }
    @include dark {
        background: $n7;
        border-color: $n6; } }

.list {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; } }


.box {
    &:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $n3;
        @include dark {
            border-color: $n6; }
        @include m {
            margin-bottom: 16px;
            padding-bottom: 16px; } } }

.category {
    //margin-bottom: 12px
    margin-top: 12px;
    padding-left: 12px;
    @include caption1;
    color: $n4;
    // &:first-child
    //     margin-top: 0
 }    //     margin-bottom: 12px

.more_results {
    width: 100%;
    cursor: pointer;
    padding: 12px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
    color: $n7;
    @include dark {
        color: $n1; }
    &:hover {
        background-color: $n3;
        @include dark {
            background-color: $n6; } } }

.no_results {
    padding: 0 12px 12px;
    color: $n4; }

 :global {

    .mobile {
         :local {
            .search {
                position: absolute;
                left: 0;
                right: 0;
                top: 72px;
                width: 100%;
                padding: 12px 16px;
                background: $n1;
                box-shadow: 0 8px 12px rgba($n8, .05);
                @include dark {
                    background-color: $n7; } }
            .result {
                display: none; }
            .body {
                top: 100%;
                left: 0;
                right: 0;
                max-height: calc(100vh - 152px);
                padding: 16px 8px;
                border-radius: 0;
                border-top: 1px solid $n3;
                box-shadow: 0 12px 32px rgba($n8, .05);
                overflow: auto; }
            .box {
                margin-bottom: 16px;
                padding-bottom: 16px; } } } }
