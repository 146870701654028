@import "styles/helpers";

$color-delete: #cf0000;

.container {
  display: flex;
  flex-direction: column;
  gap: 12px; }

.input_with_button {
  position: relative;
  .input {
    padding-right: 30px; }
  .input_button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px;
    font-size: 24px;
    font-weight: 400; } }

.item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  font-weight: 400;
  padding: 4px 0; }

.item_content {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  path {
    fill: $p1; } }

.item_delete {
  cursor: pointer;
  path {
    transition: .2s ease-in-out;
    fill: $n4; }
  &:hover {
    path {
      fill: $color-delete; } } }
