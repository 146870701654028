@import '../../styles/helpers';

.container {
  width: 400px;
  height: 400px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: $n1;
  border-radius: 16px;
  overflow: hidden;
  @include m {
    width: 100%;
    height: 100vh; }
  @include dark {
    background-color: $n7; } }

.wrapper {
  width: 100%;
  height: 100%;
  @include m {
    width: 100%;
    height: 100vh; } }


.control {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  .button {
    padding: 16px 20px;
    background-color: $p1;
    border-radius: 16px;
    color: $n1;
    font-weight: bold;
    transition: .2s ease-in-out;
    &:hover {
      background-color: #0069f6; } } }
