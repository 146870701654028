@import "styles/helpers";

.menu {
  cursor: pointer;
  svg {
    //transform: rotate(90deg)
    path {
      fill: $n4; } } }

.settings {
  border-radius: 25%;
  width: 30px;
  height: 30px;
  background-color: $n1;
  transition: none;
  svg {
    transform: rotate(90deg); }
  @include dark {
    background-color: $n7; }
  &:hover {
    transition: .2s;
    background-color: $n3;
    @include dark {
      background-color: $n6; } } }
