@import "styles/helpers";

.card {
  margin-bottom: $cards-margin;
  padding: 12px 25px; }

.container {
  width: 100; }

.tags {
  display: flex;
  align-items: center;
  gap: 8px;
  //width: 100%
  padding: 4px 0;
  margin-top: 4px;
  // overflow-y: hidden
  // overflow-x: auto
  // -webkit-overflow-scrolling: touch
  // touch-action: auto
  // &::-webkit-scrollbar
  //   height: 5px
  // /* Track */
  // &::-webkit-scrollbar-track
  //   border-radius: $default-radius
  //   background: $n1
  //   +dark
  //     background: $n7
  // /* Handle */
  // &::-webkit-scrollbar-thumb
  //   border-radius: $default-radius
  //   background: $n3
  //   +dark
 }  //     background: $n6

.tag {
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  flex-shrink: 0;
  white-space: nowrap;
  width: fit-content;
  //height: fit-content
  padding: 10px 12px;
  border-radius: $default-radius;
  color: $n7;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: .2s ease-in-out;
  &.text_dark {
    color: $n7; }
  &.text_light {
    color: $n1; }
  &.selected {
    border-color: $p1;
    svg {
      background-color: $p1;
      border-radius: 50%;
      path {
        fill: $n; } }
    @include dark {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; } } }

.settings_btn,
.reset_tags_btn,
.show_all_btn,
.my_tags_btn {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: nowrap;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 8.5px 10px;
  border-radius: $default-radius;
  color: $n7;
  @include dark {
    color: $n2; }
  path {
    //fill: $n7
    fill: $n2; }
  &.disabled {
    opacity: .5;
    cursor: default; } }

.settings_btn {
  background-color: $n4; }


.my_tags_btn {
  background-color: $p2; }

.reset_tags_btn {
  background-color: #ff4242; }

.show_all_btn {
  background-color: $p1; }
