@import "styles/helpers";

.container {
    @include element-flex(column, space-between);
    gap: 12px;
    width: 100%;
    margin-bottom: 32px; }

.header {
    @include element-flex(row, space-between);
    gap: 8px;
    width: 100%; }

.header_button {
    height: 32px;
    width: 32px;
    border-radius: 12px; }

.field {
    width: 100%; }

.input_container {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  button {
    width: 75px;
    padding: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 0 12px 12px 0;
    @include m {
      width: 50px; } } }

.remove_button {
  background: $n3;
  &:hover {
    background: red; }
  @include dark {
    background: $n5;
    &:hover {
      background: red; } }
  svg {
    fill: black;
    @include dark {
      fill: white; } } }

.input {
  min-width: 300px;
  height: 48px;
  padding: 0 10px;
  border-radius: 12px;
  border: 2px solid transparent;
  background: $n2;
  @include inter;
  @include base1-s;
  color: $n7;
  transition: all .2s;
  @include dark {
    border-color: $n6;
    background: $n6;
    color: $n1; }
  @include placeholder {
    color: $n4;
    @include dark {
      color: $shades1; } }
  &:focus {
    border-color: $shades1;
    background: $n;
    @include dark {
      border-color: $n5;
      background: $n8; } } }

.input_title {
  flex: 1 1 40%; }
