@import "styles/helpers";

.button {
  img {
    margin-right: 10px;
    max-height: 24px; } }

.overlay {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  background-color: $n1;
  @include dark {
    background-color: $n7; } }

.logo {
    display: inline-block;
    margin: 0 auto;
    z-index: 2;
    width: 96px;
    img {
        width: 100%;
        max-height: 50px; } }

.loader {
    align-self: center;
    transform: scale(1.5);
    margin-bottom: 12px; }

.loader_title {
    text-align: center;
    margin-bottom: 12px;
    @include body2-s;
    color: $shades1; }

.cancel_button {
  display: none;
  &.visible {
    display: block; } }
