@import "styles/helpers";

.container {
  @include element-flex(column, space-between);
  gap: 48px;
  width: 100%; }

.images, .fieldset {
  display: flex;
  flex-direction: column;
  gap: 32px; }

.fieldset {
  margin-top: 32px; }

.about_me_container {
  width: 100%;
  margin-bottom: 0px; }

.about_me_title {
  margin-bottom: 0px; }

.text_area {
  width: 100%; }
