@import "styles/helpers";

.card {
    margin-top: 16px;
    background: $n2 !important;
    @include dark {
        background: $n5 !important; } }

.info {
    display: flex;
    align-items: center;
    @include caption1;
    color: $n4;
    gap: 4px; }

.categories {
    display: flex;
    flex-wrap: wrap;
    gap: 12px; }

.item {
    @include element-flex(row, flex-start, center);
    width: fit-content;
    padding: 8px;
    @include body1-s;
    border-radius: 12px;
    border: solid 2px #DCDCDC;
    color: black;
    @include dark {
        color: white;
        border: solid 2px #6f767e; } }

.plus {
    margin-top: 2px;
    display: flex;
    position: relative;
    border-radius: 4px;
    margin-left: 8px;
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    &:hover {
        transition: transform .2s ease-in-out;
        transform: rotate(-135deg);
        border-color: red !important; } }

.plus_icon_vertical {
    position: absolute;
    left: 9px;
    top: 10%;
    border: 1px solid;
    height: 80%;
    width: 1px;
    border-color: inherit; }

.plus_icon_horizontal {
    position: absolute;
    left: 10%;
    top: 9px;
    border: 1px solid;
    height: 1px;
    width: 80%;
    border-color: inherit; }
