@import "../../../styles/helpers";

$color-error: #cf0000;

.heading {
  margin-bottom: 12px; }

.row {
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  @include a {
    grid-template-columns: 1fr; }
  .col {
    display: flex;
    flex-direction: column;
    gap: 12px; } }

.add_location {
  display: flex;
  flex-direction: column;
  button {
    width: fit-content;
    border: 2px solid #2A85FF;
    background-color: transparent;
    align-self: center;
    transition: .2s;
    &:hover {
      background-color: #2A85FF; }
    //margin-top: 8px
    @include m {
      width: 100%; } } }

.locations_list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  //margin-bottom: 24px
  color: $n7;
  @include dark {
    color: $n2; }
  .locaton_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding: 8px 12px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid $n3;
    background-color: $n1;
    //border-bottom: 1px solid $n3
    &:last-of-type {
      margin-bottom: 24px; }
    @include dark {
      background-color: $n7;
      border: 2px solid $n6;
 }      //border-bottom: 1px solid $n5
    &.active {
      background-color: $n3;
      @include dark {
        background-color: $n6; }
      .location_item_btn {
        &:hover {
          background-color: $n1;
          @include dark {
            background-color: $n7; } } } }
    .location_item_data {
      font-size: 15px;
      font-weight: 400; }
    .btns {
      display: flex;
      align-items: center;
      gap: 4px; }
    .location_item_btn {
      font-size: 15px;
      padding: 6px 12px;
      border-radius: 10px;
      transition: .2s ease-in-out;
      color: $n7;
      @include dark {
        color: $n3; }
      &:hover {
        background-color: $n3;
        @include dark {
          background-color: $n6; } }
      path {
        fill: $n7;
        @include dark {
          fill: $n1; } } }
    .location_item_remove {
      padding: 5px;
      border-radius: 10px;
      transition: .2s ease-in-out;
      svg {
        transition: .2s ease-in-out; }
      &:hover {
        svg {
          transform: rotate(90deg); }
        background-color: $n1;
        @include dark {
          background-color: $n7; } }
      path {
        fill: $n7;
        @include dark {
          fill: $n1; } } } } }
