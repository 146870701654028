@import "styles/helpers";

.container {
  display: flex;
  gap: 24px; }

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px; }

.title {
  @include title1-s; }

.subtitle {
  color: $n4;
  font-size: 16px;
 }  //margin-top: 4px

.external {

  position: relative;
  display: inline-flex;
  padding: 0;
  align-items: stretch;
  .button {
    position: relative;
    z-index: 2;
    @include m {
      font-size: 12px; } }
  .clickcount {
    background: lighten($p1, 30);
    border-radius: 0 15px 15px 0;
    position: relative;
    left: -10px;
    display: inline-flex;
    align-items: center;
    border: 3px solid $p1;
    padding: 0 10px 0 12px;
    color: $n5;
    gap: 5px;
    svg {
      fill: $n5; } } }

.bottom_container {
  display: flex;
  height: 100%;
  @media (max-width: 1400px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 24px; } }

.bottom {
  align-items: flex-end;
  display: inline-block;
  align-self: flex-end;
  width: 100%; }


.publish_date {
  color: $n4;
  display: inline-block;
  align-self: flex-end;
  text-align: right;
  width: 100%; }

.locations {
  margin-top: 4px; }

 :global {
  .mobile {
     :local {
      .container {
        flex-direction: column; } } } }

.column {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.average_salary {
  display: inline-block;
  align-self: flex-end;
  font-weight: 700;
  font-size: 36px;
  padding-left: 5%;
  color: #14a625;
  width: 60%;
  position: relative;
  @media (max-width: 1400px) {
    grid-area: 1 / 1 / 2 / 3;
    padding-left: 0;
    width: 100%; } }

.average_salary_value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  bottom: -15px;
  width: 100%; }

.average_salary_details {
  position: absolute;
  bottom: 35px;
  font-size: 15px;
  text-align: left;
  color: gray;
  font-weight: 500;
  color: $shades1;
  svg {
    fill: $shades1; }
  strong {
    font-weight: 700;
    color: $n7;
    @include dark {
      color: $n1; } } }
