@import "../../styles/helpers";

.page_container {
    display: flex;
    width: 100%; }

.page {
    position: relative;
    margin: 80px auto 0;
    //padding: 90px 0 0 0px
    //height: 100vh
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; }
    @include m {
        margin-top: 70px; }
    @include a {
        margin-top: 70px; }
    @include s {
        margin-top: 80px; } }

.go_back_button {
    margin-bottom: 12px;
    &.more_margin {
        margin-bottom: 24px; } }

.sidebar {
    @include m {
        transform: translateX(-100%);
        transition: transform .3s;
        &.visible {
            transform: translateX(0); } } }

.inner {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 96px);
    flex-shrink: 0;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px;
    @include x {
        padding: 32px 24px; }
    @include m {
        padding: 24px; }
    @include s {
        padding: 8px; } }

.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    padding-bottom: 100px;
    border-radius: $default-radius;
    max-width: 100%;
    &.wide {
        max-width: 100%; }
    @include d {
        max-width: 100%; } }

.title {
    margin-bottom: 24px;
    @include m {
        margin-bottom: 16px; } }
