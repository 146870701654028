@import "../../../../../styles/helpers";

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 48px;
  background: rgba($n2,.8);
  opacity: 0;
  animation: showModal .4s forwards;
  overflow: auto;
  z-index: 999;
  @include m {
    padding: 16px; }
  @include dark {
    background: rgba($n6, .9); } }

@keyframes showModal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.outer {
  position: relative;
  // width: min-content
  // max-width: 408px
  margin: auto;
  background: $n1;
  border-radius: 16px;
  box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1);
  z-index: 2;
  @include dark {
    background: $n7; }
  @include m {
    box-shadow: none; }
  & > div {
    padding: 1rem; } }

// .close
//   position: absolute
//   top: 4px
//   right: 4px
//   width: 36px
//   height: 36px
//   border-radius: 50%
//   background: $n3
//   font-size: 0
//   svg
//     fill: $n5
//     transition: transform .2s
//   +dark
//     background: $n6
//     svg
//       fill: $n1
//   &:hover
//     svg
//       transform: rotate(90deg)

//// Layout Module
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem; }
.groupButtons {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    gap: 1rem; }
.btn_remove {
    background-color: $n3;
    color: $n5;
    transition: 250ms;
    padding: 0.7rem 1rem;
    border-radius: 12px;
    &:hover {
        background-color: #ff4d4d;
        color: $n1; }
    @include dark {
        background-color: $n5;
        color: $n4;
        &:hover {
            background-color: #ff4d4d;
            color: $n1; } } }
.btn_ce {
    background-color: $n3;
    color: $n5;
    transition: 250ms;
    padding: 0.7rem 1rem;
    border-radius: 12px;
    @include dark {
        background-color: $n5;
        color: $n4; }
    &:hover {
        background-color: #2A85FF;
        color: $n1; }
    svg {
        @include dark {
            fill: white; } } }
