@import "styles/helpers";

.container {
    display: flex;
    flex-direction: column;
    gap: 24px; }

.tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
    border-radius: 2px;
    width: 100%; }

.tag {
    flex-shrink: 0;
    white-space: nowrap;
    width: fit-content;
    height: fit-content;
    padding: 4px 8px;
    border-radius: $default-radius;
    color: $n7;
    font-size: 13px;
    text-transform: uppercase;
    transition: .2s ease-in-out; }
