@import "styles/helpers";

.container {
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $default-radius;
  border: 1px solid $n3;
  @include dark {
    border-color: $n5; } }

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px; }

.icon {
  z-index: 1;
  width: fit-content;
  padding: 4px;
  border-radius: $default-radius;
  border: 2px dashed $n3;
  transition: .2s ease-in-out;
  @include dark {
    border-color: $n5; }
  path {
    transition: .2s ease-in-out;
    fill: $n4; }
  &:hover {
    border-style: solid;
    background-color: $n3;
    @include dark {
      background-color: $n5; }
    path {
      fill: $p1; } } }

.text {
  font-size: 14px;
  font-weight: 400;
  color: $n4; }

.error {
  color: $p3; }
