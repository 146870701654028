@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px; }

.header {
  display: flex;
  align-items: center;
  gap: 12px; }

.title {
  @include title1-s; }

.icon {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: $default-radius;
  background-color: $n4;
  color: $n7;
  @include dark {
    color: $n2; }
  path {
    fill: $n2; } }

.content {
  display: flex;
  flex-direction: column;
  gap: 24px; }

.tags {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  list-style-type: none;
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  //padding: 10px 5px
  padding-right: 4px;
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px; }
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $n1;
    @include dark {
      background: $n7; } }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $n3;
    @include dark {
      background: $n8; } } }

.tag {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  white-space: nowrap;
  width: fit-content;
  padding: 10px 12px;
  border-radius: $default-radius;
  color: $n7;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  transition: .2s ease-in-out;
  &.text_dark {
    color: $n7; }
  &.text_light {
    color: $n1; }
  &.selected {
    svg {
      background-color: $p1;
      border-radius: 50%;
      path {
        fill: $n; } }
    @include dark {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; } } }
