@import "styles/helpers";

.application_modal_outer {
    max-width: 700px; }

.go_back_button {
    margin-bottom: 12px; }

.application_modal_inner {
    display: flex;
    flex-direction: column;
    gap: 24px; }

.application_form {
    // max-height: 70vh
    // overflow-y: auto
    // padding-right: 8px
    // &::-webkit-scrollbar
    //     width: 8px
    //     height: 8px
    // /* Track */
    // &::-webkit-scrollbar-track
    //     border-radius: 10px
    //     background: $n1
    //     +dark
    //     background: $n7
    // /* Handle */
    // &::-webkit-scrollbar-thumb
    //     border-radius: 10px
    //     background: $n3
    //     +dark
 }    //     background: $n8

.container {
    position: relative;
    display: flex;
    gap: 24px;
    border-radius: $default-radius; }

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 36px;
    padding: 24px 24px 48px;
    overflow: hidden;
    border-radius: $default-radius;
    background-color: $n1;
    @include dark {
        background-color: $n7; } }

.image_wrapper {
    background-color: #ffffff;
    //padding: 12px
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        border-radius: 0;
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%; }
    &.no_padding {
 } }        //padding: 0

 :global {
    .mobile {
         :local {
            .container {
                flex-direction: column-reverse;
                margin-bottom: 64px; } } } }
