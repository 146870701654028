@import "styles/helpers";

.container {
    width: 28px;
    position: relative; }

.plus {
    display: flex;
    position: relative;
    border-radius: 4px;
    margin-left: 8px;
    width: 20px;
    height: 20px;
    transition: transform .2s ease-in-out;
    &:active {
        transform: rotate(-45deg); } }


.plus_rotated {
    transform: rotate(-45deg); }

.plus_icon_vertical {
    position: absolute;
    left: 9px;
    top: 10%;
    border: 1px solid;
    height: 80%;
    width: 1px; }

.plus_icon_vertical_rotated {
    transform: rotate(-45deg); }

.plus_icon_horizontal {
    position: absolute;
    left: 10%;
    top: 9px;
    border: 1px solid;
    height: 1px;
    width: 80%; }

.plus_icon_horizontal_rotated {
    transform: rotate(-45deg); }
