@import "styles/helpers";

.container {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 24px;
  background-color: $n1;
  padding: 24px;
  border-radius: $default-radius;
  @include dark {
    background-color: $n7; }
  @include m {
    padding: 16px; }

  .content {
    padding: 4px; } }

 :global {
  .mobile {
     :local {

      .container {
        grid-template-columns: 100%; }

      .content {
        padding: 0px; }

      .nav {
        display: none; } } } }
