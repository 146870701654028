@import "styles/helpers";

.label {
    margin-bottom: 14px;
    color: $n5;

    @include base2;
    @include dark {
        color: $n3; } }

.tooltip {
    position: relative;
    top: -1px;
    svg {
        fill: $shades1; } }

.editor {
    position: relative;
    @include inter;
    @include dark {
        color: $n8; }
    .editorMain {
        min-height: 112px;
        @include dark {
            color: $n1; } }
    .editorWrapper {
        border: 2px solid rgba(#9A9FA5, .25);
        border-radius: 12px;
        background: $n2;
        @include dark {
            border-color: rgba(#9A9FA5, .15);
            background: $n8; } }
    .editorToolbar {
        border: none;
        border-radius: 12px 12px 0 0;
        background: $n1;
        @include dark {
            background: $n6; } } }

.editorButton {
    .editorMain {
        padding-bottom: 56px; }
    .button {
        position: absolute;
        right: 12px;
        bottom: 12px; } }
