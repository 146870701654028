@import "../../../../styles/helpers";


.skeleton, .skeleton_user {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    padding: 25px;
    border-radius: 10px;
    border: 1px solid $n3;
    margin-bottom: 1rem;
    @include dark {
        border: 1px solid $n7; }
    span {
        @include dark {
            --base-color: #313131;
            --highlight-color: #525252; } }
    @include m {
        flex-direction: column; } }
.img {
    margin-right: 24px;
    @include m {
        margin: 0 auto; } }

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
 }    //margin: auto

.row {
    display: flex;
    flex-direction: column;
    gap: 4px;
    @include m {
        margin-top: 6px; } }

// ---------SKELETON-USER-------
.skeleton_user {
    flex-direction: column;
    padding: 0.5rem;
    width: 100px !important;
    justify-content: center;
    align-items: center;
    .img {
        margin-right: 0;
        margin-bottom: 5px; }
    .content {
        height: 24px;
        width: 100%; } }

