@import "../../styles/helpers";

.item {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 12px;
    width: 280px;
    background: $n;
    gap: 0;
    height: 100%;
    @include dark {
        border-color: $n5;
        background: $n7; } }

.image {
    flex-shrink: 0;
    height: auto;
    width: 300px;
    margin-bottom: 1rem;
    align-self: center;
    overflow: hidden;
    height: 200px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
        @include m {
            max-height: 190px; } } }

// .actions
//  // display: none

.actions {
    display: flex;
    flex-shrink: 0;
    gap: 8px; }
.pin_btn {
    cursor: pointer;
    img {
        height: 20px;
        transition: .2s ease-in-out; }
    &:hover {}
    img {
        filter: brightness(80%);
        @include dark;
        filter: brightness(120%); } }
.actions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    border-radius: $default-radius;
    background-color: $n1;
    @include dark {
        background-color: $n7; } }

.title {
    font-weight: 700;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
.foot {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 1rem; }

// .linkcont
//     display: none

// .time
//     position: absolute
//     bottom: 20px
//     left: 1rem
//     font-size: 13px
//     color: $shades1
//     // +m
//     //     bottom: auto
//     //     right: 40px
//     //     top: 218px

// .content
//     margin-bottom: 70px
//     font-weight: 500
//     color: $shades1
//     overflow: hidden
//     word-break: break-word
//     text-align: justify
//     text-overflow: ellipsis
//     display: -webkit-box
//     -webkit-box-orient: vertical
//     -webkit-line-clamp: 1
//     font-size: 14px
//     strong
//         font-weight: 700
//         color: $n7
//         +dark
//             color: $n1

// .details
//     flex-grow: 1
//     overflow: hidden
//     padding: 0
//     position: relative

//     // +m
//     //     padding: 0
// .categorieslist
//     display: none

// .title
//     font-weight: 700
//     font-size: 18px
//     white-space: nowrap
//     overflow: hidden
//     text-overflow: ellipsis
//     // +m
//     //     font-size: 20px

// .addfavouritebtn
//     cursor: pointer
//     img
//         height: 20px
//         transition: .2s ease-in-out
//     &:hover
//         img
//         filter: brightness(80%)
//         +dark
//             filter: brightness(120%)

// .price
//   position: absolute
//   bottom: 45px
//   font-size: 15px
//   color: $n6
//   +dark
//     color: $n3
//   +m
//     top: auto
//     right: 26px
//     bottom: 48px
//   s
//     color: $n4
//     +dark
//       color: $n4

// .actions
//   z-index: 2
//   position: absolute
//   bottom: 10px
//   right: 25px
//   display: flex
//   align-items: center
//   padding: 0 0 5px 10px
//   border-radius: 0 0 0 10px
//   flex-shrink: 0
//   gap: 8px

// .actionsBody
//     position: absolute
//     top: -135px
//     right: -12px
//     width: 264px
//     padding: 12px
//     border-radius: 16px
//     border: 1px solid transparent
//     box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
//     background: $n
//     visibility: hidden
//     opacity: 0
//     transform: translateY(3px)
//     transition: all .2s
//     +dark
//         background: $n8
//         border-color: $n6
//     &:before
//         content: ""
//         position: absolute
//         right: 17px
//         top: 100%
//         width: 20px
//         height: 10px
//         transform: rotate(180deg)
//         background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
//         +dark
//             background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23272B30'/%3E%3C/svg%3E")
