@import "../../../styles/helpers";

.panel {
    // position: absolute
    // top: 16px
    // left: calc(100% + 24px)
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include d {
        // top: 0
        // left: auto
 }        // right: -88px
    @include m {
 } }        //display: none

.avatar,
.brand,
.btns {
    &:not(:last-child) {
 } }        //margin-bottom: 24px

.avatar,
.brand,
.btns {
    flex-shrink: 0;
    width: 64px;
    height: 64px; }

.avatar {
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
 }        //border: 3px solid $p1
    .level {
        position: absolute;
        bottom: -3px;
        right: -3px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 2px solid $p1;
        font-size: 14px;
        background-color: $p1;
        color: $n1;
        //background-color: $n2
        //color: $n7
        //+dark
        //  //background-color: $n5
 } }        // // color: $n1

.brand {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $n;
    @include dark {
        background: $n6; }
    img {
        max-height: 32px; } }

.counter {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 20px;
    padding: 0 4px;
    background: $n7;
    border-radius: 50%;
    font-size: 14px;
    line-height: 20px;
    color: $n1;
    @include dark {
        background: $n1;
        color: $n7; } }

.btns {
    background: $n;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 700;
    color: $n4;
    @include dark {
        background: $n6; }
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover, &:focus {
        color: $p1;
        svg {
            fill: $p1;
            filter: brightness(104%); } } }

 :global {
    .mobile {
         :local {
            .panel {
                position: fixed;
                bottom: 10px;
                align-self: center;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 12px; }
            .btns,
            .avatar {
                width: 48px;
                height: 48px; } } } }
