@import "styles/helpers";

.skeleton {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    border-radius: 12px;
    gap: 1rem;
    span {
        @include dark {
            --base-color: #313131;
            --highlight-color: #525252; } } }

.tags, .section, .gt,.Techsection {
    padding: 1rem;
    display: flex;
    border-radius: 12px;
    @include dark {
        border-color: #313131; } }

.tags, .techtag {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    overflow-x: hidden;
    span {
        width: 150px;
        border-radius: 50px; } }

.section {
    gap: 2rem;
    .content {
        width: 60%; }
    .img {
        flex-grow: 1; } }

.text {}

.gt {
    gap: 2rem;
    .graph {
        width: 50%; }
    .trends {
        width: 50%; } }

.contentTech {
    padding: 24px;
    width: 100%; }

.Techsection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
        margin-bottom: 1rem; } }

.first_section {
    margin-bottom: 30px; }
