@import "styles/helpers";

.row {
  display: flex;
  @include t {
    display: block; } }


.heading2 {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 500;
  color: $n6;
  @include dark {
    color: $n4; }
  b {
    font-weight: 700;
    color: $n7;
    @include dark {
      color: $n3; } } }

.heading {
  margin-bottom: $cards-margin; }

.category {
  margin-bottom: $cards-margin; }

.no_results {
  text-align: center;
  margin: 36px 0;
  color: $n6;
  @include dark {
    color: $n4; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 340px);
        width: calc(100% - 340px);
        padding-right: 12px;
        @include x {
            flex: 0 0 calc(100% - 324px);
            width: calc(100% - 324px); }
        @include d {
            width: 100%; }
        @include t {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 340px;
        @include x {
            width: 324px; }
        @include d {
          display: none {
 } }            //width: 312px
        @include t {
            width: 100%; } } }

.card {
  &:not(:last-child) {
    margin-bottom: 8px; } }

.foot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-top: 40px;
  text-align: center;
  @include x {
    margin-top: 32px;
    margin-bottom: 32px; }
  @include m {
    margin-top: 24px;
    margin-bottom: 24px; } }

.next_page_btn {
  width: 100%; }

.pagination_numbers {
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 10px;
  color: $n7;
  path {
    fill: $n7; }
  @include dark {
    color: $n2;
    path {
      fill: $n2; } }
  &:hover {
    background-color: darken($n3, 10);
    @include dark {
      background-color: $n6; } } }

.pagination_icon {
  padding: 10px; }
.pagination_current {
  box-shadow: 0 0 0 2px $n3 inset;
  @include dark {
    box-shadow: 0 0 0 2px $n6 inset; }
  &:hover {
    box-shadow: 0 0 0 2px darken($n3, 10) inset;
    @include dark {
      box-shadow: 0 0 0 2px $n6 inset; } } }

.info {
  margin: 20px 0;
  font-style: italic;
  text-align: center;
  color: $n4; }
