@import "../../styles/helpers";


.detal_item {
    width: 100%;
    background-color: $n;
    padding: 1.5rem;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 3px solid $n2;
    @include dark {
        background-color: $n7;
        border-color: $n5; }
    &:hover {
        .detal_next_icon {
                transform: translateX(0);
                visibility: visible;
                opacity: 1; }
        @include m {
            .detal_next_icon {
                transform: translateX(-30px); } } } }




.detal_content {
    display: flex;
    width: 80%;
    flex-direction: column;
    .detal_title {
        color: $n7;
        font-size: 1.7rem;
        width: fit-content;
        // max-width: 80%
        transition: 250ms;
        overflow-wrap: break-word;
        @include dark {
          color: $n; }
        &:hover {
          color: #9659e1; }
        @include m {
            font-size: 1.2rem; } }
    @include m {
        width: 65%; } }




.detal_tags_row {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    gap: 1rem;
    margin-top: 1rem; }

.detal_tag {
    // background: rgb(121,99,255);
    // background: linear-gradient(47deg, rgba(121,99,255,1) 0%, rgba(255,59,59,1) 100%);
    background-color: $n3;
    padding: 5px 20px;
    color: $n8;
    font-size: 1rem;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 250ms;
    position: relative;
    height: 35px;
    @include dark {
        background-color: $n5;
        color: $n; }

    &:hover {
        transform: scale(1.1);
        color: #9659e1; }
    img {
        color: $n;
        width: 20px;
        z-index: 2;
        margin-right: 10px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        object-fit: cover; }
    @include m {
        font-size: 0.7rem;
        padding: 5px 10px; }
    // &::before
    //     content: "";
    //     position: absolute;
    //     width: 25px;
    //     height: 25px;
    //     border-radius: 100%
    //     background-color: $n3
    //     left: 1.1rem
    //     +m
    //         width: 50px;
 }    //         height: 50px;

.detal_side_icons, .detal_next_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 250ms;
    .detal_next_icon {
        transition: 150ms ease-in-out;
        position: absolute;
        left: -4rem;
        transform: translateX(-30px);
        visibility: hidden;
        opacity: 0;
        svg {
            color: $n8;
            transition: 150ms ease-in-out;
            font-size: 3rem;
            @include dark {
                color: $n; }
            &:hover {
                transform: scale(1.5);
                color: #9659e1; } }
        @include m {
            opacity: 1;
            visibility: visible;
            left: -2rem; } } }





.detal_main_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    // padding: 0.8rem
    img {
        width: 100%;
        position: relative;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover; }
    &::before {
        content: "";
        position: absolute;
        width: 70px;
        height: 70px;
        border-radius: 100%;
        background-color: $n3;
        // background: rgb(121,99,255);
        // background: linear-gradient(47deg, rgba(121,99,255,1) 0%, rgba(255,59,59,1) 100%);
        @include m {
            width: 70px;
            height: 70px; } }

    @include m {
        width: 50px;
 } }        // padding: 0.5rem


.content {
    //margin-bottom: 30px
    font-weight: 500;
    color: $shades1;
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-word;
    text-align: justify;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    strong {
        font-weight: 700;
        color: $n7;
        @include dark {
            color: $n1; } } }


