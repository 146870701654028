@import "styles/helpers";

.container {
  @include element-flex(column, flex-start, stretch);
  gap: 32px;
  width: 100%;
  position: relative; }


.progressWrapper {
  width: 100%;
  height: 12px;
  display: grid;
  grid-template-columns: 100%;
  background: #555555;
  border-radius: 999px;
  margin: 24px 0 12px 0;
  overflow: hidden; }


.progress {
  background-color: $n1;

  @include dark {
    background-color: $n7; } }

.questions {
  @include element-flex(column, flex-start, stretch);
  gap: 12px;
  width: 100%; }

.buttonWrapper {
  @include element-flex(row, space-between, center);
  gap: 24px;
  width: 100%; }


