@import "styles/helpers";

.progressWrapper {
  width: 100%;
  height: 18px;
  display: grid;
  grid-template-columns: 100%;
  border-radius: 999px;
  margin: 0;
  background: $n3;
  overflow: hidden;

  @include dark {
    background: $n5; }

  &.small {
    height: 10px; } }



.progress {
  height: 100%;
  justify-self: start;
  border-radius: 999px;
  background: $p1;
  position: relative;
  transition: width .2s ease-out; }


.percentages {
  color: $n1;
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  transition: right .2s ease-out, color .2s ease-out;

  &.lowValue {
    right: -30px;
    min-width: 20px;
    color: $n7;
    @include dark {
      color: $n1; } } }

