@import "styles/helpers";

.container {
    @include element-flex(column,space-between);
    gap: 24px; }

.header {
    @include title1-s;
    font-weight: 500; }

.interests_list {
    @include element-flex(row);
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px; }

.interest_container {
    @include element-flex(row,space-between);
    gap: 12px;
    padding: 12px;
    border-radius: 12px;
    background: $n3;
    @include dark {
        background: $n6; } }

.interest_name {
    @include body1-m; }

.no_information {
    @include body1-m;
    color: $n4; }
