@import "../../styles/helpers";

.container {
  width: 100%;
  height: 12px;
  //border: 1px solid darken($n3, 10)
  border-radius: 5px;
  background-color: $n3;
  //padding: 3px
  @include dark {
    //border-color: $n3
    background-color: $n4; } }

.bar {
  height: 100%;
  border-radius: 5px;
  transition: .2s ease-in-out; }
