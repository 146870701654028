@import "styles/helpers";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  gap: 6px;
  width: 100%;
  //max-width: 120px
  //border-left: 1px solid $n3
  color: $n7;
  @include dark {
    //border-left: 1px solid $n6
    color: $n1; } }

.name {
  text-transform: uppercase;
  font-weight: 600;
  word-wrap: break-word;
  color: $n7;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  @include dark {
    color: $n1; } }

.level {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
  color: $n4; }

.progress {
  border: 1px solid darken($n3, 10);
  @include dark {
    border-color: $n4; } }
