@import "../../styles/helpers";

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100% - 320px);
        width: calc(100% - 300px);
        padding-right: 12px;
        @include x {
            flex: 0 0 calc(100% - 324px);
            width: calc(100% - 324px); }
        @include d {
            // flex: 0 0 calc(100% - 312px)
            // width: calc(100% - 312px)
            width: 100%; }
        @include t {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 340px;
        @include x {
            width: 324px; }
        @include d {
            width: 312px; }
        // +t
 } }        //     width: 100%



.card {
    &:not(:last-child) {
        margin-bottom: 8px; } }

.subnone {
    color: $n4;
    margin: 2rem auto;
    text-align: center;
    @include t {
        margin: 3rem auto 4rem; } }

.foot {
    margin-top: 40px;
    text-align: center;
    @include x {
        margin-top: 32px; }
    @include m {
        margin-top: 24px; } }

.subhead {
    color: $n8;
    margin-bottom: 12px;
    padding: 1.5rem;
    border-radius: 12px;
    background-color: $n;
    font-size: 20px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 1.5rem;
        width: 16px;
        height: 32px;
        transform: translateY(-50%);
        border-radius: 4px;
        background-color: #599be1; }

    @include dark {
        background-color: $n7;
        color: $n; }


    h4 {
        margin-left: 2rem;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.6;
        letter-spacing: -.02em; } }
