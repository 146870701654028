@import "styles/helpers";

.container {
    color: black;
    @include dark {
        color: white; } }

.child_container {
    color: black;
    padding-left: 48px;
    @include dark {
        color: white; } }

.first_item {
    line-height: 32px;
    padding: 12px 0;
    position: relative; }

.item {
    line-height: 32px;
    padding: 12px 0;
    position: relative;
    white-space: nowrap; }

.item::before {
    content: "";
    height: 24px;
    width: 24px;
    left: -34px;
    top: 8px;
    position: absolute;
    border-color: rgb(50,60,60);
    border-width: 2px;
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: solid;
    border-left-style: solid;
    border-bottom-left-radius: 8px 8px; }

.item_active {
    line-height: 32px;
    padding: 12px 0;
    position: relative;
    white-space: nowrap; }

.branch_active {
    padding-bottom: 2px;
    border-bottom: 1px solid $n4;
    @include dark {
        border-color: $n5; } }

.item_active::before {
    content: "";
    height: 24px;
    width: 24px;
    left: -34px;
    top: 8px;
    position: absolute;
    border-color: #009bee;
    border-width: 2px;
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: solid;
    border-left-style: solid;
    border-bottom-left-radius: 8px 8px; }

.checkbox {
    width: 32px;
    height: 32px;
    float: left;
    padding-top: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    input {
        width: 24px;
        height: 24px; } }

.thumbnail {
    height: 32px;
    width: 32px;
    float: left;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 3px; } }

.thumbnail_active {
    height: 32px;
    width: 32px;
    float: left;
    transition: 0.2s ease-in-out;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 3px;
        animation: pulse 2s infinite;
        transition: 0.2s ease-in-out; }
    span {
        animation: pulse 2s infinite;
        transition: 0.2s ease-in-out; } }

.dot {
    height: 16px;
    width: 16px;
    margin: 8px;
    background-color: $n4;
    border-radius: 50%;
    display: inline-block; }

.item_button {
    padding-left: 8px;
    cursor: pointer;
    color: $n7;
    vertical-align: middle;
    @include body1-m;
    @include m {
        max-width: 200px;
        white-space: normal;
        text-align: left; }
    @include dark {
        color: $n; }
    &:hover {
        color: #009bee; } }

.item_button_active {
    padding-left: 8px;
    cursor: pointer;
    color: $n7;
    vertical-align: middle;
    @include body1-m;
    @include m {
        max-width: 200px;
        white-space: normal;
        text-align: left; }
    @include dark {
        color: $n; }
    &:hover {
        color: #009bee; } }

.item_button_edit {
    margin-left: 42px;
    color: $n7;
    @include dark {
        color: $n; } }

.down_arrow {
    border: solid rgb(50,60,60);
    width: 12px;
    height: 12px;
    margin-left: 12px;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    top: 35%; }

.up_arrow {
    border: solid rgb(50,60,60);
    width: 12px;
    height: 12px;
    margin-left: 12px;
    border-width: 0 2px 2px 0;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    position: absolute;
    top: 47.5%; }

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(11, 133, 255, 0.4);
        box-shadow: 0 0 0 0 rgba(11, 133, 255, 0.4); }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
        box-shadow: 0 0 0 10px rgba(204,169,44, 0); }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0); } }
