@import "styles/helpers";

.foot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-top: 40px;
  text-align: center;
  @include x {
    margin-top: 32px;
    margin-bottom: 32px; }
  @include m {
    margin-top: 24px;
    margin-bottom: 24px; } }
