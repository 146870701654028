@import "styles/helpers";

.row {
    display: flex;
    align-items: flex-start;
    @include t {
        display: block; } }

.col {
    &:first-child {
        // flex: 0 0 calc(100% - 340px)
        // width: calc(100% - 340px)
        // padding-right: 8px
        // +d
        //     flex: 0 0 calc(100% - 300px)
        //     width: calc(100% - 300px)
        // +t
        //     width: 100%
 }        //     padding-right: 0
    &:nth-child(2) {
        // flex-shrink: 0
        // width: 340px
        // position: sticky
        // top: 102px
        // +x
        //     top: 86px
        // +d
        //     width: 300px
        // +t
 } }        //     display: none

.cardWrap {
    flex: 0 0 calc(100% - 340px);
    width: calc(100% - 340px);
    padding-right: 20px;
    @include d {
        flex: 0 0 calc(100% - 300px);
        width: calc(100% - 300px); }
    @include t {
        width: 100%;
        padding-right: 0; } }

.card:nth-child(n+2) {
    margin-top: 20px; }

.filters {
    flex-shrink: 0;
    width: 340px;
    position: sticky;
    top: 102px;
    @include x {
        top: 86px; }
    @include d {
        width: 300px; }
    @include t {
        display: none; } }

.filtersSmall {
    display: none;
    margin-bottom: 20px;
    @include t {
        display: flex;
        justify-content: flex-end; } }
